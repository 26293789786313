import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import { NavLink } from 'react-router-dom'
//React Reveal
import { Fade } from 'react-reveal'
export * from "./BottomBarScroll";
export * from "./BottomBarForUpcoming";
export * from "./EvreBottomBar";


const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    margin: "0 auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "column",
    },
  },
  menu: {
    margin: 10,
    color: "#fff",
    font: "normal normal normal 1rem urbanist",
    textDecoration: "none",
    "&:hover": {
      color: '#00BF5F '
    }
  },
  active: {
    color: "#00BF5F ",
  },
  barAlign: {
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      width: '100%',
      textAlign: "left",
    },
  },
  heading: {
    color: "#fff",
    font: "normal normal normal 1rem urbanist",
    cursor: 'pointer',
    "&:hover": {
      color: "#00BF5F ",
      "& $mobile": {
        opacity: '1!important',
      },
      "& $arrow": {
        opacity: '1!important',
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: '100%',
      paddingLeft: '2%',
      marginBottom: '-5%'
    },
  },
  mobile: {
    opacity: '0!important',
  },
  arrow: {
    opacity: '0!important',
    width: '40%',
    marginLeft: '55%',
  },
  '@media(max-width: 1440px)': {
    root: {
      marginTop: '-3%',
    },
  },
  '@media(max-width: 1366px)': {
    root: {
      marginTop: '-3%',
    },
  },
}));

export const BottomBar = ({ style }) => {
  const classes = useStyles();
  const history = useHistory()
  return (
    <Box className={classes.root} style={style}>


      <Typography className={classes.heading} onClick={() => history.push('/evreproduct')}>
        <img className={classes.mobile} style={{ width: '40%' }} src={"./images/All/mobile.png"} alt="mobile" />
        <br />
        CHARGE WITH EVRE
        <br />
        <img className={classes.arrow} src={"./images/Arrow/Arrowleft.png"} alt="arrow" />
      </Typography>


      <Box className={classes.barAlign}>
        <h5>
          <NavLink
            className={classes.menu}
            activeClassName={classes.active}
            to="/infinity"
          >
            Infinity
          </NavLink>
        </h5>
        <h5>
          <NavLink
            className={classes.menu}
            activeClassName={classes.active}
            to="/pod"
          >
            POD
          </NavLink>
        </h5>
        <h5>
          <NavLink
            className={classes.menu}
            activeClassName={classes.active}
            to="/ac"
          >
            AC-001
          </NavLink>
        </h5>
        <h5>
          <NavLink
            className={classes.menu}
            activeClassName={classes.active}
            to="/intellipole"
          >
            Intellipole
          </NavLink>
        </h5>
        <h5>
          <NavLink
            className={classes.menu}
            activeClassName={classes.active}
            to="/upcomming"
          >
            Upcoming Products
          </NavLink>
        </h5>
      </Box>
    </Box>
  );
};
