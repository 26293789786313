import React, { useEffect } from "react";
import { Container, makeStyles, Typography } from "@material-ui/core";
//Aos
import AOS from 'aos';
import 'aos/dist/aos.css';


const useStyles = makeStyles((theme) => ({
  root: {
    width: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "10% auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      margin: "20% 0",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  text: {
    font: "normal normal normal 1.5rem Urbanist",
    cursor: "pointer",
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem'
    }
  },
  divider: {
    font: "normal normal  2rem Urbanist",
    opacity: "0.5",
    margin: '0% 5%'
  },
  image: {
    width: '200px',
    [theme.breakpoints.down('sm')]: {
      width: "100px"
    }
  }
}));

export const MakeInIndia = () => {
  const classes = useStyles();

  const animation = () => {
    AOS.init({
      easing: 'ease-out-back',
      duration: 1000,
    });
  }

  useEffect(() => {
    animation()
  }, [])
  return (
    <Container className={classes.root}>

      <img
        src={"./images/ProductCommonImages/Make_In_India.png"}
        alt="make in india"
        className={classes.image}
        data-aos="fade-up"
      />

      <Typography className={classes.divider} data-aos="fade-up">
        |
      </Typography>

      <Typography className={classes.text} data-aos="fade-up">
        Download Brochure
      </Typography>

    </Container>
  );
};
