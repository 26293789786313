import React, { useEffect } from "react";
import { makeStyles, Typography, Container, Box } from "@material-ui/core";
import { useHistory } from "react-router";
//Aos
import AOS from "aos";
import "aos/dist/aos.css";
import { ViewDetails } from "../../Atom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    marginTop: "15%",

    [theme.breakpoints.down("sm")]: {
      height: "100vh",
      justifyContent: "center",
      flexDirection: "column",
      width: '100%',
      margin: "0% auto"
    },
  },
  leftBox: {
    width: "35%",
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "25%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  image: {
    marginRight: "8%",
    width: "10vw",
    [theme.breakpoints.down("sm")]: {
      margin: "0% 0%",
      width: "10%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "25%",
    },
  },
  rightBox: {
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "65%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  heading: {
    font: "normal normal 900 6vw Urbanist",
    background: "#006934",
    background: "-webkit-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "-moz-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    [theme.breakpoints.down("md")]: {
      fontSize: "8vw",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "10vw",
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "12vw",
    },
  },
  para: {
    width: "70%",
    marginLeft: "2%",
    font: "normal normal lighter 1.2rem Urbanist",
    lineHeight: "1.8",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: "4%",
      lineHeight: "1.5",

    },
  },
  rightArrow: {
    display: "inline-block",
    cursor: "pointer",
    marginLeft: "2%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "4%",
    },
  },
  explore: {
    marginTop: 10,
    color: "#00BF5F ",
    fontFamily: 'Gilroy',
    [theme.breakpoints.down("sm")]: {
      font: "normal normal lighter 0.7rem Urbanist",
    },
  },
  arrowAlign: {
    marginLeft: 20,
    marginBottom: 20,
    width: "60",
    [theme.breakpoints.down("sm")]: {
      width: "30%",
    },
  },
}));

export const IntellipoleTemplate = () => {
  const classes = useStyles();
  const history = useHistory();

  const animation = () => {
    AOS.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  };

  useEffect(() => {
    animation();
  }, []);
  return (
    <Box className={classes.root}>
      <Box className={classes.leftBox}>
        <img
          src={"./images/Intellipole/Intellipole.png"}
          className={classes.image}
        />
      </Box>
      <Box className={classes.rightBox}>
        <Typography className={classes.heading}>INTELLIPOLE</Typography>
        <Typography className={classes.para}>
          Presenting the futuristic and intelligent EV Charging Solar Street
          Light Solution with multi-charge facility, AQI, all-weather
          durability, and much more!
        </Typography>
        <Box
          className={classes.rightArrow}
        // onClick={() => history.push("/intellipoleproduct")}
        >
          {/* <Typography
            className={classes.explore}
            onClick={() => history.push("/intellipoleproduct")}
            data-aos="fade-right"
          >
            Explore
          </Typography>
          <img
            src={"./images/Arrow/Arrow.svg"}
            alt="Arrow"
            width="60"
            className={classes.arrowAlign}
            onClick={() => history.push("/intellipoleproduct")}
            data-aos="fade-right"
          /> */}
          <ViewDetails
            title="Explore"
            route_curr="intellipoleproduct"
          />
        </Box>
      </Box>
    </Box>
  );
};
