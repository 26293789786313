import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import Fade from "react-reveal/Fade";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    margin: "0 auto",
    display: "flex",
    justifyContent: "space-between",
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  '@media(max-width: 1440px)': {
    root: {
      marginTop: '-5%',
    },
  },
  '@media(max-width: 1366px)': {
    root: {
      marginTop: '-5%',
    },
  },
  scroll: {
    transform: "rotate(90deg)",
    font: "normal  normal lighter 1rem Urbanist",
  cursor: "pointer",
  },
  arrowAlign: {
    marginLeft: 50,
    marginTop: 35,
    transform: "rotate(90deg)",
  cursor: "pointer",
  },
  heading: {
    "&:hover": {
      "& $title": {
        color: "#00BF5F ",
      },
      "& $mobile": {
        opacity: '1!important',
      },
      "& $arrow": {
        opacity: '1!important',
      },
    }
  },
  title: {
    color: "#fff",
    font: "normal normal normal 1.5vw urbanist",
    cursor: 'pointer',
  },
  mobile: {
    opacity: '0!important',
  },
  arrow: {
    opacity: '0!important',
    marginLeft: '30%',
  }
}));

export const EvreBottomBar = ({ title, route, image }) => {
  const classes = useStyles();
  const history = useHistory();
  const height = window.innerHeight;

  const handleScroll = () => {
    window.scroll({ top: height, behavior: "smooth" });
  };

  return (
    <Box className={classes.root}>
      <Fade left>
        <Box
          className={classes.heading}
        >
          <img className={classes.mobile} style={{ width: '20%' }} src={image} alt="mobile" />
          <h1 className={classes.title} onClick={() => history.push(`/${route}`)}>
            {title}
          </h1>
          <img className={classes.arrow} src={"./images/Arrow/Arrowleft.png"} alt="arrow" />
        </Box>
      </Fade>
      {/* <Fade top> */}
        <Box className={classes.barAlign} onClick={handleScroll}>
          <h1 className={classes.scroll}> Scroll to Explore</h1>
          <img
            src={"./images/Arrow/Arrow.svg"}
            className={classes.arrowAlign}
            alt="Arrow"
            width="60"
          />
        </Box>
      {/* </Fade> */}
    </Box>
  );
};
