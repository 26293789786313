import React, { useEffect, useState } from "react";
//Material ui
import { Box, makeStyles } from "@material-ui/core";
//Material Icon
import {
  AcGridOneData,
} from "../../../Data";
import {
  LeftImagesPara,
  ProductGridOne,
  ProductGridSecond,
  ProductGridTitle,
  ProductImage,
  ProductInfinityPara,
  ProductIntro,
  ProductSpecification,
  RightImagesPara,
  MakeInIndia,
} from "../../Atom";
import {
  BottomBar,
  TopNavbar,
  Footer,
  TopBarAll,
  ScrollTop,
} from "../../Molecules";
import { BottomBarScroll } from "../../Molecules/BottomBar/BottomBarScroll";

const para2 =
  "Presenting AC-001, an advanced, robust, and durable EV Charging Station with multi-charge capability that can help you power non-stop business operations.";
const paraRight =
  "Partner with EVRE to set up business-first EV Charging Stations for your electric fleets. Our solutions can help you optimize your fleet operations by providing swift EV Charging with electrically protected infra, class-apart technology, and business-focused features. Power your logistics, fleet, or last-mile delivery business with EVRE.";

const useStyles = makeStyles((theme) => ({
  paddingView: {
    paddingTop: '10%',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '18%'
    }
  },
  paddingViewAll: {
    paddingTop: '2%',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '20%'
    }
  }
}))

export const AC001ProductPage = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <TopBarAll
        l2="Features"
        l2id="#powering"
        l3="Logistics & Fleet Operators"
        l3id="#logistcs"
        // l4="&#11015; Download Specifications"
        downloadSpecification="&#11015; Download Specifications"
      />

      <ProductIntro
        title="AC-001"
        AC
        // Image={"./images/AC/AC.png"}
      />

      <BottomBarScroll />

      {/* <ScrollTop />s */}

      <ProductInfinityPara para1={para2} />


      <Box id="powering" className={classes.paddingView}>
        <ProductGridTitle
          h1="Powering the"
          h2="largest EV Fleets"
          h3="Across India"
        />
      </Box>

      <ProductGridOne mapData={AcGridOneData} />

      <Box id="logistcs" className={classes.paddingViewAll}>
        <RightImagesPara
          image={"./images/AC/center.png"}
          title="Logistics and Fleet Operators"
          para={paraRight}
          route="home"
        />
      </Box>

      <MakeInIndia />

      <Footer />
    </div>
  );
};
