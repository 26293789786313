import React, { useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
//Aos
import AOS from "aos";
import "aos/dist/aos.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    margin: "auto",
    paddingTop: "15%",
    paddingBottom: "10%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginTop: "20%",
      width: "90%",
    },
  },
  flexBox: {
    width: "45%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  flexBox1: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  head: {
    font: "normal normal bolder 3vw Urbanist",
    textAlign: "left",
    background: "#006934",
    background: "-webkit-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "-moz-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    marginBottom: "5%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10vw",
      textAlign: "center",
    },
  },
  para: {
    font: "normal normal 200 1.8vw Urbanist",
    opacity: ".6 !important",
    lineHeight: "1.5",
    [theme.breakpoints.down("sm")]: {
      fontSize: "6vw",
      textAlign: "justify",
    },
  },
}));

export const AllPageHead = ({ title, para1, para2 }) => {
  const classes = useStyles();

  const animation = () => {
    AOS.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  };

  useEffect(() => {
    animation();
  }, []);
  return (
    <Box className={classes.root}>
      <Box className={classes.flexBox}>
        <h1
          className={classes.head}
          dangerouslySetInnerHTML={{ __html: title }}
          data-aos="fade-up"
          data-aos-delay="30"
        ></h1>
      </Box>
      <Box className={classes.flexBox1}>
        <p className={classes.para} data-aos="fade-up">
          {para1}
        </p>
        <br />
        <p className={classes.para} data-aos-delay="50" data-aos="fade-up">
          {para2}
        </p>
      </Box>
    </Box>
  );
};
