import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import { Footer, TopBarAll } from "../../Molecules";
import { GovermentTemplates } from "../../Templates";

export const Goverment = () => {
  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <Box>

      <TopBarAll
        l1="Public Places"
        l1id="#public"
        l2="Smart Cities"
        l2id="#smart"
        l3="Highways"
        l3id="#highways"
        l4="Why EVRE"
        l4id="#whyevre"
        l5="Solutions for you"
        l5id="#next"
      />

      <GovermentTemplates
        l1id="public"
        l2id="smart"
        l3id="highways"
        l4id="whyevre"
        l5id="next"
      />

      <Footer />

    </Box>
  );
};
