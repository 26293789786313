import React, { useEffect } from "react";
//Material ui
import { Box, Grid, makeStyles } from "@material-ui/core";
//Aos
import AOS from 'aos';
import 'aos/dist/aos.css';

const useStyles = makeStyles((theme) => ({
  alignBox: {
    width: "80%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      marginBottom: '10%'
    }
  },
  mainPara: {
    marginTop: "5%",
    // marginBottom: "1%",
    font: "normal normal 400 2.5vw urbanist",
    color: "#00BF5F ",
    [theme.breakpoints.down("sm")]: {
      fontSize: '2rem'
    },
  },
  GridContainer: {
    marginTop: "1%",
    // border:'2px solid red'
  },
  HeadTitile: {
    font: "normal normal 400 1.8rem urbanist",
    color: "#FFF",
    [theme.breakpoints.down("sm")]: {
      fontSize: '1.5rem'
    },
  },
  subtitlePara: {
    font: "normal normal 400 1.5rem urbanist",
    color: "#747474",
    lineHeight: "1.4",
    marginTop: "5%",
    [theme.breakpoints.down("sm")]: {
      fontSize: '1.2rem'
    },
  },
}));

export const ProductGridSecond = ({ mainTitle, ...mapData }) => {
  const classes = useStyles();

  const animation = () => {
    AOS.init({
      easing: 'ease-out-back',
      duration: 1000,
    });
  }

  useEffect(() => {
    animation()
  }, [])
  return (
    <Box className={classes.alignBox}>
      <p className={classes.mainPara} data-aos="fade-up">{mainTitle}</p>
      <Grid spacing="5" container className={classes.GridContainer}>
        {mapData.mapData.map((item, index) => {
          const { title, subtitle } = item;
          return (
            <Grid item xs={12} sm={4} key={index}>
              <p className={classes.HeadTitile} data-aos="fade-up">{title}</p>
              <p className={classes.subtitlePara} data-aos="fade-up">{subtitle}</p>
            </Grid >
          );
        })}
      </Grid >
    </Box >
  );
};
