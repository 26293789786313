import React, { useEffect } from "react";
import { makeStyles, Typography, Box } from "@material-ui/core";
import { useHistory } from "react-router";
//Aos
import AOS from "aos";
import "aos/dist/aos.css";
import { ViewDetails } from "../../Atom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    marginTop:"15%",
    [theme.breakpoints.down("sm")]: {
      height: "100vh",
      justifyContent: "center",
      flexDirection: "column",
      width: '100%',
      margin: "0% auto"
    },
  },
  leftBox: {
    width: "45%",
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      width: "35%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  image: {
    marginRight: "8%",
    width: "18vw",
    [theme.breakpoints.down("sm")]: {
      margin: "0% 0%",
      width: "20%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "35%",
    },
  },
  rightBox: {
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "65%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin:"0 auto"
    },
  },
  heading: {
    font: "normal normal 900 8vw Urbanist",
    background: "#006934",
    background: "-webkit-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "-moz-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    [theme.breakpoints.down("md")]: {
      fontSize: "10vw",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "8vw",
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14vw",
    },
  },
  para: {
    width: "65%",
    marginLeft: "2%",
    font: "normal normal lighter 1.2rem Urbanist",
    lineHeight: "1.8",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      lineHeight: "1.5",
    },
  },
  rightArrow: {
    display: "inline-block",
    cursor: "pointer",
    marginLeft: "2%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "2%",
    },
  },
  explore: {
    marginTop: 10,
    color: "#00BF5F ",
    fontFamily: 'Gilroy',
    [theme.breakpoints.down("sm")]: {
      font: "normal normal 0.7rem Urbanist",
    },
  },
  arrowAlign: {
    marginLeft: 20,
    marginBottom: 20,
    width: "60",
    [theme.breakpoints.down("sm")]: {
      width: "40%",
    },
  },
}));

export const ACTemplate = () => {
  const classes = useStyles();
  const history = useHistory();

  const animation = () => {
    AOS.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  };

  useEffect(() => {
    animation();
  }, []);

  return (
    <Box className={classes.root}>
      <Box className={classes.leftBox}>
        <img src={"./images/AC/AC.png"} className={classes.image} />
      </Box>
      <Box className={classes.rightBox}>
        <Typography className={classes.heading}>AC-001</Typography>
        <Typography className={classes.para}>
          An indomitable and robust 2, 3, & 4 Wheeler EV Charging Station
          spearheading durability, performance, and multi-charge facility.
        </Typography>
        <Box
          className={classes.rightArrow}
          onClick={() => history.push("/ac001product")}
        >
          <Box
            className={classes.explore}
            // onClick={() => history.push("/ac001product")}
            // data-aos="fade-right"
          >
            {/* 
            <Typography
              className={classes.explore}
              onClick={() => history.push("/ac001product")}
              data-aos="fade-right"
            >
              Explore
            </Typography>
            <img
              src={"./images/Arrow/Arrow.svg"}
              alt="Arrow"
              width="60"
              className={classes.arrowAlign}
              onClick={() => history.push("/ac001product")}
              data-aos="fade-right"
            />
             */}

            <ViewDetails
              title="Explore"
              route_curr="ac001product"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
