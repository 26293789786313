import React, { useEffect } from "react";
//Material ui
import { Box, makeStyles, Slider } from "@material-ui/core";
//Aos
import AOS from "aos";
import "aos/dist/aos.css";
import { CursorCustom } from "../CursorCustom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "80%",
    color: "#ffffff",
    // marginTop: "10%",
    marginBottom: "15%",
    [theme.breakpoints.down("xs")]: {
      height: "90vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
  },
  text1: {
    font: "normal normal bolder 14vw Urbanist",
    textAlign: "center",
    background: "#006934",
    background: "-webkit-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "-moz-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    marginTop: "10%",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20vw",
    },
  },
  ImageTextBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  imageBox: {
    // width: "60%",
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    position: "relative",
    cursor: 'url(./images/Arrow/Arrow.png)',

    "&:hover": {
      "& $cursorDesign": {
        display: "flex",
        opacity: "1",

      }
    }
  },
  image: {
    width: "22vw",
    cursor: "none",
    cursor: 'url(./images/Arrow/Arrow.png)',

    [theme.breakpoints.down("xs")]: {
      width: "65vw",
    },
    "& img": {
      width: "100%",
      cursor: "none",
    },

  },
  Text: {
    font: "normal normal normal 1vw Urbanist",
    [theme.breakpoints.down("xs")]: {
      fontSize: "4vw",
    },
  },
  sliderHidden: {
    width: "22vw",
    height: "100%",
    position: "absolute",
    top: "0",
    left: "0",
    margin: "0 auto",
    // cursor: "none",
    cursor: 'url(./images/Arrow/Arrow.png)',
    [theme.breakpoints.down("xs")]: {
      width: "65vw",
    },
    "& .MuiSlider-thumb": {

      display: "none",
      transition: "box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
    },
    "& .MuiSlider-rail": {
      background: "#00FF7F",
      height: "100%",
      borderRadius: "5px",
      display: "none"
    },
    "& .MuiSlider-track": {
      background: "#00FF7F",
      borderRadius: "5px",
      display: "none",
    },
  },
  slider: {
    width: "12vw",
    // height: "100%",
    margin: "0 auto",
    [theme.breakpoints.down("xs")]: {
      width: "65vw",
    },
    "& .MuiSlider-rail": {
      background: "#00FF7F",
      cursor: "pointer",
      height: "6px",
      borderRadius: "5px",
    },
    "& .MuiSlider-track": {
      background: "#00FF7F",
      cursor: "pointer",
      borderRadius: "5px",
      height: "6px"
    },
    "& .MuiSlider-thumb": {
      background: "#00FF7F",
      borderRadius: "50%",
      cursor: "pointer",
      height: "15px",
      width: "15px",
      "&:hover": {
        "& $cursorDesign": {
          width: "70px",
          height: "70px",
        }
      }
    },
  },
  IntellipoleImage: {
    margin: "0 auto",
    width: "12vw",
    [theme.breakpoints.down("xs")]: {
      width: "55vw",
    },
    "& img": {
      width: "100%"
    }
  },
  rotationBox: {
    textAlign: 'center',
    "& img": {
      width: "100%",
      width: "3.5rem",
    }
  },
  rotation: {
    font: "normal normal bold 1rem Urbanist",
    textAlign: "center",
    color: "#7D7D7D"
  },
  cursorDesign: {
    display: "none",
    opacity: "0",
    transition: "opacity 200ms ease"
  }
}));
export const ProductIntro = ({
  title,
  Image,
  AC,
  Intellipole,
  Infinity,
  Pod
}) => {

  const [value, setValue] = React.useState(16);
  const [number, setnumber] = React.useState(16);

  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setnumber(value)
  };

  const animation = () => {
    AOS.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  };

  useEffect(() => {
    animation();
  }, [value]);


  return (
    <Box className={classes.root}>
      <h1 variant="h1" className={classes.text1} data-aos="fade-up" data-aos-delay="200">
        {title}
      </h1>
      <Box className={classes.ImageTextBox} data-aos="fade-up" data-aos-delay="300">
        <Box className={classes.imageBox}>

          {Image && <Box className={classes.image}>
            <img src={Image} />
          </Box>}

          {AC && <Box className={classes.image}>
            <img src={`./images/AllAngleImages/AC/a (${number}).png`} />
          </Box>}

          {Infinity && <Box className={classes.image}>
            <img src={`./images/AllAngleImages/Infinity/a (${number}).png`} />
          </Box>}

          {
            Intellipole &&
            <Box className={classes.IntellipoleImage}>
              <img src={`./images/AllAngleImages/Intellipole/a (${number}).png`} />
            </Box>
          }


          {Pod &&
            <Box className={classes.image}>
              <img src={`./images/AllAngleImages/Halo/a (${number}).png`} />
            </Box>
          }

          {
            !Image ?
              <Box >
                <Slider className={classes.sliderHidden} value={value} onChange={handleChange} min={1} max={30} />
                <Slider className={classes.slider} value={value} onChange={handleChange} min={1} max={30} />
                {/* <CursorCustom className={classes.cursorDesign} /> */}
                <Box className={classes.rotationBox}>
                  {/* <img src="./images/Rotation/rotation.png" /> */}
                  <p className={classes.rotation}>180&#176; VIEW</p>
                </Box>
              </Box>
              : ""
          }
        </Box>

        {/*<br/>
        <img src={'./images/Rotation/rotation.png'} alt="image_Rotation" data-aos="fade-up" />
        <br/>
        <h1 className={classes.Text} data-aos="fade-up">180&#xb0;View</h1>*/}
      </Box>
    </Box>
  );
};
