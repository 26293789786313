import React, { useRef, useState } from 'react'
import { Box, makeStyles, Typography } from "@material-ui/core";
import { ReactComponent as Authenticate } from '../../Assets/AboutEvreIcons/Authenticate.svg'
import { ReactComponent as Charge } from '../../Assets/AboutEvreIcons/Charge.svg'
import { ReactComponent as Locate } from '../../Assets/AboutEvreIcons/locate.svg'
import { ReactComponent as Navigate } from '../../Assets/AboutEvreIcons/Navigate.svg'
import { ReactComponent as Pay } from '../../Assets/AboutEvreIcons/Pay and move.svg'
import { ReactComponent as Reserve } from '../../Assets/AboutEvreIcons/Reserve.svg'
import { ReactComponent as Track } from '../../Assets/AboutEvreIcons/Track.svg'

import Authenticates from '../../Assets/AboutEvreIcons/Withoutcircle/Authenticate.svg'
import Charges from '../../Assets/AboutEvreIcons/Withoutcircle/Charge.svg'
import Locates from '../../Assets/AboutEvreIcons/Withoutcircle/locate.svg'
import Navigates from '../../Assets/AboutEvreIcons/Withoutcircle/Navigate.svg'
import Pays from '../../Assets/AboutEvreIcons/Withoutcircle/Pay and move.svg'
import Reserves from '../../Assets/AboutEvreIcons/Withoutcircle/Reserve.svg'
import Tracks from '../../Assets/AboutEvreIcons/Withoutcircle/Track.svg'

const useStyles = makeStyles((theme) => ({
    root: {
        width: "50%",
        margin: '0% auto',
        marginTop: '5%',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        }
    },
    gridFlex: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    gridFlexs: {
        width: '100%',
        height: '100px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // opacity:"0"
    },
    icons: {
        "& svg": {
            width: "100%"
        }
    },
    imgBox: {
        width: "5rem",
        "& img": {
            width: "100%"
        }
    },
    textBox: {
        marginTop: ".8rem",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    gridFlexs_heading: {
        font: "normal normal 600 1.6rem Urbanist",
        color: '#00BF5F',
        [theme.breakpoints.down("sm")]: {
            fontSize: "6vw",
            textAlign: 'justify'
        },
    },
    gridFlexs_paragraph: {
        font: "normal normal 200 1vw Urbanist",
        opacity: "0.6 !important",
        width: "65%",
        textAlign: "center",
        lineHeight: "1.5",
        [theme.breakpoints.down("sm")]: {
            fontSize: "1rem",
            textAlign: 'center'
        },
    },
    flex_grid: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    flex_grid_hover: {
        transition: "all .3s ease-in-out",
        borderRadius: "50%",
        "&:hover": {
            background: "#00BF5f",
            transform: "scale(1.15)",
        }
    },


}));


export const Technology = () => {
    const classes = useStyles();
    const [heading, setHeading] = useState("Navigate")
    const [paragraph, setParagraph] = useState("Navigate")
    // const [fillColor, setFillColor] = useState("")
    const [imageFill, setImageFill] = useState(Navigates)
    let svgRef = useRef([])
    console.log(svgRef)

    const handleState = ({ head, para, color, fillImage }) => {
        setHeading(head)
        setParagraph(para)
        // setFillColor(color)
        setImageFill(fillImage)
    }

    return (
        <div className={classes.root}>

            <div className={classes.gridFlex}>

                <a style={{ paddingTop: '25%' }} className={classes.icons} >
                    <Authenticate className={classes.flex_grid_hover}
                        onMouseOver={() => handleState({ head: 'Authenticate', para: 'User Authentication through Licence Plate Recognition', fillImage: Authenticates })}
                        style={{ cursor: 'pointer' }} />
                </a>

                <a style={{ paddingBottom: '25%' }} className={classes.icons}>
                    <Charge className={classes.flex_grid_hover}
                        onMouseOver={() => handleState({ head: 'Charge', para: 'Charging initiates on successful authentication', fillImage: Charges })}
                        style={{ cursor: 'pointer' }} />
                </a>

                <a style={{ paddingTop: '25%' }} className={classes.icons}>
                    <Track className={classes.flex_grid_hover}
                        onMouseOver={() => handleState({ head: 'Track', para: 'Live charging status on the EVRE Mobile App', fillImage: Tracks })}
                        style={{ cursor: 'pointer' }} />
                </a>

            </div>

            <div className={classes.gridFlexs} data-aos="fade-up">
                {/*<img src={'./images/AboutEvre/icon/'} 
                style={{ cursor: 'pointer' }} 
                    onMouseOver={() => setState("4")} alt="" />*/}
                <div className={classes.flex_grid}>
                    <div className={classes.imgBox}>
                        <img src={imageFill} />
                    </div>
                    <div className={classes.textBox}>
                        <h5 className={classes.gridFlexs_heading}>{heading}</h5>
                        <h5 className={classes.gridFlexs_paragraph}>{paragraph}</h5>
                    </div>
                </div>
                {/*<img src={'./images/AboutEvre/icon/'} 
                style={{ cursor: 'pointer' }} 
                    onMouseOver={() => setState("5")} alt="" />*/}
            </div>

            <div className={classes.gridFlex}>

                <a style={{ paddingBottom: '25%' }} className={classes.icons}>
                    <Locate className={classes.flex_grid_hover}
                        onMouseOver={() => handleState({ head: 'Locate', para: 'Available EV Charging Stations', fillImage: Locates })}
                        style={{ cursor: 'pointer' }} />
                </a>

                <a style={{ paddingTop: '25%' }} className={classes.icons}>
                    <Pay className={classes.flex_grid_hover}
                        onMouseOver={() => handleState({ head: 'Pay and move', para: 'Secure online billing and payments', fillImage: Pays })}
                        style={{ cursor: 'pointer' }} />
                </a>

                <a style={{ paddingBottom: '25%' }} className={classes.icons}>
                    <Reserve className={classes.flex_grid_hover}
                        onMouseOver={() => handleState({ head: 'Reserve', para: 'Your charging time slot', fillImage: Reserves })}
                        style={{ cursor: 'pointer' }} />
                </a>

            </div>

        </div>
    )
}
