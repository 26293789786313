import React, { useEffect, useState } from "react";
import { makeStyles, Box } from "@material-ui/core";
import { useHistory, useLocation } from "react-router";
//Aos
import AOS from "aos";
import "aos/dist/aos.css";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    width: "100%",
    position: "fixed",
    top: "0",
    zIndex: "1",
    backgroundColor: "#000",

    [theme.breakpoints.down("sm")]: {
      paddingBottom: "0%",
    },
  },
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "80%",
    margin: "0 auto",
    color: "#fff",
    paddingTop: "2%",

    [theme.breakpoints.down("sm")]: {
      width: "90%",
      padding: "1.2rem 0",
    },
  },
  heading: {
    cursor: "pointer",
    width: "8rem",
    [theme.breakpoints.down("sm")]: {
      width: "5.2rem",
    },
  },
  Menu: {
    width: "40%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "end",
    },
  },
  ul: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    listStyle: "none",
    font: "normal normal normal 1rem urbanist",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  li_ul: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  MenuIcon: {
    cursor: "pointer",
  },
  noActive: {
    font: "normal normal normal 1.1rem urbanist",
    textDecoration: "none",
    color: "#fff",
  },
  active: {
    color: "#00BF5F ",
  },
  NewNav: {
    width: "100%",
    marginTop: "2%",
    backgroundColor: "#0C0C0C",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  NewNavSecond: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "80%",
    margin: "0 auto",
  },
  chargeWithEVRE: {
    font: "normal normal normal 1rem urbanist",
    // [theme.breakpoints.down('sm')]: {
    //     display: 'none'
    // }
  },
  newUl: {
    display: "flex",
    justifyContent: "center",
    listStyle: "none",
    font: "normal normal normal 1rem urbanist",
    // [theme.breakpoints.down('sm')]: {
    //     display: 'none'
    // }
  },
  newLileft: {
    marginLeft: "1.5rem",
    cursor: "pointer",
  },
  newLi: {
    cursor: "pointer",
    height: "100%",
    padding: "1rem .8rem",
    textAlign: "center",
    transition: "all .3s ease-in-out",
    color: "grey",
    position: "relative",
    "&::before": {
      content: "''",
      position: "absolute",
      bottom: "0",
      left: "50%",
      width: "0%",
      height: "2px",
      background: "#00BF5F",
      transition: "all .4s ease-in-out",
    },
    "&::after": {
      content: "''",
      position: "absolute",
      bottom: "0",
      right: "50%",
      width: "0%",
      height: "2px",
      background: "#00BF5F",
      transition: "all .4s ease-in-out",
    },
    "&:hover": {
      color: "#fff",
      "&::before": {
        width: "50%",
      },
      "&::after": {
        width: "50%",
      },
    },
  },
  downloadBox: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    marginLeft: ".8rem",
  },
  downloadBtn: {
    fontFamily: "Gilroy",
    padding: ".5rem .8rem",
    textAlign: "center",
    transition: "all .5s ease-in-out",
    borderRadius: "3px",
    background: "transparent",
    border: "2px solid #00BF5F",
    color: "#00BF5F",
    cursor: "pointer",
    "&:hover": {
      background: "#00BF5F",
      color: "Black",
      border: "2px solid transparent",
    },
  },
}));

const active = {
  color: "#00BF5F",
  boxShadow: "inset 0 -2px 0 #00BF5F",
};

const unActive = {};

export const TopBarAll = ({
  l1,
  l1id,
  l2,
  l2id,
  l3,
  l3id,
  l4,
  l4id,
  l5,
  l5id,
  l6,
  l6id,
  genralNavigation,
  removeCharge,
  downloadSpecification,
  downloadFile,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { pathname } = useLocation();
  const [state, setState] = useState("");

  useEffect(() => {
    AOS.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  }, []);

  return (
    <Box className={classes.mainRoot}>
      <Box className={classes.root}>
        <img
          className={classes.heading}
          src={"./images/Logo/Logo.svg"}
          onClick={() => history.push("/home")}
          alt="Logo"
        />
        <Box className={classes.Menu}>
          {genralNavigation ? (
            <ul className={classes.ul}>
              <li>
                <NavLink
                  activeClassName={classes.active}
                  className={classes.noActive}
                  to="/home"
                >
                  Products
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName={classes.active}
                  className={classes.noActive}
                  to="/evreproduct"
                >
                  Charge with EVRE
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName={classes.active}
                  className={classes.noActive}
                  to="/aboutus"
                >
                  About Us
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName={classes.active}
                  className={classes.noActive}
                  to="/switch"
                >
                  Why make the Switch
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName={classes.active}
                  className={classes.noActive}
                  to="/contact"
                >
                  Contact Us
                </NavLink>
              </li>
            </ul>
          ) : (
            <ul className={classes.ul}>
              <li>
                <NavLink
                  activeClassName={classes.active}
                  className={classes.noActive}
                  to="/infinity"
                >
                  Products
                </NavLink>
              </li>
              <li>
                <NavLink
                  activeClassName={classes.active}
                  className={classes.noActive}
                  to="/solution"
                >
                  Solutions
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  activeClassName={classes.active}
                  className={classes.noActive}
                  to="/becomeadealer"
                >
                  Become a Dealer
                </NavLink>
              </li> */}
            </ul>
          )}
          <img
            className={classes.MenuIcon}
            src={"./images/TopNavbar/menu.png"}
            alt="logo"
            onClick={() => history.push("/menu")}
          />
        </Box>
      </Box>
      <Box className={classes.NewNav} data-aos="fade-up">
        <Box className={classes.NewNavSecond}>
          {removeCharge ? (
            ""
          ) : (
            <a href="/evreproduct">
              <h6 className={classes.chargeWithEVRE}>Go to Charge with EVRE</h6>
            </a>
          )}
          <ul className={classes.newUl}>
            {l1 ? (
              <a href={l1id} onClick={() => setState("id1")}>
                <li
                  style={state == "id1" ? active : unActive}
                  className={classes.newLi}
                >
                  {l1}
                </li>
              </a>
            ) : (
              ""
            )}

            {l2 ? (
              <a href={l2id} onClick={() => setState("id2")}>
                <li
                  style={state == "id2" ? active : unActive}
                  className={classes.newLi}
                >
                  {l2}
                </li>
              </a>
            ) : (
              ""
            )}
            {l3 ? (
              <a href={l3id} onClick={() => setState("id3")}>
                <li
                  style={state == "id3" ? active : unActive}
                  className={classes.newLi}
                >
                  {l3}
                </li>
              </a>
            ) : (
              ""
            )}

            {l4 ? (
              <a href={l4id} onClick={() => setState("id4")}>
                <li
                  style={state == "id4" ? active : unActive}
                  className={classes.newLi}
                >
                  {l4}
                </li>
              </a>
            ) : (
              ""
            )}
            {l5 ? (
              <a href={l5id} onClick={() => setState("id5")}>
                <li
                  style={state == "id5" ? active : unActive}
                  className={classes.newLi}
                >
                  {l5}
                </li>
              </a>
            ) : (
              ""
            )}
            {l6 ? (
              <a href={l6id} onClick={() => setState("id6")}>
                <li
                  style={state == "id6" ? active : unActive}
                  className={classes.newLi}
                >
                  {l6}
                </li>
              </a>
            ) : (
              ""
            )}
            {downloadSpecification ? (
              <a href={downloadFile} download>
                <li className={classes.downloadBox}>
                  <button className={classes.downloadBtn}>
                    {downloadSpecification}
                  </button>
                </li>
              </a>
            ) : (
              ""
            )}
          </ul>
        </Box>
      </Box>
    </Box>
  );
};
