import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "80%",
    margin: "0 auto",
    color: "#fff",
    paddingTop: "2%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "1.2rem 0"
    },
  },
  heading: {
    cursor: "pointer",
    width: "8rem",
    [theme.breakpoints.down("sm")]: {
      width: "5.2rem",
    },
  },
  Menu: {
    width: "65%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  ul: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    listStyle: "none",
    "& li": {
      font: "normal normal normal 1.1rem urbanist",
    }
  },
  MenuIcon: {
    cursor: "pointer",
  },
}));

export const EvreTopBar = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Box className={classes.root}>
      <img
        className={classes.heading}
        src={"./images/Logo/Logo.svg"}
        onClick={() => history.push("/home")}
        alt="Logo"
      />
      <Box className={classes.Menu}>
        <ul className={classes.ul}>
          <li onClick={() => history.push("/evreproduct")}>Charge with EVRE</li>
          <li onClick={() => history.push("/")}>About EVRE</li>
          <li onClick={() => history.push("/solutions")}>Solutions</li>
          <li onClick={() => history.push("/")}>
            Locate a Charger
          </li>
          {/* <li onClick={() => history.push("/franchise")}>
            Own a Franchise
          </li> */}
        </ul>
        <img
          className={classes.MenuIcon}
          src={"./images/TopNavbar/menu.png"}
          alt="logo"
          onClick={() => history.push("/menu")}
        />
      </Box>
    </Box>
  );
};
