import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import { Footer, TopBarAll } from "../../Molecules";
import { SolutionTemplates } from "../../Templates";

export const Solution = () => {
  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <Box>
      <TopBarAll
        l1="Residential Spaces"
        l1id="#residential"
        l2="Logistics"
        l2id="#logistics"
        l3="Business"
        l3id="#business"
        l4="Government"
        l4id="#government"
      />

      <SolutionTemplates
        l1id="residential"
        l2id="logistics"
        l3id="business"
        l4id="government"
      />
      <Footer />
    </Box>
  );
};
