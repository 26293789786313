export const AcGridOneData = [
  {
    title: "Total 10 kW Charging Provision",
    subtitle: "Charge multiple EVs in one go with 3 charging outlets.",
  },
  {
    title: "Charges within 4 to 6 Hours",
    subtitle:
      "Charging for fleets and logistics operators while they halt and refresh.",
  },
  {
    title: "Real-time Telemetry Data",
    subtitle:
      "Access real-time charging data like unit usage, charging sessions, payments, etc. in your EVRE dashboard or integrate with your existing management systems.",
  },
  {
    title: "Integrated with Mobile App",
    subtitle:
      "Access our ready-to-go customer app or integrate with your business app to deliver customer experience that cuts above the rest.",
  },
  {
    title: "Compatible with all 2, 3, & 4 Wheeler EVs",
    subtitle:
      "A one-stop charging solution for all types of electric 2, 3, & 4 Wheelers.",
  },
  // {
  //   title: "Patented Computer Vision Technology",
  //   subtitle:
  //     "With EVRE, enable your users to reserve their charging slot in advance using our patented number plate recognition technology.",
  // },
  {
    title: "Energy Metering",
    subtitle:
      "With Energy Metering, transparently track electricity unit consumption by EVs and earned revenue.",
  },
  {
    title: "Electrically Protected",
    subtitle:
      "State-of-the-art electrical protections, leaving little to chance.",
  },
  {
    title: "RFID/QR Code Based User Authentication",
    subtitle:
      "Gain utmost control of who uses your EV Chargers through custom RFID tags and QR Codes.",
  },
  {
    title: "Integrated Billing and Payments",
    subtitle:
      "With completely unmanned operations, manage your EV Charging income with integrated online billing and payments.",
  },
  {
    title: "Emergency Button",
    subtitle: "All the alarms and alerts you need in case of a fire breakout.",
  },
  {
    title: "OCPP 1.6 Compatibility",
    subtitle: "OCPP 1.6 compliant charging station that can push data to your CMS with little integration effort.",
  },
  {
    title: "Yours to Configure",
    subtitle: "Remotely configure your most important parameters such as pricing, cut-off thresholds, etc.",
  },
];

export const AcSecondData = [
  {
    title: "Input Voltage Rating",
    subtitle: "415 VAC (± 20%) Three Phase",
  },
  {
    title: "Input Current Rating",
    subtitle: "16 A per Phase",
  },
  {
    title: "Connection & Wiring",
    subtitle: "L1, L2, L3, N, G",
  },
  {
    title: "Standby Power",
    subtitle: "< 20 W",
  },
];

export const AcSecondData1 = [
  {
    title: "Output Rating",
    subtitle: "230 VAC (± 20%), 16 A per Phase",
  },
  {
    title: "Output Power",
    subtitle: "3.3 kW X 3",
  },
  {
    title: "Metering",
    subtitle: "Real-time three-phase energy metering",
  },
  {
    title: "Cold Load Pick-Up",
    subtitle: "Delay before charge resumes after power failure",
  },
];

export const AcSecondData2 = [
  {
    title: "Upstream Breaker",
    subtitle: "4-pole, 20 A breaker on dedicated circuit, non-GFCI type",
  },
  {
    title: "Plug-Out Protection",
    subtitle: "Output terminated upon charging connector plug-out",
  },
  {
    title: "Ground Fault",
    subtitle: "30 mA",
  },
  {
    title: "Surge Protection",
    subtitle: "Class-II, 4-pole up to 1.5 kV",
  },
  {
    title: "Electrical Protections",
    subtitle:
      "Over current, Over voltage, Under voltage, Ground fault, Surge protection",
  },
];

export const AcSecondData3 = [
  {
    title: "Buttons",
    subtitle: "Emergency Stop",
  },
  {
    title: "User Authentication",
    subtitle: "QR Code, Mobile App, RFID, Remote Start",
  },
  {
    title: "Status Indication",
    subtitle: "Multi-colour LED-Power, Charging, Diagnostic",
  },
];

export const AcSecondData4 = [
  {
    title: "EVSE to Server",
    subtitle: "OCPP 1.6",
  },
  {
    title: "Cloud Connectivity",
    subtitle: "Wi-Fi, Ethernet, GSM (optional)",
  },
];

export const AcSecondData5 = [
  {
    title: "Charging Interface",
    subtitle: "IEC 60309 Socket",
  },
  {
    title: "Number of Outputs",
    subtitle: "3",
  },
];

export const AcSecondData6 = [
  {
    title: "Operating Temperature",
    subtitle: "0° C to + 55° C",
  },
  {
    title: "Operating Humidity",
    subtitle: "5 to 95% relative humidity, non-condensing",
  },
];

export const AcSecondData7 = [
  {
    title: "Dimensions",
    subtitle: "660 mm x 360 mm x 130 mm",
  },
  {
    title: "Material",
    subtitle: "Aluminium",
  },
  {
    title: "Cooling",
    subtitle: "Natural air cooled",
  },
  {
    title: "Net Weight",
    subtitle: "11 kg",
  },
  {
    title: "Mounting Type",
    subtitle: "Wall Mount, Pedestal (optional)",
  },
];

export const AcSecondData8 = [
  {
    title: "Compliance",
    subtitle: "AC-001",
  },
];
