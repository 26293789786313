import React, { useEffect } from "react";
//Material ui
import { Box, makeStyles } from "@material-ui/core";
//Aos
import AOS from 'aos';
import 'aos/dist/aos.css';

//Style
const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: '100%'
    },
  },
  image: {
    width: "100%",
    height: "100%",
  },
  para: {
    width: "60%",
    marginTop: "5%",
    marginLeft: "15%",
    marginBottom: '15%',
    font: "normal normal 200 2vw Urbanist",
    lineHeight: "1.8",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin: "10% auto",
      fontSize: "6vw",
    },
  },
}));

export const ImageTopCenter = ({ image, para1 }) => {
  const classes = useStyles();

  const animation = () => {
    AOS.init({
      easing: 'ease-out-back',
      duration: 1000,
    });
  }

  useEffect(() => {
    animation()
  }, [])
  return (
    <Box className={classes.root}>
      <img className={classes.image} src={image} alt="imageCenter" data-aos="fade-up" />
      <h1 className={classes.para} data-aos="fade-up">{para1}</h1>
    </Box>
  );
};
