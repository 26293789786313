import React from "react";
import {
  AllPageHead,
  HomeNavigation,
  ImageTopCenter,
  ImageRightCenter,
  ImageLeftCenter,
  ElectriceVehicales,
  BottomCenterProduct,
  WhatNext,
  WhyEvreCoursal,
} from "../../Atom";
import { ResidentialCarousal, WhyEvreCoursalResidential } from '../../../Data'

const para =
  "Tap into the future of Real Estate by equipping your properties with EVRE’s EV Charging Infra. Leverage the nascence of the EV Revolution now and attract the majority share of EV owners to purchase or rent your properties by making them future-ready.";
const paraCenter =
  "Majority of EV owners prefer charging their Electric Vehicles at home. This is what makes EV Charging a crucial prerequisite for building future-ready residential spaces.";
const paraRight =
  "Real Estate Businesses and Resident Welfare Associations hold the key to unlock a sustainable future for the world. The demand for EVs is increasing by the day and soon EV Charging Infra will become a necessity and compliance for Residential Spaces. Meeting these demands now can pique the majority of EV Owners’ interest towards your property and help you generate new revenue streams from rentals, sales, and EV Charging.";
const paraLeft =
  "Switch from unregulated 3 pin socket solutions that may endanger the safety of your home and your EV assets. Explore pocket-friendly EV Chargers for homes that are electrically protected and can power multiple EVs at a time.";
const paraBottom =
  "A premium 4-wheeler EV Charging Station with superior design, class-apart performance, and centennial durability.";

export const ResidentialTemplates = ({
  l1id,
  l2id,
  l3id,
  l4id
}) => {
  return (
    <>
      <HomeNavigation name="Solutions" />

      <AllPageHead title="Residential</br> Spaces" para1={para} />

      <ImageTopCenter
        para1={paraCenter}
        image={"./images/Infinity/Right1.png"}
      />

      <div id={l1id}>
        <ImageRightCenter
          title="Townships, Apartments, and High Rise Buildings"
          para={paraRight}
          image={"./images/ProductCommonImages/centerone.png"}
        />
      </div>

      <div id={l2id}>
        <ImageLeftCenter
          title="Independent Houses"
          para={paraLeft}
          image={"./images/Infinity/Right1.png"}
        />
      </div>

      <ElectriceVehicales />

      <div id={l3id}>
        <WhyEvreCoursal CarousalData={WhyEvreCoursalResidential} />
      </div>

      <BottomCenterProduct
        data={ResidentialCarousal}
      />

      <div id={l4id}>
        <WhatNext />
      </div>

    </>
  );
};
