import React from "react";
import { HomeSolutionNavigation } from "../../Atom";
import { FirstBox } from "./FirstBox";
import { SecondBox } from './SecondBox'
import { ThirdBox } from "./ThirdBox";
import { FifthBox } from "./FifthBox";
import { SixthBox } from "./SixthBox";
import { EighthBox } from "./EighthBox";
import { DashBoard } from "./DashBoard";

// const useStyles = makeStyles((theme) => ({}));

export const AboutEvreTemplate = ({ l1id, l2id, l3id, l4id, l5id }) => {
  // const classes = useStyles();

  return (
    <>
      <HomeSolutionNavigation name="About EVRE" />

      <FirstBox />

      <div id={l1id} >
        <SecondBox />
      </div>

      <div id={l2id} >
        <ThirdBox />
      </div>

      <div id={l3id} >
        <FifthBox />
      </div>

      <div id={l4id} >
        <SixthBox />
      </div>

      <DashBoard />

      <div id={l5id} >
        <EighthBox />
      </div>
    </>
  );
};
