import React, { useEffect } from "react";
//Material ui
import { Box, makeStyles } from "@material-ui/core";
//Router
import { useHistory } from "react-router";
//Aos
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ViewDetails } from "..";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "75%",
    height: "100%",
    margin: "0 auto",
    marginTop: "5%",
    marginBottom: "5%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    }
  },
  imageBox: {
    width: "80%",
    margin: "auto",
    marginBottom: "4%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  image: {
    width: "100%",
  },
  paraTitle: {
    color: "#00BF5F ",
    margin: "0% 0% 1% 0%",
    // font: "normal normal normal 2rem urbanist",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: '5%'
    },
  },
  para: {
    width: "50%",
    color: "#FFF",
    lineHeight: "1.8",
    font: "normal normal 200 1.5vw urbanist",
    marginTop: 10,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: "6vw",
      padding: '0% 5%'
    },
  },
  view_det: {
    color: "#00BF5F",
    lineHeight: "1.8",
    font: "normal normal 500 1.1vw urbanist",
    marginTop: 10,
    cursor: 'pointer',
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: "8vw",
      marginLeft: "5%",
    },
  },
  arrowAlign: {
    marginLeft: '5rem',
    marginTop: '-2%',
    width: "70px",
    [theme.breakpoints.down("sm")]: {
      width: "30%",
    },
  },
  btnBox:{
    [theme.breakpoints.down("sm")]: {
      width:"90%",
      margin:"0 auto",
    },
  }
}));

export const LeftImagesPara = ({ image, title, para, route }) => {
  const classes = useStyles();
  const history = useHistory();

  const animation = () => {
    AOS.init({
      easing: 'ease-out-back',
      duration: 1000,
    });
  }

  useEffect(() => {
    animation()
  }, [])
  return (
    <Box className={classes.root} >

      <Box className={classes.imageBox}>
        <img className={classes.image} src={image} alt="Image" data-aos="fade-up" />
      </Box>

      <Box>
        <h4 className={classes.paraTitle} onClick={() => history.push(`/${route}`)} data-aos="fade-up">{title}</h4>
        <h6 className={classes.para} data-aos="fade-up">{para}</h6>
      </Box>

      <Box className={classes.btnBox}>
      <ViewDetails
        title="view details"
        route={route}
      />
      </Box>
    </Box>
  );
};
