import React from "react";
import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "80%",
        margin: "auto",
        marginTop: "5%",
        marginBottom: "2%",
        [theme.breakpoints.down("sm")]: {
           marginTop:"10%"
        },
    },
    head: {
        font: "normal normal bolder 3vw Urbanist",
        marginLeft: '10%',
        background: "#006934",
        background: "-webkit-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
        background: "-moz-linear-gradient(to top, #006934 0%,#00BF5F 100%)",
        background: "linear-gradient(to top, #006934 0%,#00BF5F 100%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        marginBottom: "2%",
        [theme.breakpoints.down("sm")]: {
            fontSize: "2rem",
            marginLeft: '0%',
            textAlign: "center",
        },
    },
    flexBox: {
        // width: "80%",
        margin: "auto",
        display: "flex",
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            flexDirection: 'column-reverse'
        }
    },
    boxFlex: {
        width: "45%",
        height: "100%",
        [theme.breakpoints.down("sm")]: {
            width: '100%'
        }
    },
    boxFlex1: {
        width: "50%",
        height: "100%",
        [theme.breakpoints.down("sm")]: {
            width: '100%'
        }
    },
    image: {
        width: "100%",
        [theme.breakpoints.down("sm")]: {
            width: '100%'
        }
    },
    para: {
        font: "normal normal 200 1.6vw Urbanist",
        lineHeight: "1.5",
        width: "100%",
        // opacity: ".5",
        [theme.breakpoints.down("sm")]: {
            fontSize: '6vw'
        }
    },
}));

export const HeadFenchise = () => {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            <h1 className={classes.head}>Own-A-Franchise</h1>
            <Box className={classes.flexBox}>
                <Box className={classes.boxFlex}>
                    <p className={classes.para}>
                        Gain access to a ready-to-go EV Charging business by becoming an EVRE Franchise.
                        We are looking for partners who can help us build India’s largest EV Charging Network and become market dominators.
                    </p>
                    <br />
                    <br />
                    <p className={classes.para}>
                        As an EVRE Franchise, you will gain access to a one-stop EV Charging Infrastructure along with EVRE’s brand name.
                        As a parent business, we will take care of all the complexities of an EV charging business while you focus on retaining and attracting new customers and boosting your profits.
                    </p>
                </Box>
                <Box className={classes.boxFlex1}>
                    <img
                        className={classes.image}
                        src={"./images/Franchise/franchise.png"}
                        alt="Dealer"
                    />
                </Box>
            </Box>
        </Box>
    );
};