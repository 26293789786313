import React, { useEffect } from "react";
import { TopBarAll, Footer, SolutionsTabBar } from "../../Molecules";
import { AboutUsTemplate } from "../../Templates";

export const AboutUs = () => {
  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div>

      <SolutionsTabBar
        l1="Our Vision"
        l1id="#vision"
        l2="Our Mission"
        l2id="#mission"
        l3="Our Story"
        l3id="#story"
        l4="Our Reach"
        l4id="#reach"
        l5="Team"
        l5id="#team"
        genralNavigation="genralNavigation"
      />

      <AboutUsTemplate
        l1id="vision"
        l2id="mission"
        l3id="story"
        l4id="reach"
        l5id="team"
      />

      <Footer />

    </div>
  );
};
