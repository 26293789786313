export const contactData = [
  {
    title: "BENGALURU OFFICE",
    subtitle: "AMPLIFY CLEANTECH SOLUTION PRIVATE LIMITED.",
    subtitles:
      "4TH Floor, BMTC Building, Yeshwanthpur, Bengaluru, Karnataka, India - 560022.",
  },
  {
    title: "HYDERABAD OFFICE",
    subtitle: "AMPLIFY CLEANTECH SOLUTION PRIVATE LIMITED.",
    subtitles:
      "Plot No. 72, Road No. 9, Jubilee Hills, Hyderabad, Telangana, India - 500033.",
  },
  {
    title: "MANUFATURING OFFICE",
    subtitle: "AMPLIFY CLEANTECH SOLUTION PRIVATE LIMITED.",
    subtitles:
      "10-83, Adarsh Nagar, Adjeetpura Nagar, Sowbhagya Nagar, Gurumurthy Nagar, Balanagar, Hyderabad, Telangana, India - 500037.",
  },
  {
    title: "CONTACT",
    subtitle: "+91 90524 90524 | hello@evre.in",
  },
];
