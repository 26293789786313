import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root:{
    width:"100%",
    display: "flex",
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  rootin: {
    height: '100%',
    margin: "3% 2%",
    cursor:'pointer'
  },
  imageBox: {
    width: "16vw",
    height: "16vw",
    overflow: 'hidden',
    margin: "0 auto",
    borderRadius: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "50vw",
      height:'50vw',
    },
  },
  images: {
    objectFit: "cover",
    objectPosition: 'center',
    width: "100%",
    // maxHeight: '100%'
  },
  name: {
    font: "normal normal 1.2rem Urbanist",
    textAlign: "center",
    marginTop: 30,
  },
  holder: {
    font: "normal normal 1.2rem Urbanist",
    color: "#00BF5F ",
    textAlign: "center",
    marginTop: 10,
  },
}));

export const TeamProfile = ({ data }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      {
        data.map((item, index) => {
          return (
            <Box className={classes.rootin}>
              <Box className={classes.imageBox}>
                <img src={item.image} className={classes.images} />
              </Box>
              <Typography className={classes.name}>{item.name}</Typography>
              <Typography className={classes.holder}>{item.designation}</Typography>
            </Box>

          )
        })
      }
    </Box>
  );
};
