import React from "react";
import { makeStyles, Box } from "@material-ui/core";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    width: '100%',
    position: 'fixed',
    top: '0',
    zIndex: '1',
    backgroundColor: '#000',
  },
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "80%",
    margin: "0 auto",
    color: "#fff",
    padding: "2% 0",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      padding: "1.2rem 0",
    },
  },
  heading: {
    cursor: "pointer",
    width: "8rem",
    [theme.breakpoints.down("sm")]: {
      width: "5.2rem",
    },
  },
  Menu: {
    width: "30%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "end",
    },
  },
  ul: {
    width: "100%",
    display: "flex",
    justifyContent: "space-evenly",
    listStyle: "none",
    font: "normal normal normal 1rem urbanist",
    [theme.breakpoints.down("sm")]: {
      display: 'none'
    },
    "& li": {
      font: "normal normal normal 1.1rem urbanist"
    }
  },
  MenuIcon: {
    cursor: "pointer",
  },
}));

export const TopNavbar = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Box className={classes.mainRoot}>
      <Box className={classes.root}>
        <img
          className={classes.heading}
          src={"./images/Logo/Logo.svg"}
          onClick={() => history.push("/home")}
          alt="Logo"
        />
        <Box className={classes.Menu}>
          <ul className={classes.ul}>
            <li onClick={() => history.push("/solution")}>Solutions</li>
            {/* <li onClick={() => history.push("/becomeadealer")}>Become a Dealer</li> */}
          </ul>
          <img
            className={classes.MenuIcon}
            src={"./images/TopNavbar/menu.png"}
            alt="logo"
            onClick={() => history.push("/menu")}
          />
        </Box>
      </Box>
    </Box>
  );
};
