import React from "react";
import {
  AllPageHead,
  HomeNavigation,
  ImageTopCenter,
  ImageRightCenter,
  ImageLeftCenter,
  ElectriceVehicales,
  BottomCenterProduct,
  WhatNext,
  WhyEvreCoursal,
} from "../../Atom";
import { CommercialCarousal, WhyEvreCoursalCommercial } from "../../../Data";

const para =
  "Fast-track your business growth with EVRE. The world is going to home millions of EVs in the coming years and the EV owners will opt for businesses where they can charge their EVs while availing other services.";
const paraCenter =
  "The world is switching to electric vehicles and is in dire need of a sustainable EV Charging infra. Businesses can leverage this switch by capitalizing on the 21st century’s most disruptive business models - Providing EV Charging as a Service. Add new revenue streams to your existing business with EVRE.";
const paraRight =
  "Leverage the EV Revolution to attract more customers to your business. EV owners like to charge with businesses that they trust. Businesses like retail kirana stores, departmental stores, or shopping complexes can increase the in-store time of their customers thereby scaling sales while earning simultaneously by charging electric vehicles.";
const paraRight1 =
  "With the rising competition and omnipresence of leading brands in multiple destinations, it’s about time Malls add a premium yet revenue-generating service to their bouquet. Equipping EV Chargers in parking spaces can help malls not only redirect the major traffic of EV owners to their spaces but also earn additional revenue by providing safe parking and charging as a service.";
const paraLeft =
  "Along with gaining more walk-ins, Restaurants can also build better visibility and brand by providing EV Charging as a Service. Apart from retaining existing customers, you can also gain new ones by showing up as a charging station on EVRE’s mobile app. With increased footfall and dining time, you can give a double boost to your revenue while becoming the go-to restaurant in your locality.";
const paraLeft1 =
  "The hospitality business is a competitive one. Apart from luxury, clients expect world-class services within the vicinity of their hotels. The world is moving towards electric vehicles and hotels that leverage this and add EV Charging to their services can not only set precedence but also gain a competitive edge and an additional revenue stream.";
const paraBottom =
  "Unveiling a 2, 3, & 4 Wheeler EV Charging Station for assorted fleets.";

export const CommercialTemplates = ({
  l1id,
  l2id,
  l3id,
  l4id,
  l5id,
  l6id,
  l7id,
}) => {
  return (
    <>
      <HomeNavigation name="Solutions" />

      <AllPageHead
        title="Logistics and Fleet Operators"
        para1="We are building India’s largest and most advanced EV Charging network for logistics and fleet operators, and last-mile delivery service providers. Our charging hubs are secured and insured spaces with a variety of EV Chargers that are compatible with all-wheeler EVs. We are also partnering with leading fleet operators to help them set up charging hubs exclusively for their companies with business-first features for non-stop operations."
      />
      <AllPageHead title="Commercial Spaces" para1={para} />

      <ImageTopCenter
        para1={paraCenter}
        image={"./images/ProductCommonImages/center.png"}
      />

      <div id={l1id}>
        <ImageRightCenter
          title="Retail Stores"
          para={paraRight}
          image={"./images/ProductCommonImages/comone.png"}
        />
      </div>

      <div id={l2id}>
        <ImageLeftCenter
          title="Restaurants"
          para={paraLeft}
          image={"./images/ProductCommonImages/comtwo.png"}
        />
      </div>

      <div id={l3id}>
        <ImageRightCenter
          title="Malls"
          para={paraRight1}
          image={"./images/ProductCommonImages/center.png"}
        />
      </div>

      <div id={l4id}>
        <ImageLeftCenter
          title="Hotels"
          para={paraLeft1}
          image={"./images/ProductCommonImages/comthree.png"}
        />
      </div>

      <ElectriceVehicales />

      <div id={l5id}>
        <WhyEvreCoursal CarousalData={WhyEvreCoursalCommercial} />
      </div>

      <div id={l6id}>
        <BottomCenterProduct data={CommercialCarousal} />
      </div>

      <div id={l7id}>
        <WhatNext />
      </div>
    </>
  );
};
