import React from "react";
import { DealerHeadTemplate } from "./DealerHeadTemplate";
import { Why } from "./Why";
import { DealerEnquiryForm, WhyBecomeEvre } from "../../Molecules";
import { becomeDealer } from '../../../Data/WhyEvreCoursalData'


export const BecomeADealerTemplate = ({ l1id, l2id, l3id }) => {


  return (
    <div>
      <DealerHeadTemplate />

      <div id={l1id}>
        <WhyBecomeEvre data={becomeDealer} head="Why become an" middleHead="EVRE" head1='Dealership?' />
      </div>

      <div id={l2id}>
        <Why />
      </div>

      <div id={l3id}>
        <DealerEnquiryForm />
      </div>
    </div>
  );
};
