import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  head: {
    font: "normal normal bolder 8rem Urbanist",
    background: "#006934",
    marginBottom: "10%",
    background: "-webkit-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "-moz-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3rem",
    },
  },
  boxOne: {
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: '10%',
    },
  },
  boxtwo: {
    width: "40%",
    marginLeft: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: "0%",
      marginTop: '10%',
    },
  },
  titleText: {
    font: "normal normal 300 3rem Urbanist",
    [theme.breakpoints.down("sm")]: {
      font: "normal normal 400 8vw Urbanist",
    },
  },
  paraText: {
    font: "normal normal  lighter 1.5rem Urbanist",
    lineHeight: "1.5",
    width: "90%",
    marginTop: 10,
    [theme.breakpoints.down("sm")]: {
      font: "normal normal  lighter 6vw Urbanist",
      width: "90%",
    },
  },
}));

export const WhyEvre = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <h1 className={classes.head}>Why EVRE ?</h1>
      <Box className={classes.boxOne}>
        <Typography className={classes.titleText}>
          Fastest time-to-market and value
        </Typography>
        <Typography className={classes.paraText}>
          The demand for EV charging solutions is steep and we ensure you
          capitalize on this demand catching the fastest route to value
          generation.
        </Typography>
      </Box>
      <Box className={classes.boxtwo}>
        <Typography className={classes.titleText}>
          Experience guided support
        </Typography>
        <Typography className={classes.paraText}>
          We know the ins and outs of an EV Charging business and how it sells.
          Our team of technology and business experts will guide you each step
          of the journey on how to succeed as an EV Infra Builder.
        </Typography>
      </Box>
      <Box className={classes.boxOne}>
        <Typography className={classes.titleText}>
          Promising business opportunity
        </Typography>
        <Typography className={classes.paraText}>
          We present you with a disruptive yet sustainable business opportunity
          which makes EVRE ideal for its customers and partners. Not only this,
          with the increasing demand for EV Charging Infra, you can also churn
          the best results leveraging the market demand.
        </Typography>
      </Box>
    </Box>
  );
};
