import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  view_det_box: {
    display: "flex",
  },
  view_det: {
    color: "#00FF7F",
    lineHeight: "1.8",
    font: "normal normal 500 1.4rem Gilroy",
    marginTop: 10,
    cursor: "pointer",
    padding: "0% .2px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      // width: "100%",
      fontSize: "1.2rem",
      marginTop: 0,
      padding: "0%",
    },
    "&::before": {
      content: "''",
      position: "absolute",
      bottom: "0",
      left: "50%",
      width: "0%",
      height: "2px",
      background: "#00FF7F",
      transition: "all .4s ease-in-out",
    },
    "&::after": {
      content: "''",
      position: "absolute",
      bottom: "0",
      right: "50%",
      width: "0%",
      height: "2px",
      background: "#00FF7F",
      transition: "all .4s ease-in-out",
    },
    "&:hover": {
      "& p": {
        color: "#00FF7F !important",
      },
      "&::before": {
        width: "50%",
      },
      "&::after": {
        width: "50%",
      },
    },
  },
  curves: {
    height: "100%",
    width: "100%",
    transform: "matrix(0.96, -0.29, 0.29, 0.96, 0, 0)",
    border: "2px solid #00FF7F",
    position: "absolute",
    top: "0",
    left: "0",
    borderRadius: "50%",
    opacity: "0.5",
    cursor: "pointer",
  },

  curvesBox: {
    position: "relative",
    font: "normal normal 500 1.4rem Gilroy",
    cursor: "pointer",
    transition: "all .3s ease-in-out",
    "&:hover": {
      color: "#00FF7F !important",
    },
  },
}));

export const ViewDetails = ({
  className,
  route,
  route_curr,
  title,
  color,
  style,
  curves,
  window_route,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const handleRoute = () => {
    window_route
      ? window.open(window_route)
      : route_curr
      ? history.push(`/${route_curr}`)
      : window.open(`/${route}`, "_blank");
  };

  return (
    <a>
      <Box className={classes.view_det_box} style={style} onClick={handleRoute}>
        {curves ? (
          <Typography
            className={classes.curvesBox}
            style={{ color: `${color}` }}
          >
            {" "}
            {title} <span className={classes.curves}></span>
          </Typography>
        ) : (
          <Typography
            className={classes.view_det}
            style={{ color: `${color}` }}
          >
            {title}
          </Typography>
        )}
      </Box>
    </a>
  );
};
