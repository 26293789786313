export const WhyEvreCoursalResidential = [
    {
        title: 'Dynamic Load Management',
        paragraph: "Take the easiest & most economical step towards electric vehicle charging with EVRE. With our patented Dynamic Load Management tech, RWA’s and Real Estate Owners can provide EV charging without the need of buying extra electrical load."
    },
    {
        title: 'RFID and QR Code for User Authentication',
        paragraph: "Practice utmost control of who uses your EV Charging facilities. With EVRE’s custom RFID tags and QR Codes, you can authenticate your users limiting access of your EV Chargers only to your residents."
    },
    {
        title: 'Energy Metering and Real-time Updates on Cloud',
        paragraph: "Our chargers come with Energy Meters and are cloud-connected. This enables you to transparently track the electricity unit consumption by EVs, no. of charging sessions and, billings and payments on your EVRE dashboard."
    },
    {
        title: 'Mobile App Integration',
        paragraph: "We are a customer-centric product company. Our EV Charging Infra can be accessed by your customers using our EVRE Mobile App or be integrated to your existing business app."
    },
    {
        title: 'Premium Designs',
        paragraph: "We hold patents for the designs of our custom EV Chargers. We understand the value of aesthetics in real estate and hence provide EV Charging solutions that are aesthetically designed and technologically sound."
    },
]

export const WhyEvreCoursalCommercial = [
    {
        title: 'Dynamic Load Management',
        paragraph: "Take the easiest & most economical step towards electric vehicle charging with EVRE. With our patented Dynamic Load Management tech, you can provide EV charging as a service without the need of buying extra electrical load."
    },
    {
        title: 'RFID and QR Code for User Authentication',
        paragraph: "Practice utmost control of who uses your EV Charging facilities. With EVRE’s custom RFID tags and QR Codes, you can authenticate users and limit the access of your EV chargers to select individuals. "
    },
    {
        title: 'Energy Metering and Real-time Updates on Cloud',
        paragraph: "Our chargers come with Energy Meters and are cloud-connected. This enables you to transparently track the electricity unit consumption by EVs, no. of charging sessions and, billings and payments on your EVRE dashboard."
    },
    {
        title: 'Mobile App Integration',
        paragraph: "We are a customer-centric product company. Our EV Charging Infra can be accessed by your customers using our EVRE Mobile App or be integrated to your existing business app."
    },
    {
        title: 'Premium Designs',
        paragraph: "We hold patents for the designs of our custom EV Chargers making them aesthetically superior and technologically sound."
    },
]

export const WhyEvreCoursalBusiness = [
    {
        title: 'Dynamic Load Management',
        paragraph: "Take the easiest & most economical step towards electric vehicle charging with EVRE. With our patented Dynamic Load Management tech, you can provide EV charging as a service without the need of buying extra electrical load."
    },
    {
        title: 'RFID and QR Code for User Authentication',
        paragraph: "Practice utmost control of who uses your EV Charging facilities. With EVRE’s custom RFID tags and QR Codes, you can authenticate users and limit the access of your EV chargers to select individuals. "
    },
    {
        title: 'Energy Metering and Real-time Updates on Cloud',
        paragraph: "Our chargers come with Energy Meters and are cloud-connected. This enables you to transparently track the electricity unit consumption by EVs, no. of charging sessions and, billings and payments on your EVRE dashboard."
    },
    {
        title: 'Mobile App Integration',
        paragraph: "We are a customer-centric product company. Our EV Charging Infra can be accessed by your customers using our EVRE Mobile App or be integrated to your existing business app."
    },
    {
        title: 'Premium Designs',
        paragraph: "We hold patents for the designs of our custom EV Chargers making them aesthetically superior and technologically sound."
    },
]

export const WhyEvreCoursalGovernment = [
    {
        title: 'Dynamic Load Management',
        paragraph: "Take the easiest & most economical step towards electric vehicle charging with EVRE. With our patented Dynamic Load Management tech, you can provide EV charging as a service without the need of buying extra electrical load."
    },
    {
        title: 'RFID and QR Code for User Authentication',
        paragraph: "Practice utmost control of who uses your EV Charging facilities. With EVRE’s custom RFID tags and QR Codes, you can authenticate users and limit the access of your EV chargers to select individuals. "
    },
    {
        title: 'Energy Metering and Real-time Updates on Cloud',
        paragraph: "Our chargers come with Energy Meters and are cloud-connected. This enables you to transparently track the electricity unit consumption by EVs, no. of charging sessions and, billings and payments on your EVRE dashboard."
    },
    {
        title: 'Mobile App Integration',
        paragraph: "We are a customer-centric product company. Our EV Charging Infra can be accessed by your customers using our EVRE Mobile App or be integrated to your existing business app."
    },
    {
        title: 'Premium Designs',
        paragraph: "We hold patents for the designs of our custom EV Chargers making them aesthetically superior and technologically sound."
    },
]


export const ownFranchise = [
    {
      head: "Ready-to-go Business Model",
      para: "We are India’s leading EV Charger OEMs and EV Infra developers. Becoming an EVRE Franchise gives you a ready-to-go business model with defined business plans and objectives."
    },
    {
      head: 'Access to EVRE’s Innovative Charging Solutions',
      para: 'Unlock the true potential of an EV Charging Business with EVRE’s design-first and innovation-driven infrastructure offerings. Our solutions lead by example in terms of first-of-its-kind tech, design, variety of charging stations. '
    },
    {
      head: 'Access to EVRE’s EV Charging Network',
      para: 'We are currently present across 8 major cities in India and are expanding. As an EVRE franchise, you will derive the competitive advantage that comes with partnering with an established brand.'
    },
    {
      head: 'Partner with a globally competitive brand',
      para: 'We are redefining the status quo of conventional EV Charging. Our EV Charging solutions hold design and technology patents which makes us stand in line with international EV Charging brands from across the globe.  '
    },
  ]


  export const becomeDealer = [
    {
      head: 'Partner with a leading EV OEM',
      para: 'We are one of the leading EV Charger OEMs in India with a disparate range of patented EV Charging Solutions. Becoming a dealership gives you access to our globally competing products that are well-known across leading brands.'
    },
    {
      head: 'International grade EV charging infra',
      para: 'We are the first EV OEMs in India to introduce computer vision and dynamic load management technology in our EV Chargers. These patents make us stand in line with global brands providing the best-in-class EV Charging Infra.'
    },
    {
      head: 'Faster time-to-market',
      para: 'With our in-house manufacturing units, we provide our dealerships with ready-to-go innovative products making the time-to-market extremely competitive.'
    },
    {
      head: 'Bespoke EV Charging Solutions',
      para: 'We are breaking the norm in EV charging by catering to new-age EV charging solutions powered with patented our tech and designs. These solutions are bespoke and serve multi-dimensional use cases.'
    },
    {
      head: '100% Made in India Products',
      para: 'All our products are 100% made in India. We believe in the concept of indigenously building in India and for India which also makes us one of the most accepted EV Charger brands in the country. '
    },
  ]