import React, { useEffect } from "react";
//Material ui
import { Box, makeStyles } from "@material-ui/core";
//Aos
import AOS from "aos";
import "aos/dist/aos.css";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: "0 auto",
    height: "80vh",
    color: "#ffffff",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height:"fit-content",
      flexDirection: "column",
    },
  },
  paraBox: {
    width: "40%",
    height: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin: "10% auto",
    },
  },
  para: {
    lineHeight: "1.8",
    font: "normal normal lighter 1.87rem Urbanist",
    [theme.breakpoints.down("xs")]: {
      fontSize: "6vw",
    },
  },
}));
export const ProductInfinityPara = ({ para1 }) => {
  const classes = useStyles();

  const animation = () => {
    AOS.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  };

  useEffect(() => {
    animation();
  }, []);
  return (
    <Box className={classes.root}>
      <Box className={classes.paraBox}>
        <h6 className={classes.para} data-aos="fade-up">
          {para1}
        </h6>
      </Box>
    </Box>
  );
};
