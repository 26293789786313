import React, { useEffect } from "react";
import { Footer, ScrollTop, TopBar, SolutionsTabBar } from "../../Molecules";
import { EvreProductTemplate } from "../../Templates";

export const Evreproduct = () => {
  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <SolutionsTabBar
        l1="About Evre"
        l1id="#weare"
        l2="Solutions"
        l2id="#21centery"
        l3="Franchise Overview"
        l3id="#disrupt"
        l4="EVRE Mobile App"
        l4id="#evreapp"
        // genralNavigation="genralNavigation"
      />
      <EvreProductTemplate
        l1id="weare"
        l2id="21centery"
        l3id="disrupt"
        l4id="evreapp"
      />
      <Footer />
    </div>
  );
};
