import React, { useEffect, useState } from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { BottomBar, TopNavbar } from "../../Molecules";
import { useHistory } from "react-router";
import { NavLink } from 'react-router-dom'
import { InfinityTemplate, ACTemplate, PodTemplate, IntellipoleTemplate, UpcommingTemplatesFix } from "../../Templates";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/splide.min.css';
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';
import { useStyles } from '../../Assets/AllPrdouctStyle'
import clsx from "clsx";


export const Intellipole = ({ style }) => {
  const classes = useStyles();
  const history = useHistory();
  const [state, setState] = useState(0)

  const handleActive = (index) => {
    setState(index)
  }
  const handleHover = (state, route)=>{
    setTimeout(() => history.push(`/${route}`), 100)
    setState(state)
  }



  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' });
    console.log(state)
  }, [state])
  return (
    <div>
      <TopNavbar />
      {/* <InfinityTemplate /> */}

      <Splide
       className={classes.slider}
       onMove={(dest, index) => handleActive(index)}
       options={{
         width: '80vw',
         rewind: true,
         wheel: true,
         arrows: false,
         type: "loop",
         speed: 800,
         pagination: false,
        }}
      >

        <SplideSlide>
          <IntellipoleTemplate />
        </SplideSlide>

        <SplideSlide>
          <InfinityTemplate />
        </SplideSlide>

        <SplideSlide>
          <PodTemplate />
        </SplideSlide>

        <SplideSlide>
          <ACTemplate />
        </SplideSlide>

        <SplideSlide key={5}>
          <UpcommingTemplatesFix explore />
        </SplideSlide>

      </Splide>

   
      <Box className={classes.heading}
        onClick={() => history.push('/evreproduct')} >
        <Box className={classes.mobile}>
          <img className={classes.image} src={"./images/All/mobile.png"} alt="mobile" />
        </Box>
        <p className={classes.chargeWithEvre}>CHARGE WITH EVRE</p>
        <Box className={classes.arrow}>
          <img className={classes.image} src={"./images/Arrow/Arrowleft.png"} alt="arrow" />
        </Box>
      </Box>


      <Box className={classes.barAlign}>
        <h5>
          <h4
            className={clsx(classes.menuActive, state == 0 ? classes.green : '')}
            to="/infinity"
            onClick={() => handleHover(0, "infinity")}
          >
            Infinity
          </h4>
        </h5>
        <h5>
          <h4
            className={clsx(classes.menuActive, state == 1 ? classes.green : '')}
            activeClassName={classes.active}
            to="/halo"
            onClick={() => handleHover(1, "halo")}
          >
            Halo
          </h4>
        </h5>
        <h5>
          <h4
            className={clsx(classes.menuActive, state == 2 ? classes.green : '')}
            activeClassName={classes.active}
            to="/ac"
            onClick={() => handleHover(2, "ac")}

          >
            AC-001
          </h4>
        </h5>
        <h5>
          <h4
            className={clsx(classes.menuActive, state == 3 ? classes.green : '')}
            activeClassName={classes.active}
            to="/intellipole"
            onClick={() => handleHover(2, "intellipole")}

          >
            Intellipole
          </h4>
        </h5>
        <h5>
          <h4
            className={clsx(classes.menuActive, state == 4 ? classes.green : '')}
            activeClassName={classes.active}
            onClick={() => handleHover(2, "upcomming")}
          >
            Upcoming Products
          </h4>
        </h5>
      </Box>
      {/* </Box> */}



    </div>
  );
};
