import React, { useState } from "react";
import { Box, makeStyles } from "@material-ui/core";
import emailjs from "emailjs-com";
import { ViewDetails } from "../../Atom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    minHeight: "80vh",
    margin: "auto",
    marginTop: "5%",
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  flexBox: {
    width: "40%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0 auto",
    },
  },
  flexBoxs: {
    width: "30%",
    display: "flex",
    flexDirection: "column",
    marginLeft: "5%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0 auto",
      marginTop: "5%",
    },
  },
  head: {
    font: "normal normal bolder 6vw Urbanist",
    textAlign: "left",
    background: "#006934",
    background: "-webkit-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "-moz-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    [theme.breakpoints.down("lg")]: {
      fontSize: "5rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
  },
  inputField: {
    width: "100%",
    margin: "8% auto",
    padding: "10px 15px",
    border: "none",
    borderBottom: "1px solid #FFFFFF",
    background: "transparent",
    color: "#FFFFFF",
    fontFamily: "Urbanist",
    outline: "none",
    fontSize:"1.1rem", 
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "8px 10px",
    },
  },
  submitBtn: {
    font: "normal normal 1.4rem Urbanist",
    display: "inline",
    float: "right",
    backgroundColor: "transparent",
    color: "#fff",
    outline: "none",
    border: "none",
    cursor: "pointer",
    position: "relative",
    padding: "5px 0px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "5%",
    },
    "&::before": {
      content: "''",
      position: "absolute",
      bottom: "0",
      left: "50%",
      width: "0%",
      height: "2px",
      background: "#00fb5f",
      transition: "all .4s ease-in-out",
    },
    "&::after": {
      content: "''",
      position: "absolute",
      bottom: "0",
      right: "50%",
      width: "0%",
      height: "2px",
      background: "#00fb5f",
      transition: "all .4s ease-in-out",
    },
    "&:hover": {
      color: "#00fb5f",
      "& p": {
        color: "#00fb5f !important",
      },
      "&::before": {
        width: "50%",
      },
      "&::after": {
        width: "50%",
      },
    },
  },
  arrowAlign: {
    display: "column",
    float: "right",
    marginRight: "5%",
  },
}));

export const ContactFormTemplate = () => {
  const classes = useStyles();

  const [contact, setContact] = useState({
    fullname: "",
    phone: "",
    email: "",
    message: "",
  });

  const { fullname, phone, email, message } = contact;

  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  let currentDate = `${date}/${month}/${year}`;

  let name, value;

  const handleChange = (e) => {
    name = e.target.name;
    value = e.target.value;
    setContact({ ...contact, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    try {
      const response = await fetch(
        "https://v1.nocodeapi.com/kirar_rahul/google_sheets/qRfOKAYURDtvKIMi?tabId=sheet1",
        {
          method: "post",
          headers: myHeaders,
          redirect: "follow",
          body: JSON.stringify([
            [fullname, phone, email, message, currentDate],
          ]),
        }
      );

      emailjs
        .sendForm(
          "contact_us_service_id",
          "contact_us_templateID",
          e.target,
          "3LUxNg65RxvO_Ezb8"
        )
        .then((res) => {
          if (res.status == 200) {
            setContact({
              fullname: "",
              phone: "",
              email: "",
              message: "",
            });
            alert(
              "Form submitted. we'll soon get in touch."
            );
          } else {
            alert("something went wrong");
          }
        })
        .catch((error) => console.log(error));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box className={classes.root}>
      <Box className={classes.flexBox}>
        <h1 className={classes.head}>
          Let's <br /> Keep in touch !
        </h1>
        <br />
        <hr
          style={{
            width: "20%",
            border: "3px solid #FFFFFF",
            marginLeft: "2%",
            borderRadius: "2px",
          }}
        />
      </Box>

      <Box className={classes.flexBoxs}>
        <form onSubmit={handleSubmit}>
          <input
            className={classes.inputField}
            placeholder="Full Name*"
            name="fullname"
            value={fullname}
            onChange={(e) => handleChange(e)}
          />

          <input
            className={classes.inputField}
            placeholder="Phone*"
            name="phone"
            value={phone}
            onChange={(e) => handleChange(e)}
          />

          <input
            className={classes.inputField}
            placeholder="Email*"
            name="email"
            value={email}
            onChange={(e) => handleChange(e)}
          />

          <textarea
            className={classes.inputField}
            placeholder="Message*"
            name="message"
            value={message}
            onChange={(e) => handleChange(e)}
          />

          <button type="submit" className={classes.submitBtn}>
            SUBMIT
          </button>
        </form>

        <Box></Box>
      </Box>
    </Box>
  );
};
