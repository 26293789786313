import React, { useEffect } from "react";
import {
  TopBarMenu,
  Footer,
  ScrollTop,
  TopBarAll,
  SolutionsTabBar,
} from "../../Molecules";
import { SwitchTemplate } from "../../Templates";

export const Switch = () => {
  // useEffect(() => {
  //   window.scroll({ top: 0, behavior: "smooth" });
  // }, []);
  return (
    <div>
      <TopBarMenu />
      <SolutionsTabBar
        l1="Knowledge & Resources"
        l1id="#knowledge"
        l2="News and Events"
        l2id="#news"
        l3="Blogs"
        l3id="#blogs"
        genralNavigation="genralNavigation"
      />
      {/* <ScrollTop/> */}
      <SwitchTemplate l1id="knowledge" l2id="news" l3id="blogs" />
      <Footer />
    </div>
  );
};
