import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import { ProductInfinityPara } from "../../Atom";

const para1 =
  "The EVRE Mobile App is every Charging Business’s roadway to success. The intuitive app allows EV owners registered on EVRE’s network to navigate to their nearest EV Charging Stations.";

const para2 =
  "The app increases your business’ visibility on the charging network, bringing more traction to your charging services.";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: "0 auto",
    marginTop: "10%",
    [theme.breakpoints.down("sm")]: {
      width: "90%"
    }
  },
  head: {
    width: "35%",
    font: "normal normal  3.75rem Urbanist",
    marginLeft: "15%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "8vw",
      margin:"0 auto",
      width: "100%",

    },
    [theme.breakpoints.down("xs")]: {
      font: "normal normal 500 8vw Urbanist",
    },
  },

}));

export const SecondBox = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <h1 className={classes.head}>
        Bringing the <span style={{ color: "#00BF5F " }}>Charge Demand </span> at
        your <span style={{ color: "#00BF5F " }}> Business’ </span> doorway!
      </h1>
      <ProductInfinityPara para1={para1} para2={para2} />
    </Box>
  );
};
