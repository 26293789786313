import React from 'react'
import { Box, makeStyles } from '@material-ui/core'


const useStyles = makeStyles((theme) => ({

    evreImage: {
        width: "100%",
        display: "flex",
        marginTop: "5%",
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            display: "flex",
            flexDirection: "column",
        },
    },
    evreone: {
        textAlign: "right",
    },
    evretwo: {
        marginTop: "20%",
    },
    imageEvreOne: {
        width: "90%",
        height: "60%",
        [theme.breakpoints.down("sm")]: {
            width: "80%",
            marginTop: "5%",
        },
    },
    imageEvreTwo: {
        width: "80%",
        height: "90%",
        // [theme.breakpoints.down("sm")]: {
        //     width: "100%",
        //     marginTop: "5%",
        // },
    },

}));

export const TwoImg = () => {
    const classes = useStyles();

    return (
        <Box className={classes.evreImage}>
            <Box className={classes.evretwo}>
                <img
                    src={"./images/evre/evretwo.png"}
                    alt="evretwo"
                    className={classes.imageEvreTwo}
                />
            </Box>
            <Box className={classes.evreone}>
                <img
                    src={"./images/evre/evreone.png"}
                    alt="evreone"
                    className={classes.imageEvreOne}
                />
            </Box>
        </Box>
    )
}
