import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "60%",
    margin: "0 auto",
    marginTop: "20%",
    marginBottom: "10%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginTop: "10%"
    },
  },
  flexBox: {
    marginTop: "5%",
  },
  headText: {
    width: "70%",
    font: "normal normal normal 3vw Urbanist",
    [theme.breakpoints.down("sm")]: {
      fontSize: "7vw",
      width: "100%",
    },
  },
  paraText: {
    width: "50%",
    font: "normal normal 200 1.8vw Urbanist",
    lineHeight: "1.5",
    float: "right",
    marginTop: "5%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "4.5vw",
      width: "100%",
      marginTop: "5%",
    },
  },
}));

export const TwoPara = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.flexBox}>
        <Typography className={classes.headText}>
          Building India's most advanced EV Charging Network to drive the EV
          Revolution with full throttle
        </Typography>

        <Typography className={classes.paraText}>
          The world will witness millions of EVs in the coming years and we are
          all set to electrify them! We are currently present across major parts
          of India and around the world and plan to establish 10,000 more EV
          Chargers by 2023.
        </Typography>
      </Box>
    </Box>
  );
};
