import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "60%",
    margin: "0 auto",
    marginTop: "10%",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      marginTop: "20%",
    },
  },
  joinHead: {
    font: "normal normal bolder 10rem Urbanist",
    background: "#006934",
    lineHeight: "1.5",
    background: "-webkit-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "-moz-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    [theme.breakpoints.down("md")]: {
      fontSize: "10rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "5rem",
    },
  },
  joinpara: {
    font: "normal normal lighter 1.5rem Urbanist",
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      font: "normal normal lighter 1rem Urbanist",
    },
  },
  imageWidt: {
    width: "80%",
    textAlign: "center",
    marginTop: "5%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "10%",
    },
  },
  text: {
    font: "normal normal lighter 3rem Urbanist",
    marginTop: "5%",
    [theme.breakpoints.down("sm")]: {
      font: "normal normal lighter 2rem Urbanist",
      marginTop: "20%",
    },
  },
  colorText: {
    font: "normal normal lighter 3rem Urbanist",
    color: "#00BF5F ",
    marginTop: 10,
    [theme.breakpoints.down("sm")]: {
      font: "normal normal lighter 2rem Urbanist",
    },
  },
}));

export const JoinUs = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography className={classes.joinHead}>Join us</Typography>
      <Typography className={classes.joinpara}>
        We are looking for team members who are as humorous as they are witty
        and tech-savvy!
      </Typography>
      <Box className={classes.imageWidt}>
        <img src={"./images/story/joinus.jpg"} className={classes.imageWidt} />
      </Box>
      <Typography className={classes.text}>
        To join our team, write to us at
      </Typography>
      <Typography className={classes.colorText}>careers@evre.in</Typography>
    </Box>
  );
};
