import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import {
  Footer,
  ScrollTop,
  TopBarAll,
  TopNavbar,
  SolutionsTabBar,
} from "../../Molecules";
import { FranchiseTemplates } from "../../Templates";

export const Franchise = () => {
  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <Box>

      <SolutionsTabBar
        // l1="Franchise"
        // l1id="#franchise"
        l1="Why EVRE"
        l1id="#whyevre"
        l2="ENQUIRY"
        l2id="#enquiry"
      />

      <FranchiseTemplates
        l1id="franchise"
        l2id="whyevre"
        l3id="enquiry" />

      <Footer />

    </Box>
  );
};
