import React from "react";
//Material ui
import { Box, makeStyles } from "@material-ui/core";

import Fade from "react-reveal/Fade";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "80%",
    color: "#ffffff",
    // display: "flex",
    // justifyContent: "space-between",
    // alignItems: "center",
    // flexDirection: "column",
    position: "relative",
    // marginTop: 10,
    // paddingTop: 10,
    marginTop: "10%",
    marginBottom: "15%",
    [theme.breakpoints.down("sm")]: {
      height: "90vh",
      fontSize: "5rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
  },
  text1: {
    font: "normal normal bolder 15vw Urbanist",
    textAlign: "center",
    background: "#006934",
    background: "-webkit-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "-moz-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    marginTop: "5%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "4rem",
    },
    // [theme.breakpoints.down("sm")]: {
    //     display: "none",
    // },
  },
  ImageTextBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    // position: 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50% )'
  },
  image: {
    width: "15vw",
    // position: "absolute",
    // left: "38%",
    // top: "30%",
    // transform: "translate(-50%,-50%)",
    marginTop: "-2%",
    [theme.breakpoints.down("sm")]: {
      width: "8rem",
      top: "55%",
      left: "35%",
    },
  },
  Text: {
    font: "normal normal normal 1vw Urbanist",
  },
}));
export const ProductIntroIntellipole = ({ title, Image }) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Fade bottom>
        <h1 variant="h1" className={classes.text1}>
          {title}
        </h1>
      </Fade>

      <Box className={classes.ImageTextBox}>
        <img className={classes.image} src={Image} alt="Infinity_Logo" />
        {/* <br/>
                <img src={'./images/Rotation/rotation.png'} alt="image_Rotation" />
                <br/>
               <h1 className={classes.Text}>180&#xb0;View</h1>*/}
      </Box>
    </Box>
  );
};
