import React from "react";
import { makeStyles, Typography, Container, Box } from "@material-ui/core";
import { useHistory } from "react-router";
//Fade
import Fade from "react-reveal/Fade";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "45vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    position: "relative",
    color: "#ffffff",
    marginBottom: "2%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "75vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      position: "relative",
      color: "#ffffff",
    },
  },
  image: {
    width: "95%",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  para: {
    width: "60%",
    marginTop: "18%",
    marginLeft: "5%",
    font: "normal  normal lighter 1rem Urbanist",
    lineHeight: "1.8",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      marginTop: "18%",
      font: "normal  normal lighter 0.7rem Urbanist",
    },
  },
  text1: {
    font: "normal normal bolder 15rem Urbanist",
    textAlign: "center",
   background: "#006934",
background: "-webkit-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
background: "-moz-linear-gradient(to top, #006934 0%,#00BF5F 100%)",
background: "linear-gradient(to top, #006934 0%,#00BF5F 100%)",

    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    [theme.breakpoints.down("md")]: {
      fontSize: "10rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "5rem",
    },
  },
  arrowAlign: {
    marginLeft: 20,
    marginBottom: 20,
    width: "60",

    [theme.breakpoints.down("sm")]: {
      width: "30%",
    },
  },
  imageBox: {
    display: "flex",
    position: "absolute",
    width: "60%",
    left: "25%",
    top: "50%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      position: "absolute",
      width: "80%",
      left: "20%",
      top: "50%",
    },
  },
  explore: {
    marginTop: 10,
    marginLeft: "5%",
    color: "#00BF5F ",
    [theme.breakpoints.down("sm")]: {
      font: "normal normal 0.7rem Urbanist",
    },
  },
  rightArrow: {
    display: "inline-block",
  cursor: "pointer",
    marginLeft: "5%",
    // transition: "transform ease 300ms",
    // "&:hover": {
    //   transform: "translate(40px, 0)",
    // },
  },
}));

export const AllProductsExplore = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Container className={classes.root}>
      <Fade bottom>
        <Typography className={classes.text1}>INFINITY</Typography>
      </Fade>
      <Box className={classes.imageBox}>
        <Box>
          <Fade bottom>
            <img
              className={classes.image}
              src={"./images/Infinity/left.png"}
              alt="Infinity_Logo"
            />
          </Fade>
        </Box>
        <Box>
          <Fade bottom>
            <Typography className={classes.para}>
              A premium 4-wheeler EV Charging Station with superior design,
              class-apart performance, and centennial durability.
            </Typography>
          </Fade>
          <Box
            className={classes.rightArrow}
            onClick={() => history.push("/infinityproduct")}
          >
            <Fade left>
              <Typography
                className={classes.explore}
                onClick={() => history.push("/infinityproduct")}
              >
                Explore
              </Typography>
              <img
                src={"./images/Arrow/arrowgreen.png"}
                alt="Arrow"
                width="60"
                className={classes.arrowAlign}
                onClick={() => history.push("/infinityproduct")}
              />
            </Fade>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
