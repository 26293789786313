export const team = [
  {
    name: 'Krishna Jasti',
    image: './images/Team_Images/Krishna_Jasti.jpg',
    designation: 'CEO & Co-Founder',
  },
  {
    name: 'Chandresh Sethia',
    image: './images/Team_Images/Chandresh_Sethia.jpg',
    designation: 'CBO & Co-Founder',
  },
  {
    name: 'Rajashree Jasti',
    image: './images/Team_Images/Rajashree_Jasti.jpg',
    designation: 'Head Finance & Accounts',
  },
  {
    name: 'Deepika Ostwal',
    image: './images/Team_Images/Deepika_Ostwal.JPG',
    designation: 'VP Fleet Infra',
  },
  {
    name: 'Jeewak Jadhav',
    image: './images/Team_Images/Jeewak_Jadhav.jpg',
    designation: 'VP Residential & Commercial Infra',
  },
  // {
  //   name: "Sumit Banerji",
  //   image: "./images/Team_Images/Sumit_Banerji.jpg",
  //   designation: "VP Strategic Alliances",
  // },
  // {
  //     name:'Syed Yusuf Hassan',
  //     image:'',
  //     designation:'COO',
  // },
];
