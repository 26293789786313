import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { ViewDetails } from "../../Atom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "60%",
    margin: "10% auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  headText: {
    font: "normal normal bolder 4vw Urbanist",
    [theme.breakpoints.down("sm")]: {
      color: "#ffffff",
      fontFamily: "Urbanist",
      fontSize: "2rem",
      marginTop: "5%",
      textAlign: "center",
    },
  },
  about: {
    marginTop: "2%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      marginTop: "5%",
    },
  },
  para: {
    font: "normal normal 200 1.8vw Urbanist",
    width: "70%",
    lineHeight: "1.5",
    marginTop: "3%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: "5vw",
    },
  },
  aboutEvre: {
    width: "20%",
    display: "flex",
    alignItems: "end",
    justifyContent: "right",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "center",
      marginTop: "15%",
    },
  },
  aboutImg: {
    font: "normal normal 200 2vw Urbanist",
    [theme.breakpoints.down("sm")]: {
      fontSize: "8vw",
    },
  },
}));

export const AboutEVRE = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography className={classes.headText}>We are EVRE</Typography>

      <Box className={classes.about}>
        <Box className={classes.para}>
          We are one of India’s leading EV Charger OEMs and Charging Infra
          developers. The EV Revolution brings with it opportunities to build a
          sustainable future. We aim to be a key influencer in leading this shift
          and pave a way for a greener future one vehicle, one neighborhood, and
          one city at a time.
        </Box>

        <Box className={classes.aboutEvre}>
          <Box>
            {/* <Typography className={classes.aboutImg}>About EVRE</Typography> */}
            <ViewDetails
              title="About EVRE"
              color="#fff"
              route="aboutevre"
              curves="curves"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
