import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "70%",
    margin: "0 auto",
    marginTop: "10%",
    [theme.breakpoints.down('sm')]: {
      marginTop: "20%",
    width: "90%",

    }
  },
  headingText: {
    font: "normal normal 5rem Urbanist",
    width: "80%",
    [theme.breakpoints.down('sm')]: {
      fontSize: '8vw'
    }
  },
  mainBox: {
    display: "flex",
    justifyContent:"space-between",
    marginTop: "5%",
    [theme.breakpoints.down('sm')]: {
      flexDirection:"column-reverse",
    }
  },
  leftBox:{
    width:"60%",
    [theme.breakpoints.down('sm')]: {
      width:"100%",

    }
  },
  rightBox: {
    textAlign: "right",
    width:"30%",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      textAlign: "center"
    }
  },
  image: {
    width: "100%",
    maxHeight:"100%"
  },
  imprintText: {
    font: "normal normal 600 1.5vw Urbanist",
    color: "#00BF5F ",
    marginTop: "5%",
    [theme.breakpoints.down('sm')]: {
      fontSize:"6vw"
    }
  },
  paraText: {
    font: "normal normal lighter 1.5rem Urbanist",
    marginTop: "3%",
    width: "80%",
    lineHeight: "1.5",
    [theme.breakpoints.down('sm')]: {
      fontSize:"6vw"
    }
  },
  numberBoxes: {
    width:"100%",
    display: "flex",
    flexDirection: "row",
    marginTop: "10%",
    justifyContent: "space-between",
  },
  numberText: {
    font: "normal normal 6.5vw Urbanist",
    color: "#00BF5F ",
    [theme.breakpoints.down('sm')]: {
      fontSize: '4vw'
    }
  },
  text: {
    font: "normal normal 1.8rem Urbanist",
    width: '70%',
    [theme.breakpoints.down('sm')]: {
      fontSize: '4vw'
    }
  },
}));

export const EighthBox = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography className={classes.headingText}>
        Join us as <span style={{ color: "#00BF5F " }}> we carve</span> India’s
        EV Landscape.
      </Typography>
      <Box className={classes.mainBox}>
        <Box className={classes.leftBox}>
          <Typography className={classes.imprintText}>
            EVRE’S IMPRINT
          </Typography>
          <Typography className={classes.paraText}>
            The journey towards a 100% EV compliant India still has miles to go
            but we are ensuring to cover every nook and corner to skyrocket this
            transition.
          </Typography>
          <Box className={classes.numberBoxes}>
            <Box>
              <Typography className={classes.numberText}>12</Typography>
              <Typography className={classes.text}>Cities Covered</Typography>
            </Box>
            <Box style={{ paddingLeft: '8%' }}>
              <Typography className={classes.numberText}>550+</Typography>
              <Typography className={classes.text}>
                EV Chargers Commissioned
              </Typography>
            </Box>
            <Box style={{ paddingLeft: '8%' }}>
              <Typography className={classes.numberText}>10,000</Typography>
              <Typography className={classes.text}>
                Installations Planned by 2023
              </Typography>
            </Box>
          </Box>
        </Box> 

        
        <Box className={classes.rightBox}>
          <img
            src={"./images/AboutEvre/aboutevre-1.png"}
            alt="car"
            className={classes.image}
          />
        </Box>
       
      </Box>
    </Box>
  );
};
