import React, { useEffect } from "react";
//Material ui
import { Box, makeStyles } from "@material-ui/core";
import { Link } from "react-router-dom";
//Aos
import { ViewDetails } from "../ViewDetails";
import AOS from "aos";
import "aos/dist/aos.css";

//Style
const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    margin: "0 auto",
    paddingTop: "10%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  image: {
    width: "100%",
    height: "auto",
    objectFit: "contain",
    margin: "0% auto",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "20%",
      height: "250px",
    },
  },
  flexBox: {
    width: "50%",
    float: "right",
    marginBottom: "5%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      float: "none",
    },
  },
  head: {
    font: "normal normal 600 2.5vw Urbanist",
    color: "#00BF5F ",
    [theme.breakpoints.down("sm")]: {
      fontSize: "8vw",
      textAlign: "center",
    },
  },
  para: {
    lineHeight: "1.8",
    font: "normal normal 200 1.5vw Urbanist",
    margin: "5% 0%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin: "5% auto",
      fontSize: "6vw",
    },
  },
}));

export const ImageRightCenter = ({
  title,
  image,
  para,
  linkText,
  linkTarget,
}) => {
  const classes = useStyles();

  const animation = () => {
    AOS.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  };

  useEffect(() => {
    animation();
  }, []);
  return (
    <Box className={classes.root}>
      <Box className={classes.flexBox}>
        <h1 className={classes.head} data-aos="fade-up">
          {title}
        </h1>
        <h1 className={classes.para} data-aos="fade-up">
          {para}
        </h1>
        {linkText ? <ViewDetails route={linkTarget} title="Read More" /> : ""}
      </Box>
      <img
        className={classes.image}
        src={image}
        alt="imageCenter"
        data-aos="fade-up"
      />
    </Box>
  );
};
