import React, { useEffect } from "react";
import { makeStyles, Typography, Box } from "@material-ui/core";
import { useHistory } from "react-router";
import Fade from "react-reveal/Fade";
import clsx from 'clsx'
import logo from '../../Assets/pic.png'

//Aos
import AOS from "aos";
import "aos/dist/aos.css";
import { CursorAnimated, CursorCustom, ViewDetails } from "../../Atom";
import { Link, NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    margin: "0 auto",
    display: "flex",
    justifyContent: "space-between",
    marginTop: "12%",
    cursor: "none",
    // ['@media (max-width:1366px)']: {
    //   marginTop: "8%",
    // },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      marginTop: "20%",
      marginBottom: "20%",
      width: "100%",
    }
  },
  cursor: {
    position: 'absolute',
    transform: 'translate(-50%,-50%)',
    height: '35px',
    width: '35px',
    borderRadius: '50%',
    border: '2px solid black',
  },
  rightImage: {
    width: "48%",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      textAlign: "center",
      marginTop: "20%",
    },
    // "& $arrowLeft": {
    //   display: "none",
    // },
    "&:hover": {
      "& $arrowLeft": {
        // display: "block",
      },

    },
  },
  leftImage: {
    height: "100%",
    width: "48%",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      textAlign: "center",
    },
    // "& $arrowLeft": {
    //   opacity: '0',
    //   display: "none",
    //   transition: 'all .3s ease-in-out'
    // },
    "&:hover": {
      "& $arrowLeft": {
        opacity: '1'
      },
      "& $textHeading": {
        color: "#00BF5F"
      },
      "& $productImage": {
        transform: 'translateY(-20px)',
      },
    },
  },
  productImage: {
    width: "45%",
    height: "100%",
    cursor: "none",
    transition: 'all .5s ease-in-out',
    [theme.breakpoints.down("xs")]: {
      width: '60%',
      marginTop: "2%"
    }
  },
  chargeImage: {
    width: "80%",
    height: "100%",
    marginTop: "5%",
    cursor: 'none',
    transition: 'all .5s ease-in-out',
    [theme.breakpoints.down("xs")]: {
      width: '80%',
      marginTop: "2%"
    }
  },
  textHeading: {
    font: "normal normal 2.5rem Urbanist",
    color: "#ffffff",
    textAlign: "center",
    transition: "color .5s ease-in-out",
    [theme.breakpoints.down("sm")]: {
      color: "#ffffff",
      textAlign: "center",
      fontFamily: "Urbanist",
      fontSize: "1.8rem",
    },
  },
  arrowLeft: {
    marginTop: "2%",
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      marginTop: "0%",
    }
  },
  explore: {
    color: '#00BF5F',
    cursor: 'pointer',
    fontFamily: 'Gilroy',
    fontSize: '1.2rem',
    transition: 'all .3s ease-in-out',
    "&:hover": {
      color: '#fff'
    }
  },
  arrowAlign: {
    marginLeft: "5%",
  },
  leftAnimBox: {
    "&:hover": {
      "& $textHeading": {
        color: "#00BF5F"
      },
      "& $productImage": {
        transform: 'translateY(-20px)',
      },
    }
  },
  rightAnimBox: {
    "&:hover": {
      "& $textHeading": {
        color: "#00BF5F"
      },
      "& $chargeImage": {
        transform: 'translateY(-20px)',
      },
    }
  },
}));

export const HomeTemplate = ({ style }) => {
  const classes = useStyles();
  const history = useHistory();

  const animation = () => {
    AOS.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  };

  useEffect(() => {
    animation();
  }, []);
  return (
    <div className={classes.root} style={style}>
      <Box
        className={clsx(classes.leftImage)}
      >
        <Box className={classes.alignmentLeft} data-aos="fade-up">
          <Box className={classes.leftAnimBox}>
            <NavLink to="/infinity">
              <img
                className={classes.productImage}
                onClick={() => history.push("/infinity")}
                src={"./images/Home/left.png"}
                alt="leftPng"
              />
            </NavLink>
            <Typography className={classes.textHeading} >Products</Typography>
          </Box>
          <Box className={classes.arrowLeft}>
            <ViewDetails
              title="Explore"
              route_curr="infinity"
              style={{ justifyContent: "center", }}
            />
            {/* <Typography className={classes.explore}>Explore</Typography> */}
            {/* <img
                className={classes.arrowAlign}
                src={"./images/Arrow/Arrow.svg"}
                alt="Arrow"
                width="70"
              /> */}
          </Box>
        </Box>
      </Box>

      <Box
        className={classes.rightImage}
      >
        <Box className={classes.alignmentRight} data-aos="fade-up">
          <Box className={classes.rightAnimBox}>
            <Link to="/evreproduct">
              <img
                onClick={() => history.push("/evreproduct")}
                className={classes.chargeImage}
                src={"./images/Home/right.png"}
                alt="rightpng"
              />
            </Link>
            <Typography className={classes.textHeading} >
              Charge with EVRE
            </Typography>
          </Box>
          <Box className={classes.arrowLeft}>
            <ViewDetails
              title="Explore"
              route_curr="evreproduct"
              style={{ justifyContent: "center", }}
            />
            {/* <Typography className={classes.explore}>Explore</Typography>
              <img
                className={classes.arrowAlign}
                src={"./images/Arrow/Arrow.svg"}
                alt="Arrow"
                width="70"
              /> */}
          </Box>
        </Box>
      </Box>
    </div>
  );
};
