import React from "react";
import { Box, Grid, makeStyles } from "@material-ui/core";
import Fade from "react-reveal/Fade";

const useStyles = makeStyles((theme) => ({
  GridContainer: {
    width: "80%",
    marginLeft: "20%",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      marginLeft: "10%",
    },
  },
  boxWrapper: {
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
  },
  HeadTitle: {
    font: 'normal normal 600 34px/50px Urbanist',
    color: "#00BF5F ",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  subtitlePara: {
    font: 'normal normal 600 24px/40px Urbanist',
    color: "white",
    lineHeight: "1.8",
    marginTop: "2%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  subtitlesPara: {
    font: 'normal normal 300 24px/40px Urbanist',
    color: "white",
    lineHeight: "1.8",
    marginTop: "2%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
}));

export const ContactGridOne = ({ ...mapData }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.GridContainer}>
      {
        mapData.mapData.map((item, index) => {
          const { title, subtitle, subtitles } = item;
          return (
            <Grid item xs={12} sm={6} key={index} style={{ marginTop: "5%" }}>

              <Box className={classes.boxWrapper}>

                <h1 className={classes.HeadTitle} data-aos="fade-up"> {title}</h1>

                <p className={classes.subtitlePara} data-aos="fade-up">{subtitle}</p>

                <p className={classes.subtitlesPara} data-aos="fade-up">{subtitles}</p>

              </Box>

            </Grid>
          );
        })
      }
    </Grid>
  );
};
