export const PoleData = [
  {
    title: "Up to 22 kW Charging Provision",
    subtitle:
      "Charge up to 3 EVs in one go, with a flexibility of charging options.",
  },
  {
    title: "Customizable Charging Load",
    subtitle:
      "Customize Intellipole’s charging outlets’ energy output as per your charging needs.",
  },
  {
    title: "Compatible with all 2, 3, & 4 Wheeler EVs",
    subtitle:
      "A one-stop charging solution for all types of electric 2, 3, & 4 Wheelers.",
  },
  {
    title: "Solar-integrated Street Light",
    subtitle: "Redefining sustainability and usability goals.",
  },
  // {
  //   title: "Patented Computer Vision Technology",
  //   subtitle:
  //     "With EVRE, enable your users to reserve their charging slot in advance using our patented number plate recognition technology.",
  // },
  {
    title: "Real-time Telemetry Data",
    subtitle:
      "Access real-time charging data like unit consumption, charging sessions, payments etc. on your EVRE dashboard or integrate with your existing management systems.",
  },
  {
    title: "Integrated with Mobile App",
    subtitle:
      "Access our ready-to-go customer app or integrate with your business app to deliver customer experience that cuts above the rest.",
  },
  {
    title: "Energy Metering",
    subtitle:
      "With Energy Metering, transparently track electricity unit consumption by EVs and earned revenue.",
  },
  {
    title: "Electrically Protected",
    subtitle:
      "State-of-the-art electrical protections, leaving little to chance.",
  },
  {
    title: "RFID/QR Code Based User Authentication",
    subtitle:
      "Gain utmost control of who uses your EV Chargers through custom RFID tags and QR Codes.",
  },
  {
    title: "Integrated Billing and Payments",
    subtitle:
      "With completely unmanned operations, manage your EV Charging income with integrated online billing and payments.",
  },
  {
    title: "Emergency Button",
    subtitle: "All the alarms and alerts you need in case of a fire breakout.",
  },
  {
    title: "OCPP 1.6 Compatibility",
    subtitle: "OCPP 1.6 compliant charging station that can push data to your CMS with little integration effort.",
  },
  {
    title: "Yours to Configure",
    subtitle: "Remotely configure your most important parameters such as pricing, cut-off thresholds, etc.",
  },
  {
    title: "Monitor Air Quality",
    subtitle: "Real-time air quality data including PM2.5, PM10, and CO2.",
  },
  {
    title: "Digital Advertising",
    subtitle: "Scale your income by providing digital advertising along with EV Charging as a Service.",
  },
  {
    title: "CCTV Provision",
    subtitle: "Boost the use case of your Intellipole by integrating CCTV Camera for increased security.",
  },
];

export const IntellipoleSecondData = [
  {
    title: "Input Rating",
    subtitle: "415 VAC (± 20%) Three Phase, 16 A/32 A per Phase",
  },
  {
    title: "Connection & Wiring",
    subtitle: "L1, L2, L3, N, G",
  },
  {
    title: "Standby Power",
    subtitle: "< 40 W",
  },
];

export const IntellipoleSecondData1 = [
  {
    title: "Output Rating",
    subtitle: "230 VAC (± 20%), 16A/32A per Phase",
  },
  {
    title: "Output Power",
    subtitle:
      "3.3 kW X 3 OR 3.3 kW X 2 & 7.4 kW X 1 OR 3.3 kW X 1 & 7.4 kW X 2",
  },
  {
    title: "Metering",
    subtitle: "Real-time three-phase energy metering",
  },
  {
    title: "Cold Load Pick-Up",
    subtitle: "Delay before charge resumes after power failure",
  },
];

export const IntellipoleSecondData2 = [
  {
    title: "Upstream Breaker",
    subtitle:
      "2-pole 20 A/4-pole 40 A breaker on dedicated circuit, non-GFCI type",
  },
  {
    title: "Plug-Out Protection",
    subtitle: "Output terminated upon charging connector plug-out",
  },
  {
    title: "Ground Fault",
    subtitle: "30 mA",
  },
  {
    title: "Surge Protection",
    subtitle: "Class-II, 4-pole up to 1.5 kV",
  },
  {
    title: "Electrical Protections",
    subtitle:
      "Over current, Over voltage, Under voltage, Ground fault, Surge protection",
  },
];

export const IntellipoleSecondData3 = [
  {
    title: "Buttons",
    subtitle: "Emergency Stop",
  },
  {
    title: "User Authentication",
    subtitle: "QR Code, Mobile App, RFID, Remote Start",
  },
  {
    title: "Status Indication",
    subtitle: "Multi-colour LED-Power, Charging, Diagnostic",
  },
];

export const IntellipoleSecondData4 = [
  {
    title: "EVSE to Vehicle ",
    subtitle: "Pilot Line Communication (Type-2/J1772)",
  },
  {
    title: "EVSE to Server",
    subtitle: "OCPP 1.6",
  },
  {
    title: "Cloud Connectivity",
    subtitle: "Wi-Fi, Ethernet, GSM",
  },
];

export const IntellipoleSecondData5 = [
  {
    title: "Charging Interface",
    subtitle: "IEC 60309 Socket/IEC 62196 (Type 2) - Wire",
  },
  {
    title: "Number of Outputs",
    subtitle: "3",
  },
];

export const IntellipoleSecondData6 = [
  {
    title: "Standard",
    subtitle: "PM2.5, PM10, Temperature, Humidity",
  },
  {
    title: "Optional",
    subtitle:
      "eCO2, CO, TVOC, SO2, O3, NH3, H2S, Light Intensity, UV Radiation, Wind Speed, Wind Direction, Rainfall, Noise",
  },
];

export const IntellipoleSecondData7 = [
  {
    title: "Solar Panel",
    subtitle: "100 W",
  },
  {
    title: "LED",
    subtitle: "20 W",
  },
  {
    title: "Internal Battery",
    subtitle: "320 Wh",
  },
];

export const IntellipoleSecondData8 = [
  {
    title: "User Interface",
    subtitle: "7-inch display",
  },
  {
    title: "Alarm",
    subtitle: "Speaker",
  },
];

export const IntellipoleSecondData9 = [
  {
    title: "Drone Docking Station",
  },
];
