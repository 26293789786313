import React, { useRef } from "react";
import { Box, makeStyles } from "@material-ui/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './styled.css'

const useStyles = makeStyles((theme) => ({
  headText: {
    font: "normal normal bolder 4.5rem Urbanist",
    color: "#00BF5F ",
    width: "100%",
    paddingLeft: '10%',
    marginBottom: '10%',
    [theme.breakpoints.down("sm")]: {
      paddingLeft: '0%',
      textAlign: "center"
    }
  },
  arrow: {
    width: '100%',
    // marginBottom: '2%',
    display: 'inline-block'
  },
  arrowFloat: {
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center",
    float: 'right',
    [theme.breakpoints.down('sm')]: {
      float: 'none',
      width: '100%',
    }
  },
  arrowIcon: {
    margin: '0 5%',
    background: 'none',
    border: 'none',
    outline: 'none',
    opacity: '.5',
    cursor: 'pointer',
    '&:hover': {
      opacity: '1'
    }
  },
  boxone: {
    
  },
  boxtwo: {
    border: "2px solid green",
    display: "flex",
    flexDirection: "row",
  },
  image: {
    width: "80%",
  },
  flexboxone: {
    width: "20%",
    marginTop: '10%'
  },
  flexboxtwo: {
    width: "70%",
  },
  number: {
    fontSize: "5rem",
    fontFamily: "Urbanist",
    fontWeight: '700',
    opacity: '0.5',
  },
  slider: {
    width: '95%',
    margin: '0 auto',
    // height: '75vh',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    }
  },
  card: {
    overflow: 'hidden',
    width: '20rem',
    // height: '80vh',
    padding: '10%',
    [theme.breakpoints.down('xs')]: {
      // width: '80%'
    }
  },
  imageBox: {
    display: 'flex',
    // paddingLeft: '2.5rem',
  },
  cardId: {
    font: 'normal normal bold 4vw Urbanist',
    opacity: '0.3',
    marginRight: '5rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10vw'
    }
  },
  // cardImage: {
  //   width: '75%',
  //   [theme.breakpoints.down('sm')]: {
  //     width: '65%'
  //   }
  // },
  cardSub: {
    width: '100%',
    // paddingLeft: '2rem',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '0rem',
    }
  },
  subHead: {
    font: 'normal normal bold 2vw Urbanist',
    marginTop: '5%',
    [theme.breakpoints.down('sm')]: {
      fontSize: "4vw",
    }
  },
  subPara: {
    font: 'normal normal 300 1.6vw Urbanist',
    marginTop: '5%',
    lineHeight: '1.8',
    [theme.breakpoints.down('sm')]: {
      fontSize: "4vw",
      lineHeight: '1.5'
    }
  }

}));

export const WhyEvreCoursal = ({ CarousalData }) => {
  const classes = useStyles();

  const sliderRef = useRef();

  const next = () => {
    sliderRef.current.slickNext();
  };
  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const settings = {
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Box className={classes.root}>
      <h2 className={classes.headText}>Why EVRE</h2>

      <Box className={classes.arrow}>
        <Box className={classes.arrowFloat}>
          <button className={classes.arrowIcon} style={{ transform: 'rotate(180deg)' }} onClick={previous}><img src={'./images/Arrow/Arrow.png'} alt="arrow" /></button>
          <button className={classes.arrowIcon} onClick={next}><img src={'./images/Arrow/Arrow.png'} alt="arrow" /></button>
        </Box>
      </Box>

      <Slider ref={sliderRef} {...settings} className={classes.slider}>

        {
          CarousalData.map((item, index) => {
            const { title, paragraph } = item
            return (
              <Box className={classes.card} key={index}>
                <Box className={classes.cardSub}>
                  <h1 className={classes.subHead}>{title}</h1>
                  <h1 className={classes.subPara}>{paragraph}</h1>
                </Box>
              </Box>
            )
          })
        }

      </Slider>


    </Box>
  );
};
