import React from "react";
import Slider from "react-slick";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "5%",
    [theme.breakpoints.down("sm")]: {
      padding: "5%",
    },
  },
  dateText: {
    fontSize: "1rem",
    fontFamily: "Urbanist",
    fontWeight: "100",
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      fontFamily: "Urbanist",
      fontWeight: "100",
      marginTop: 10,
    },
  },
  image: {
    width: "20%",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  paratext: {
    fontSize: "1.2rem",
    fontFamily: "Urbanist",
    marginTop: 10,
    width: "20%",
    marginLeft: 10,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
      fontFamily: "Urbanist",
      marginTop: "5%",
      width: "80%",
      marginLeft: 10,
    },
  },
}));

export const ImagePara = () => {
  const classes = useStyles();

  const Settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  return (
    <div className={classes.root}>
      <Slider {...Settings}>
        <div className={classes.flexBox}>
          <img
            src={"./images/coursal/test.png"}
            alt="test"
            className={classes.image}
          />
          <p className={classes.dateText}>Aug 12, 2021 01:00 PM</p>
          <p className={classes.paratext}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor
          </p>
        </div>
      </Slider>
    </div>
  );
};
