import {
  Intro,
  Home,
  Infinity,
  InfinityFix,
  Pod,
  AC,
  Intellipole,
  InfinityProductPage,
  PodProductPage,
  AC001ProductPage,
  IntellipoleProductPage,
  Evreproduct,
  Contact,
  BecomeADealer,
  Solution,
  Residential,
  Commercial,
  Business,
  Goverment,
  Upcomming,
  Franchise,
  Switch,
  AboutUs,
  AboutEvre,
  Solutions,
  LogisticsScreen,
  PrivacyScreen,
} from "../Components/Pages";
import { NavBarButton } from "../Components/Atom";

export const Routes = [
  {
    path: "/menu",
    title: "/menu",
    exact: true,
    component: () => <NavBarButton />,
  },
  {
    path: "/",
    title: "/intro",
    exact: true,
    component: () => <Intro />,
  },
  {
    path: "/home",
    title: "/home",
    exact: true,
    component: () => <Home />,
  },
  {
    path: "/Infinity",
    title: "/Infinity",
    exact: true,
    // component: () => <Infinity />,
    component: () => <InfinityFix />,
  },
  {
    path: "/halo",
    title: "/halo",
    exact: true,
    component: () => <Pod />,
  },

  {
    path: "/ac",
    title: "/ac",
    exact: true,
    component: () => <AC />,
  },

  {
    path: "/intellipole",
    title: "/intellipole",
    exact: true,
    component: () => <Intellipole />,
  },
  {
    path: "/infinityproduct",
    title: "/infinityproduct",
    exact: true,
    component: () => <InfinityProductPage />,
  },
  {
    path: "/podproduct",
    title: "/podproduct",
    exact: true,
    component: () => <PodProductPage />,
  },
  {
    path: "/ac001product",
    title: "/ac001product",
    exact: true,
    component: () => <AC001ProductPage />,
  },
  {
    path: "/intellipoleproduct",
    title: "/intellipoleproduct",
    exact: true,
    component: () => <IntellipoleProductPage />,
  },
  {
    path: "/contact",
    title: "/contact",
    exact: true,
    component: () => <Contact />,
  },
  {
    path: "/evreproduct",
    title: "/evreproduct",
    exact: true,
    component: () => <Evreproduct />,
  },
  {
    path: "/becomeadealer",
    title: "/becomeadealer",
    exact: true,
    component: () => <BecomeADealer />,
  },
  {
    path: "/solution",
    title: "/solution",
    exact: true,
    component: () => <Solution />,
  },
  {
    path: "/residential",
    title: "/residential",
    exact: true,
    component: () => <Residential />,
  },
  {
    path: "/commercial",
    title: "/commercial",
    exact: true,
    component: () => <Commercial />,
  },
  {
    path: "/business",
    title: "/business",
    exact: true,
    component: () => <Business />,
  },
  {
    path: "/government",
    title: "/government",
    exact: true,
    component: () => <Goverment />,
  },
  {
    path: "/upcomming",
    title: "/upcomming",
    exact: true,
    component: () => <Upcomming />,
  },
  {
    path: "/franchise",
    title: "/franchise",
    exact: true,
    component: () => <Franchise />,
  },
  {
    path: "/switch",
    title: "/switch",
    exact: true,
    component: () => <Switch />,
  },
  {
    path: "/aboutus",
    title: "/aboutus",
    exact: true,
    component: () => <AboutUs />,
  },
  {
    path: "/aboutevre",
    title: "/aboutevre",
    exact: true,
    component: () => <AboutEvre />,
  },
  {
    path: "/solutions",
    title: "/solutions",
    exact: true,
    component: () => <Solutions />,
  },
  {
    path: "/logistics",
    title: "/logistics",
    exact: true,
    component: () => <LogisticsScreen />,
  },
  {
    path: "/privacy-policy",
    title: "/privacy-policy",
    exact: true,
    component: () => <PrivacyScreen />,
  },
];
