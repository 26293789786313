import React from "react";
import {
  AllPageHead,
  HomeNavigation,
  ImageTopCenter,
  ImageRightCenter,
  ImageLeftCenter,
  ElectriceVehicales,
  BottomCenterProduct,
  WhatNext,
  WhyEvreCoursal,
} from "../../Atom";
import { GovermentCarousal, WhyEvreCoursalGovernment } from '../../../Data'

const para =
  "The EV Revolution is here and governments across the world can act as the key influencers of this shift. With large-scale adoption of EV Charging Infra in public areas, smart cities, and highways, the government can help drive the revolution to success.";
const paraCenter =
  "The biggest challenge in the transition to EVs is the lack of EV Charging Infra that is safe, connected, and easily accessible. With EVRE, governments can access an interoperable EV Charging solution that is smart, connected, and easy to use.";
const paraRight =
  "EV Charging Infra in public places can help solve the challenge of range and charge anxiety, giving a much-needed push to EV adoption. This will not only help EV owners but also businesses already using or planning to switch to EV fleets. Apart from promoting sustainable mobility, this will also help governments generate funds for further expansion of similar initiatives.";
const paraLeft =
  "The future is electric and going to home millions of EVs in the coming years. To meet this demand, governments can retrofit EV Charging Infra in smart cities. This will not only help the government support the EV uptake and reduce crude oil imports but also generate revenue by providing EV Charging as a service to people and businesses at large.";
const paraBottom =
  "Presenting the futuristic and intelligent EV Charging Street Light Solution with multi-charge facility, all-weather durability, and much more!";

const paraRightOne = "Become a world leader in the EV uptake with EVRE. Electric is the future and apart from promoting sustainable mobility, it also offers a better environment, cost savings in terms of crude oil imports, revenue generation, and much more. Highways electrification can boost the EV uptake by building confidence in EV owners to plan inter-city travel and uplift an EV dominant ecosystem."

export const GovermentTemplates = ({
  l1id,
  l2id,
  l3id,
  l4id,
  l5id
}) => {
  return (
    <>
      <HomeNavigation name="Solutions" />

      <AllPageHead title="Government" para1={para} />

      <ImageTopCenter
        para1={paraCenter}
        image={"./images/ProductCommonImages/one.png"}
      />

      <div id={l1id}>
        <ImageRightCenter
          title="Public Places"
          para={paraRight}
          image={"./images/ProductCommonImages/three.png"}
        />
      </div>

      <div id={l2id}>
        <ImageLeftCenter
          title="Smart Cities"
          para={paraLeft}
          image={"./images/ProductCommonImages/two.png"}
        />
      </div>

      <div id={l3id}>
        <ImageRightCenter
          title="Highways"
          para={paraRightOne}
          image={"./images/ProductCommonImages/four.png"}
        />
      </div>

      <ElectriceVehicales />

      <div id={l4id}>
        <WhyEvreCoursal CarousalData={WhyEvreCoursalGovernment} />
      </div>

      <BottomCenterProduct
        data={GovermentCarousal}
      />

      <div id={l5id}>
        <WhatNext />
      </div>

    </>
  );
};
