import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import { HomeNavigation, ContactGridOne } from "../../Atom";
import { Footer, ScrollTop, SolutionsTabBar, TopBarAll, TopNavbar } from "../../Molecules";
import { contactData } from "../../../Data";
import { ContactImageTemplate } from "../../Templates";
import { ContactFormTemplate } from "../../Templates/Contact/ContactFormTemplate";

export const Contact = () => {
  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <Box>
      <SolutionsTabBar
        l1="Contact Details"
        l1id="#address"
        genralNavigation="genralNavigation"
      />
      {/* <ScrollTop /> */}
      <HomeNavigation name="Contact" />

      <ContactFormTemplate />

      <ContactImageTemplate />

      <div id="address">
        <ContactGridOne mapData={contactData} />
      </div>

      <Footer />
    </Box>
  );
};
