import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import { useHistory } from "react-router";
import { NavLink } from 'react-router-dom'
//React Reveal
export * from "./BottomBarScroll";


const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        margin:"0 auto",
        // marginLeft: '-10%',
        display: "flex",
        justifyContent: "space-between",
        alignItems: "end",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            flexDirection: "column",
            display:"none"
        },
    },
    menu: {
        // margin: 10,.
        color: "#fff",
        font: "normal normal normal 1rem urbanist",
        textDecoration: "none",
        "&:hover": {
            color: '#00BF5F '
        }
    },
    active: {
        color: "#00BF5F ",
    },
    barAlign: {
        textAlign: "right",
        [theme.breakpoints.down("sm")]: {
            width: '100%',
            textAlign: "left",
        },
    },
    heading: {
        color: "#fff",
        font: "normal normal normal 1rem urbanist",
        cursor: 'pointer',
        "&:hover": {
            color: "#00BF5F ",
            "& $mobile": {
                display: "block",
            },
            "& $arrow": {
                display: "block",
            },
        },
        [theme.breakpoints.down("sm")]: {
            width: '100%',
            paddingLeft: '2%',
            marginBottom: '-5%'
        },
    },
    mobile: {
        display: 'none'
    },
    arrow: {
        display: 'none',
        width: '40%',
        marginLeft: '55%',
    },
    // '@media(max-width: 1440px)': {
    //     root: {
    //         width: "110%",
    //         marginLeft: '-5%',
    //     },
    // },
    // '@media(max-width: 1366px)': {
    //     root: {
    //         width: "110%",
    //         marginLeft: '-5%',
    //     },
    // },
}));

export const BottomBarForUpcoming = ({ style }) => {
    const classes = useStyles();
    const history = useHistory()
    return (
        <Box className={classes.root} style={style}>


            <Typography className={classes.heading} onClick={() => history.push('/evreproduct')}>
                <img className={classes.mobile} style={{ width: '40%' }} src={"./images/All/mobile.png"} alt="mobile" />
                <br />
                <sanp className={classes.span}>CHARGE WITH EVRE</sanp>
                <br />
                <img className={classes.arrow} src={"./images/Arrow/Arrowleft.png"} alt="arrow" />
            </Typography>


            <Box className={classes.barAlign}>
                <h5>
                    <NavLink
                        className={classes.menu}
                        activeClassName={classes.active}
                        to="/infinity"
                    >
                        Infinity
                    </NavLink>
                </h5>
                <h5>
                    <NavLink
                        className={classes.menu}
                        activeClassName={classes.active}
                        to="/pod"
                    >
                        POD
                    </NavLink>
                </h5>
                <h5>
                    <NavLink
                        className={classes.menu}
                        activeClassName={classes.active}
                        to="/ac"
                    >
                        AC-001
                    </NavLink>
                </h5>
                <h5>
                    <NavLink
                        className={classes.menu}
                        activeClassName={classes.active}
                        to="/intellipole"
                    >
                        Intellipole
                    </NavLink>
                </h5>
                <h5>
                    <NavLink
                        className={classes.menu}
                        activeClassName={classes.active}
                        to="/upcomming"
                    >
                        Upcoming Products
                    </NavLink>
                </h5>
            </Box>
        </Box>
    );
};
