import React, { useState } from 'react'
import { makeStyles, Box } from "@material-ui/core";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  mainRoot: {
    width: '100%',
    position: 'fixed',
    top: '0%',
    zIndex: '1',
    backgroundColor: '#000',
    [theme.breakpoints.down("sm")]: {
      paddingBottom: '1%',
    },
  },
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "80%",
    margin: "0 auto",
    color: "#fff",
    paddingTop: "2%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      padding: "1.2rem 0",
    },
  },
  heading: {
    cursor: "pointer",
    width: "8rem",
    [theme.breakpoints.down("sm")]: {
      width: "5.2rem",
    },
  },
  Menu: {
    width: "50%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-end"
    },
  },
  ul: {
    width: "100%",
    display: "flex",
    justifyContent: "space-around",
    listStyle: "none",
    font: "normal normal normal 1rem urbanist",
    [theme.breakpoints.down("sm")]: {
      display: 'none'
    },
  },
  MenuIcon: {
    cursor: "pointer",
    marginLeft: '5%',
    [theme.breakpoints.down("sm")]: {
      marginLeft: '0%'
    },
  },
  noActive: {
    textDecoration: 'none',
    font: "normal normal normal 1.1rem urbanist",

    color: '#fff'
  },
  active: {
    color: '#00BF5F '
  },
  '@media(max-width: 1440px)': {
    Menu: {
      width: "60%",
    },
  },
  '@media(max-width: 1366px)': {
    Menu: {
      width: "60%",
    },
  },
  NewNav: {
    width: '100%',
    marginTop: '2%',
    backgroundColor: '#0C0C0C',
    [theme.breakpoints.down("sm")]: {
      display: 'none'
    },
  },
  NewNavSecond: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '80%',
    margin: '0 auto'
  },
  chargeWithEVRE: {
    font: "normal normal normal 1rem urbanist",
  },
  newUl: {
    display: 'flex',
    justifyContent: 'center',
    listStyle: 'none',
    font: "normal normal normal 1rem urbanist",
  },
  newLileft: {
    margin: '1rem 1rem 1rem 0rem',
    cursor: 'pointer',
  },
  newLi: {
    // marginLeft: '.5%',
    cursor: 'pointer',
    height: "100%",
    padding: "1rem .8rem",
    transition: 'all .3s ease-in-out',
    color: 'grey',
    position: 'relative',
    "&::before": {
      content: "''",
      position: 'absolute',
      bottom: '0',
      left: "50%",
      width: '0%',
      height: "2px",
      background: "#00BF5F",
      transition: "all .4s ease-in-out"
    },
    "&::after": {
      content: "''",
      position: 'absolute',
      bottom: '0',
      right: "50%",
      width: '0%',
      height: "2px",
      background: "#00BF5F",
      transition: "all .4s ease-in-out"
    },
    "&:hover": {
      color: "#fff",
      "&::before": {
        width: '50%'
      },
      "&::after": {
        width: '50%'
      },
    }
  }
}));

const active = {
  color: '#00BF5F',
  boxShadow: 'inset 0 -2px 0 #00BF5F'
}

const unActive = {
}

export const SolutionsTabBar = ({
  l1, l1id,
  l2, l2id,
  l3, l3id,
  l4, l4id,
  l5, l5id,
  l6, l6id,
  genralNavigation
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [state, setState] = useState("")

  return (
    <Box className={classes.mainRoot}>
      <Box className={classes.root}>
        <img
          className={classes.heading}
          src={"./images/Logo/Logo.svg"}
          onClick={() => history.push("/home")}
          alt="Logo"
        />
        <Box className={classes.Menu}>
          {
            genralNavigation ?
              <ul className={classes.ul}>
                <li><NavLink activeClassName={classes.active} className={classes.noActive} to="/infinity">Products</NavLink></li>
                <li><NavLink activeClassName={classes.active} className={classes.noActive} to="/evreproduct">Charge with EVRE</NavLink></li>
                <li><NavLink activeClassName={classes.active} className={classes.noActive} to="/aboutus">About Us</NavLink></li>
                <li><NavLink activeClassName={classes.active} className={classes.noActive} to="/switch">Why make the Switch</NavLink></li>
                <li><NavLink activeClassName={classes.active} className={classes.noActive} to="/contact">Contact Us</NavLink></li>
              </ul>
              :
              <ul className={classes.ul}>
                <li><NavLink activeClassName={classes.active} className={classes.noActive} to="/evreproduct">Home</NavLink></li>
                <li><NavLink activeClassName={classes.active} className={classes.noActive} to="/aboutevre">EVRE Mobile App</NavLink></li>
                <li><NavLink activeClassName={classes.active} className={classes.noActive} to="/solutions">Solutions</NavLink></li>
                {/*<li><NavLink activeClassName={classes.active} className={classes.noActive} to="/home">Locate a Charger</NavLink></li>*/}
                {/* <li><NavLink activeClassName={classes.active} className={classes.noActive} to="/franchise">Own a Franchise</NavLink></li> */}
              </ul>
          }
          <img
            className={classes.MenuIcon}
            src={"./images/TopNavbar/menu.png"}
            alt="logo"
            onClick={() => history.push("/menu")}
          />
        </Box>
      </Box>


      <Box className={classes.NewNav}>
        <Box className={classes.NewNavSecond}>
          {
            genralNavigation ?
              <Box></Box>
              :
              <a href="/infinity"><h6 className={classes.chargeWithEVRE}>Check out our Products</h6></a>
          }
          <ul className={classes.newUl}>
            {
              l1 ? <a href={l1id} onClick={() => setState("id1")}>
                <li style={state == "id1" ? active : unActive} className={classes.newLi}>{l1}</li>
              </a> : ''
            }
            {
              l2 ?
                <a href={l2id} onClick={() => setState("id2")}>
                  <li style={state == "id2" ? active : unActive} className={classes.newLi}>{l2}</li>
                </a> : ""
            }
            {
              l3 ?
                <a href={l3id} onClick={() => setState("id3")}>
                  <li style={state == "id3" ? active : unActive} className={classes.newLi}>{l3}</li>
                </a> : ""
            }
            {
              l4 ?
                <a href={l4id} onClick={() => setState("id4")}>
                  <li style={state == "id4" ? active : unActive} className={classes.newLi}>{l4}</li>
                </a> : ''
            }
            {
              l5 ?
                <a href={l5id} onClick={() => setState("id5")}>
                  <li style={state == "id5" ? active : unActive} className={classes.newLi}>{l5}</li>
                </a> : ''
            }
            {
              l6 ?
                <a href={l6id} onClick={() => setState("id6")}>
                  <li style={state == "id6" ? active : unActive} className={classes.newLi}>{l6}</li>
                </a> : ''
            }
          </ul>
        </Box>
      </Box>
    </Box>
  )
}




