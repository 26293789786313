import React from "react";
//Material ui
import { Box, makeStyles, Typography } from "@material-ui/core";

//Style
const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    margin: "0 auto",
    position: "relative",
    marginBottom: "30%",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      margin: "0 auto",
      position: "relative",
      marginBottom: "80%",
    },
  },
  image: {
    width: "80%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  imageBox: {
    width: "100%",
    marginTop: "5%",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "5%",
      textAlign: "center",
    },
  },
  evreHead: {
    width: "75%",
    position: "absolute",
    top: "70%",
    left: "10%",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      position: "absolute",
      top: "60%",
      left: 0,
    },
  },
  lineOne: {
    font: "normal normal bolder 5rem Urbanist",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      font: "normal normal bolder 2rem Urbanist",
      textAlign: "left",
    },
  },
  lineTwo: {
    color: "#00BF5F ",
    font: "normal normal bolder 5rem  Urbanist",
    textAlign: "left",
    marginLeft: 20,
    [theme.breakpoints.down("sm")]: {
      color: "#00BF5F ",
      font: "normal normal bolder 2rem  Urbanist",
      textAlign: "left",
      marginLeft: 10,
    },
  },
  lineThree: {
    textAlign: "left",
    font: "normal normal bolder 5rem Urbanist",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      font: "normal normal bolder 2rem Urbanist",
    },
  },
  para: {
    width: "60%",
    font: "normal normal lighter 1rem Urbanist",
    lineHeight: "1.5",
    marginTop: "3%",
    textAlign: "left",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "10%",
      marginLeft: 0,
      fontSize: "1rem",
      lineHeight: "1.5",
    },
  },
  paraAlignments: {
    display: "flex",
  },
}));

export const BecomeFranchise = ({ image, para1 }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.imageBox}>
        {/* <img
          className={classes.image}
          src={"./images/Franchise/becomeFranchise.png"}
          alt="imageCenter"
        /> */}
        <Box className={classes.evreHead}>
          <Typography className={classes.lineOne}>Why become</Typography>
          <Box className={classes.paraAlignments}>
            <Typography className={classes.lineOne}>an</Typography>
            <Typography className={classes.lineTwo}>EVRE</Typography>
          </Box>
          <Typography className={classes.lineThree}>Franchise?</Typography>
          <Typography className={classes.para}>
            With the rising adoption of electric vehicles, EV charging services
            are in high demand. Becoming an EVRE franchise enables you to
            capitalize on this demand without any investment or procurement
            effort.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
