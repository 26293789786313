import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { Technology } from './Technology'

const useStyles = makeStyles((theme) => ({
  root: {
    width: "60%",
    margin: "0 auto",
    marginTop: "15%",
    [theme.breakpoints.down('sm')]: {
      width: '95%',
    }
  },
  headingText: {
    font: "normal normal 600 5rem Urbanist",
    background: "#006934",
    textAlign: 'center',
    background: "-webkit-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "-moz-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    [theme.breakpoints.down("md")]: {
      fontSize: "5rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "3rem",
    },
  },
  paraText: {
    lineHeight: '1.8',
    font: "normal normal lighter 1.87rem Urbanist",
    textAlign: 'center',
    width: '60%',
    margin: '0 auto',
    marginTop: '5%',
    [theme.breakpoints.down("sm")]: {
      width: '90%',
      font: "normal normal lighter 6vw Urbanist",
      lineHeight: '1.6'
    },
  }
}));

export const FifthBox = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography className={classes.headingText}>Technology</Typography>
      <Typography className={classes.paraText}>EVRE is bringing unprecedented innovation to the EV Charging ecosystem. With our patented technology, we are working towards making EV charging ubiquitous.</Typography>
      <Technology />
    </Box>
  );
};
