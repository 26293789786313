import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "60%",
    margin: "0 auto",
    marginTop: "10%",
    [theme.breakpoints.down('sm')]: {
      width: "80%",
    }
  },
  rootBox_01: {
    backgroundColor: "#1A1A1A",
    width: "40%",
    paddingLeft: 20,
    paddingRight: 50,
    paddingBottom: 50,
    paddingTop: 50,
    marginTop: '20%',
    position: "relative",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    }
  },
  rootBox_02: {
    backgroundColor: "#1A1A1A",
    width: "40%",
    paddingLeft: 20,
    paddingRight: 50,
    paddingBottom: 50,
    paddingTop: 50,
    float: "right",
    marginTop: '20%',
    position: "relative",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      float: "none",
      "& $h1_s":{
          right:20
      }
    }
  },
  rootBox_03: {
    backgroundColor: "#1A1A1A",
    width: "40%",
    paddingLeft: 20,
    paddingRight: 50,
    paddingBottom: 50,
    paddingTop: 50,
    marginTop: '50%',
    position: "relative",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      marginTop: '20%',

    }
  },
  rootBox_04: {
    backgroundColor: "#1A1A1A",
    width: "40%",
    paddingLeft: 20,
    paddingRight: 50,
    paddingBottom: 50,
    paddingTop: 50,
    float: "right",
    marginTop: '20%',
    position: "relative",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      float: "none",
      "& $h1_s":{
        right:20
    }

    }
  },
  h1_s: {
    font: "normal normal bolder 5rem Urbanist",
    background: "-webkit-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "-moz-linear-gradient(to top, #006934 0%,#00BF5F 100%)",
    background: "linear-gradient(to top, #006934 0%,#00BF5F 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    position: "absolute",
    top: "-3rem"
  },
  p_s: {
    font: "normal normal 1.2rem Urbanist",
    lineHeight: 1.5
  },



}));


const Card = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div >
        <div className={classes.rootBox_01}>
          <h1 className={classes.h1_s}>01</h1>
          <p className={classes.p_s}>
            We are currently present across all major cities of India and growing.
          </p>
        </div>
      </div>

      <div >
        <div className={classes.rootBox_02}>
          <h1 className={classes.h1_s} >02</h1>
          <p className={classes.p_s} >
            We have strategic partnerships with 15+ renowned brands who are
            leading the EV shift with us.
          </p>
        </div>
      </div>

      <div>
        <div className={classes.rootBox_03}>
          <h1 className={classes.h1_s}>03</h1>
          <p className={classes.p_s} >
            We have currently commissioned 550+ EV
            Charging stations across India and have 10,000+ more implementation
            on our plate.
          </p>
        </div>
      </div>

      <div>
        <div className={classes.rootBox_04}>
          <h1 className={classes.h1_s}>04</h1>
          <p className={classes.p_s} >
            We currently offer 4 innovative EV Charging Stations and are planning to
            launch more in the coming future.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Card;
