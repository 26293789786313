import React, { useEffect } from "react";
//Material ui
import { Box } from "@material-ui/core";
import { HomeTemplate } from "../../Templates";
import { TopBarMenu } from "../../Molecules";
import { CursorAnimated } from "../../Atom";
import { CursorCustom } from "../../Atom";

export const Home = () => {

  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' });
  }, [])

  return (
    <Box>
      <CursorAnimated/>
      {/* <CursorCustom/> */}
      <TopBarMenu />
      <HomeTemplate style={{cursor:"none"}}/>
    </Box>
  );
};
