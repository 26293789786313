import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  rootss: {
    position: "relative",
    height: "100vh",
  },
  
  slider: {
    margin: '0 auto',
    [theme.breakpoints.down("sm")]: {
      height: "95vh",
      width:"100%"
    },
    "& .splide__pagination": {
      transform: "rotate(90deg)",
      display: "inline-table",
      position: 'absolute',
      bottom: "10% !important",
      left: "40% !important",
      "& a": {
        // border:"1px solid red",
      },
      [theme.breakpoints.down("sm")]: {
        display: "block",
        background: '#00ff7f',
      },
    },
    "& .splide__pagination__page": {
      // display: 'none',
      bottom: "0% !important",
      // border:"2px solid red",
      [theme.breakpoints.down("sm")]: {
        display: "block",
        background: '#00ff7f',
      },
    },
    "& .is-active": {
      // background:'#00BF5F',
    },
  },
  menuActive: {
    color: "#fff",
    font: "normal normal 500 1.1rem urbanist",
    lineHeight:"1",
    textDecoration: "none",
    zIndex: 9,
    marginTop:"2rem",    
    transition:"all .2s ease-in",
    cursor:"pointer",
    "&:hover": {
      color: '#00BF5F'
    }
  },
  green:{
    color:"#00ff7f",
    font: "normal normal 500 1.08rem urbanist",
    lineHeight:"1",
    textDecoration: "none",
    zIndex: 9,  

  },
  active: {
    color: "#00BF5F",
    zIndex: '1',
  },
  barAlign: {
    textAlign: "right",
    zIndex: '0',
    position:"absolute",
    bottom:"6%",
    right:"10%",
    [theme.breakpoints.down("sm")]: {
     display:"none"
    },
  },
  root: {
    width: "80.3%",
    margin: "0 auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end",
    // zIndex: '-1',
    position: "fixed",
    bottom: "5%",
    left: "10%",
    // marginTop: '-10%',
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "column",
      display: 'none',
    },
  },
  heading: {
    color: "#fff",
    font: "normal normal normal 1.2rem urbanist",
    cursor: 'pointer',
    position: "relative",
    transition: "all .3s ease-in-out",
    zIndex: 999,
    position:"absolute",
    bottom:"6%",
    left:"10%",
    "&:hover": {
      color: "#00BF5F ",

      "& $chargeWithEvre": {
        color: "#00BF5F"
      },
      "& $mobile": {
        opacity: '1 !important',
        transform: "translateY(-20px)"
      },
      "& $arrow": {
        opacity: '1 !important',
        transform: "translateX(-20px)"
      },
    },
    [theme.breakpoints.down("sm")]: {
      display:"none"
    },
  },
  chargeWithEvre: {
    cursor: "pointer",
    font: "normal normal 500 1.1rem urbanist",
  },
  image: {
    width: "100%",
    maxHeight: "100%",
    transition: "all .3s ease-in-out",

  },
  mobile: {
    width: "55%",
    opacity: '0 !important',
    position: "absolute",
    bottom: "50%",
    left: "0",
    transition: "all .3s ease-in-out",
  },
  arrow: {
    width: "3.5rem",
    opacity: '0!important',
    position: "absolute",
    right: "-20%",
    transition: "all .3s ease-in-out",

  },
  '@media(max-width: 1440px)': {
    root: {
      marginTop: '-15%',
    },
  },




  posNav: {
    position: "absolute",
    left: "10",
    top: "10%",
    color: "white",
    // border:"1px solid red"
  },

  red: {
    color: "red",
  },
  white: {
    color: "white"
  }
}));