import React from "react";
import { Switch, Route } from "react-router-dom";
import { Routes } from "./Routes";


const App = () => {
  return (
    <div className="App">
      <Switch>
        {
          Routes.map((item, index) => (
            <Route
              key={index}
              path={item.path}
              exact={item.exact}
              component={item.component}
            />
          ))
        }
      </Switch>
    </div>
  );
};

export default App;
