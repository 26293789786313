import React from "react";
import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    display: "flex",
    marginLeft: "20%",
    overflow: "hidden !important",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "10%",
      marginTop: "10%",
    },
  },
  rootBox: {
    width: "100%",
  },
}));

export const ContactImageTemplate = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.rootBox} data-aos="fade-up">
        <img
          style={{ width: "100%", maxHeight: "100%" }}
          src={"./images/Contact/contact.png"}
          alt="contact image"
        />
      </Box>
    </Box>
  );
};
