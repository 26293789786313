import React, { useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import ReactPlayer from 'react-player';
import Fade from 'react-reveal/Fade';
import { KeyboardArrowRight } from '@material-ui/icons';
import clsx from 'clsx';
import Evrevedio from '../../Assets/EvreVedio.mp4';
import { Redirect } from 'react-router';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      height: '100vh',
      justifyContent: 'center',
    },
  },
  container: {
    padding: '2% 0% 2% 0%',
    width: '90%',
    margin: '0 auto',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      top: '20%',
      left: '0',
      right: '0',
      justifyContent: 'center',
    },
  },
  ImgBox: {
    textAlign: 'center',
    marginLeft: '45%',
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0%',
      marginTop: '5%',
    },
  },
  image: {
    width: '8rem',
    [theme.breakpoints.down('xs')]: {
      width: '6rem',
    },
  },
  skipBox: {
    width: '50%',
    color: '#fff',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  skipBoxSM: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      position: 'absolute',
      bottom: '18%',
      left: '0',
      right: '0',
      margin: '0 auto',
      width: '100%',
      color: '#fff',
      display: 'block',
    },
  },
  skipSM: {
    display: 'none',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.4rem',
      margin: '0 auto',
      width: '80px',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  skip: {
    color: '#ffffff',
    fontSize: '1.4rem',
    float: 'right',
    display: 'inline-block',
    alignItems: 'center',
    fontFamily: 'Urbanist',
    fontWeight: '500',
    cursor: 'pointer',
    paddingTop: '1%',
    transition: 'all 300ms ease-in-out',
    [theme.breakpoints.down('xs')]: {
      fontSize: '1rem',
      fontFamily: 'Urbanist',
      textAlign: 'right',
      marginTop: 20,
    },
    '&:hover': {
      color: '#00BF5F',
      '& $arrows': {
        transform: 'translateX(20px)',
      },
    },
  },
  arrows: {
    fontSize: '1.8rem',
    marginLeft: '10px',
    cursor: 'pointer',
    transition: 'all 200ms ease-in-out',
    color: '#00BF5F',
  },
  videoplayer: {
    width: '100%',
    display: 'flex',
    // alignItems: "center",
    [theme.breakpoints.down('xs')]: {
      height: '100vh',
      display: 'flex',
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
  },
}));

export const IntroTemplates = () => {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    // window.location.href = "/home";
    history.push('/home');
  };
  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.ImgBox}>
          <Fade top>
            <img
              className={classes.image}
              src={'./images/Logo/Logo.svg'}
              alt="Logo"
            />
          </Fade>
        </Box>
        <Box className={classes.skipBox}>
          <Fade left>
            <h5 className={classes.skip} onClick={() => history.push('/home')}>
              Skip
              {/* &gt;&gt; */}
              <i
                className={clsx(classes.arrows, 'fa fa-angle-double-right')}
                aria-hidden="true"
              ></i>
            </h5>
          </Fade>
        </Box>
      </Box>
      <Box className={classes.videoplayer}>
        <ReactPlayer
          // url={
          //   "https://firebasestorage.googleapis.com/v0/b/brebeltesting.appspot.com/o/evrevideo%2FEvre%20v2_1.mp4?alt=media&token=e26ec411-7e73-43fa-914d-4f9d2a34e759"
          // }
          url={Evrevedio}
          pip
          playing
          muted
          width="100%"
          height="auto"
          onEnded={() => handleClick()}
        />

        <Box className={classes.skipBoxSM}>
          <Fade left>
            <h5
              className={classes.skipSM}
              onClick={() => history.push('/home')}
            >
              Skip
              {/* &gt;&gt; */}
              <i
                className={clsx(classes.arrows, 'fa fa-angle-double-right')}
                aria-hidden="true"
              ></i>
            </h5>
          </Fade>
        </Box>
      </Box>
    </Box>
  );
};
