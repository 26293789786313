import React from "react";
import Slider from "react-slick";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  slides: {
    padding: '0% 25px',
    width: '100%'
  },
  headingText: {
    font: "normal normal bolder 3.7rem Urbanist",
    textAlign: "center",
    background: "#006934",
    background: "-webkit-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "-moz-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    [theme.breakpoints.down("md")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
    },
  },
  paraTexts: {
    font: "normal normal lighter 1.5vw Urbanist",
    textAlign: "center",
    marginTop: 20,
    lineHeight: '1.4',
    [theme.breakpoints.down("sm")]: {
      fontSize: '6vw'
    },
  },
}));

export const ParaCarousel = () => {
  const classes = useStyles();

  const Settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    // autoplay: true,
    // autoplaySpeed: 2000,
  };

  return (
    <div className={classes.root}>
      <div className={classes.slides}>
        <h2 className={classes.headingText}>Why make the switch?</h2>
        <p className={classes.paraTexts}>
          Explore knowledge resources on the latest happenings in the EV world
          and how the EV Revolution is bringing booming business opportunities
          along with a sustainable change.
        </p>
      </div>

      {/* <Slider {...Settings}>
        <div className={classes.slides}>
          <h2 className={classes.headingText}>Why make the switch?</h2>
          <p className={classes.paraTexts}>
            Explore knowledge resources on the latest happenings in the EV world
            and how the EV Revolution is bringing booming business opportunities
            along with a sustainable change.
          </p>
        </div>
        <div className={classes.slides}>
          <h2 className={classes.headingText}>Why make the switch?</h2>
          <p className={classes.paraTexts}>
            Explore knowledge resources on the latest happenings in the EV world
            and how the EV Revolution is bringing booming business opportunities
            along with a sustainable change.
          </p>
        </div>
        <div className={classes.slides}>
          <h2 className={classes.headingText}>Why make the switch?</h2>
          <p className={classes.paraTexts}>
            Explore knowledge resources on the latest happenings in the EV world
            and how the EV Revolution is bringing booming business opportunities
            along with a sustainable change.
          </p>
        </div>
        <div className={classes.slides}>
          <h2 className={classes.headingText}>Why make the switch?</h2>
          <p className={classes.paraTexts}>
            Explore knowledge resources on the latest happenings in the EV world
            and how the EV Revolution is bringing booming business opportunities
            along with a sustainable change.
          </p>
        </div>
        <div className={classes.slides}>
          <h2 className={classes.headingText}>Why make the switch?</h2>
          <p className={classes.paraTexts}>
            Explore knowledge resources on the latest happenings in the EV world
            and how the EV Revolution is bringing booming business opportunities
            along with a sustainable change.
          </p>
        </div>
      </Slider> */}
    </div>
  );
};
