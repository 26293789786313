import React from "react";
import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    margin: "auto",
    marginTop: "5%",
    marginBottom: "2%",
    [theme.breakpoints.down('sm')]: {
      marginTop: '15%'
    }
  },
  head: {
    font: "normal normal bolder 3vw Urbanist",
    marginLeft: '10%',
    background: "#006934",
    background: "-webkit-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "-moz-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    marginBottom: "2%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
      textAlign: "center",
      marginLeft: '0%'
    },
  },
  flexBox: {
    width: "80%",
    margin: "auto",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: 'column-reverse',
      width: "100%",
    }
  },
  boxFlex: {
    width: "45%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      width: '100%'
    }
  },
  boxFlex1: {
    width: "50%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      width: '100%'
    }
  },
  image: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: '100%'
    }
  },
  para: {
    font: "normal normal 200 1.5vw Urbanist",
    lineHeight: "1.5",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      fontSize: '4vw'
    }
  },
}));

export const DealerHeadTemplate = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <h1 className={classes.head}>Become-A-Dealer</h1>
      <Box className={classes.flexBox}>
        <Box className={classes.boxFlex}>
          <p className={classes.para}>
            Adopt 21st Century’s most disruptive business model with EVRE and
            capitalize on the biggest automobile revolution that the world has
            ever seen. The switch towards EVs will be ubiquitous and will need
            EV Charging Infra that is interoperable, intelligent, and easy to
            use.
          </p>
          <br />
          <br />
          <p className={classes.para}>
            Become an EVRE Dealership and power the EV Revolution by offering
            next-generation EV Charging solutions to the world.
          </p>
        </Box>
        <Box className={classes.boxFlex1}>
          <img
            className={classes.image}
            src={"./images/Dealer/Dealer.png"}
            alt="Dealer"
          />
        </Box>
      </Box>
    </Box>
  );
};
