import React, { useRef } from "react";
import { Box, makeStyles } from "@material-ui/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: '15%',
    zIndex: 0,
  },
  head: {
    font: "normal normal 500 8.75rem Urbanist",
    background: "#006934",
    background: "-webkit-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "-moz-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    margin: '0 2rem',
    [theme.breakpoints.down("md")]: {
      fontSize: "5rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "3rem",
    },
  },
  headMiddle: {
    font: "normal normal 200 8.75rem Urbanist",
    background: "#006934",
    background: "-webkit-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "-moz-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "linear-gradient(to top, #006934 0%,#00FF7F 100%)",

    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    [theme.breakpoints.down("md")]: {
      fontSize: "5rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "3rem",
    },
  },
  slider: {
    width: '100%',
    margin: '0 auto',
    display: "flex",
    justifyContent: 'center',
  },
  card: {
    maxWidth: "50%",
    margin: "0 25%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "90%",
      margin: "0 5%",

    },
  },
  idCard: {
    display: 'flex',
    width: '50%',
    margin: '0 auto',
    marginTop: '5%',
    [theme.breakpoints.down("sm")]: {
      width: "90%"
    },
  },
  id: {
    font: 'normal normal 900 3.8vw Urbanist',
    color: '#00BF5F'
  },
  idText: {
    font: 'normal normal normal 3vw Urbanist',
    marginLeft: '5%',
    [theme.breakpoints.down("sm")]: {
      fontSize: '8vw',
      fontWeight: "500",
      marginLeft: '0%'
    },
  },
  mainFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '5%',
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: 'flex-start',
      marginTop: "8%"
    },
  },
  CurvBox: {
    width: '20%',
    [theme.breakpoints.down("sm")]: {
      width: '100%',
      marginTop: '2%',
      "& img": {
        display: 'none'
      }
    },
    "& img": {
      width: '100%'
    }
  },
  smBottom: {
    [theme.breakpoints.down("sm")]: {
      alignItems: 'flex-end',
      float: 'right',
      marginTop: "8%"
    }
  },
  heading: {
    font: 'normal normal 600 2.3vw Urbanist',
    lineHeight: '1',
    [theme.breakpoints.down('sm')]: {
      fontSize: "2rem"
    }

  },
  para: {
    font: 'normal normal lighter 1.2vw Urbanist',
    letterSpacing: '.5px',
    lineHeight: '1',
    marginTop: "2%",
    [theme.breakpoints.down('sm')]: {
      fontSize: "6vw",
      lineHeight: '1.5',

    }

  },
  imageFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '50%',
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: 'center',
      marginTop: "10%"
    },
  },
  imageUp: {
    marginBottom: '10%',
    width: '45%'
  },

  imageDown: {
    marginTop: '10%',
    width: '45%',
    marginLeft: "4%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "2%"
    },
  },
  arrowBox: {
    width: "100%",
    marginTop: '2%',
    display: "inline-block",
    [theme.breakpoints.down("sm")]: {
      // marginTop: "2%"
    },
  },
  arrowFloat: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    float: "right",
    [theme.breakpoints.down("sm")]: {
      float: "none",
      width: "100%",
    },
  },
  arrowIcon: {
    margin: "0 5%",
    background: "none",
    border: "none",
    outline: "none",
    opacity: ".5",
    cursor: "pointer",
    "&:hover": {
      opacity: "1",
    },
  },
}));

export const ThirdBox = () => {
  const classes = useStyles();

  const sliderRef = useRef();

  const next = () => {
    sliderRef.current.slickNext();
  };
  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const settings = {
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Box className={classes.root}>
        <marquee behavior="scroll" direction="left" scrollamount="20" >
          <span className={classes.head}>FEATURES</span>
          <span className={classes.headMiddle}>FEATURES</span>
          <span className={classes.head}>FEATURES</span>
          <span className={classes.headMiddle}>FEATURES</span>
          <span className={classes.head}>FEATURES</span>
          <span className={classes.headMiddle}>FEATURES</span>
          <span className={classes.head}>FEATURES</span>
          <span className={classes.headMiddle}>FEATURES</span>
          <span className={classes.head}>FEATURES</span>
          <span className={classes.headMiddle}>FEATURES</span>
          <span className={classes.head}>FEATURES</span>
          <span className={classes.headMiddle}>FEATURES</span>
          <span className={classes.head}>FEATURES</span>
          <span className={classes.headMiddle}>FEATURES</span>
          <span className={classes.head}>FEATURES</span>
          <span className={classes.headMiddle}>FEATURES</span>
          <span className={classes.head}>FEATURES</span>
          <span className={classes.headMiddle}>FEATURES</span>
          <span className={classes.head}>FEATURES</span>
          <span className={classes.headMiddle}>FEATURES</span>
          <span className={classes.head}>FEATURES</span>
        </marquee>
      </Box>

      <Box className={classes.idCard}>
        <h1 className={classes.idText}>Intuitive Mobile Interface</h1>
      </Box>

      <Slider ref={sliderRef} {...settings} className={classes.slider}>

        <Box className={classes.card}>

          <Box className={classes.mainFlex}>

            <Box className={classes.CurvBox}>
              <h1 className={classes.heading}>Locate</h1>
              <p className={classes.para}>Helps locate your nearest EV Chargers</p>
              <img src={'./images/AboutEvre/left.svg'} alt="Curv_Line" />
            </Box>

            <Box className={classes.imageFlex}>
              <img className={classes.imageUp} src={'./images/AboutEvre/mobile_view/Locate.png'} alt="mobile" />
              <img className={classes.imageDown} src={'./images/AboutEvre/mobile_view/Reservation_option.png'} alt="mobile" />
            </Box>

            <Box className={clsx(classes.CurvBox, classes.smBottom)}>
              <img src={'./images/AboutEvre/right.svg'} alt="Curv_Line" />
              <h1 className={classes.heading}>Reserve</h1>
              <p className={classes.para}>Reserve charging slots in advance as per your convenience</p>
            </Box>

          </Box>

        </Box>


        <Box className={classes.card}>

          <Box className={classes.mainFlex}>

            <Box className={classes.CurvBox}>
              <h1 className={classes.heading}>Navigate</h1>
              <p className={classes.para}>Find the fastest route to reach the EV Charging Station</p>
              <img src={'./images/AboutEvre/left.svg'} alt="Curv_Line" />
            </Box>

            <Box className={classes.imageFlex}>
              <img className={classes.imageUp} src={'./images/AboutEvre/mobile_view/Navigate.png'} alt="Navigate" />
              <img className={classes.imageDown} src={'./images/AboutEvre/mobile_view/Start_session.png'} alt="Start" />
            </Box>

            <Box className={clsx(classes.CurvBox, classes.smBottom)}>
              <img src={'./images/AboutEvre/right.svg'} alt="Curv_Line" />
              <h1 className={classes.heading}>Start/Stop Charging</h1>
              <p className={classes.para}>Use the app to start or stop your charging session</p>
            </Box>

          </Box>

        </Box>

        <Box className={classes.card}>

          <Box className={classes.mainFlex}>

            <Box className={classes.CurvBox}>
              <h1 className={classes.heading}>Session Summary</h1>
              <p className={classes.para}>One-click access to charging session summary</p>
              <img src={'./images/AboutEvre/left.svg'} alt="Curv_Line" />
            </Box>

            <Box className={classes.imageFlex}>
              <img className={classes.imageUp} src={'./images/AboutEvre/mobile_view/sesionSumary.png'} alt="sesionSumary" />
              <img className={classes.imageDown} src={'./images/AboutEvre/mobile_view/pay_options.png'} alt="pay_options.png" />
            </Box>

            <Box className={clsx(classes.CurvBox, classes.smBottom)}>
              <img src={'./images/AboutEvre/right.svg'} alt="Curv_Line" />
              <h1 className={classes.heading}>Payment Options</h1>
              <p className={classes.para}>A list of payment options to choose from</p>
            </Box>

          </Box>

        </Box>

        <Box className={classes.card}>

          <Box className={classes.mainFlex}>

            <Box className={classes.CurvBox}>
              <h1 className={classes.heading}>Charging History</h1>
              <p className={classes.para}>Easy access to charging history and session details</p>
              <img src={'./images/AboutEvre/left.svg'} alt="Curv_Line" />
            </Box>

            <Box className={classes.imageFlex}>
              <img className={classes.imageUp} src={'./images/AboutEvre/mobile_view/chargingHistory.png'} alt="chargingHistory" />
              <img className={classes.imageDown} src={'./images/AboutEvre/mobile_view/recentReservation (1).jpg'} alt="Reservation_option" />
            </Box>

            <Box className={clsx(classes.CurvBox, classes.smBottom)}>
              <img src={'./images/AboutEvre/right.svg'} alt="Curv_Line" />
              <h1 className={classes.heading}>Reservations History</h1>
              <p className={classes.para}>Access to all recent reservations and related details</p>
            </Box>

          </Box>

        </Box>

      </Slider>

      <Box className={classes.arrowBox}>
        <Box className={classes.arrowFloat}>
          <button
            className={classes.arrowIcon}
            style={{ transform: "rotate(180deg)" }}
            onClick={previous}
          >
            <img src={"./images/Arrow/Arrow.png"} alt="arrow" />
          </button>
          <button className={classes.arrowIcon}
            onClick={next}>
            <img src={"./images/Arrow/Arrow.png"} alt="arrow" />
          </button>
        </Box>
      </Box>

    </>
  );
};
