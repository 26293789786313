import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "75%",
    margin: "0 auto",
    marginTop: "10%",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  headingText: {
    font: "normal normal bolder 7rem Urbanist",
    textAlign: "center",
    color: "#00BF5F ",
    [theme.breakpoints.down("sm")]: {
      font: "normal normal bolder 4rem Urbanist",
    },
  },
  boxOne: {
    width: "40%",
    marginTop: "5%",
    marginLeft: 10,
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  numberText: {
    font: "normal normal bolder 5rem Urbanist",
    opacity: "0.5",
  },
  headText: {
    font: "normal normal normal 2rem Urbanist",
    marginTop: 10,
  },
  paratext: {
    font: "normal normal lighter 1rem Urbanist",
    marginTop: 10,
    width: "50%",
    marginLeft: 40,
    lineHeight: "1.5",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  boxtwo: {
    width: "50%",
    marginTop: "5%",
    marginLeft: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      marginLeft: 10,
    },
  },
  boxthree: {
    width: "60%",
    marginTop: "5%",
    marginLeft: 10,
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
}));

export const AdditionalFeature = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Typography className={classes.headingText}>
        Additional Features
      </Typography>
      <Box className={classes.boxOne}>
        <Typography className={classes.numberText}>01</Typography>
        <Typography className={classes.headText}>Air Quality Index</Typography>
        <Typography className={classes.paratext}>
          Monitor the environment around you with Intellipole’s Air Quality
          index.
        </Typography>
      </Box>
      <Box className={classes.boxtwo}>
        <Typography className={classes.numberText}>02</Typography>
        <Typography className={classes.headText}>
          Digital Advertising
        </Typography>
        <Typography className={classes.paratext}>
          Scale your income by providing digital advertising along with EV
          Charging as a Service.
        </Typography>
      </Box>
      <Box className={classes.boxthree}>
        <Typography className={classes.numberText}>03</Typography>
        <Typography className={classes.headText}>CCTV Provision</Typography>
        <Typography className={classes.paratext}>
          Boost the use case of your Intellipole by integrating CCTV Camera for
          increased security.
        </Typography>
      </Box>
      <Box className={classes.boxtwo}>
        <Typography className={classes.numberText}>04</Typography>
        <Typography className={classes.headText}>
          Monitoring Air Quality
        </Typography>
        <Typography className={classes.paratext}>
          Real-time air quality data including PM2.5, PM10 and CO2.
        </Typography>
      </Box>
    </Box>
  );
};
