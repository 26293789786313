import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "60%",
    margin: "0 auto",
    marginTop: "10%",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      marginTop: "20%",
    },
  },
  boxes: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  boxesRight:{
    width: "60%",
    marginTop: "10%",
    position: "relative",
    float: "right",
    paddingTop: '10%',
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "0%",
    },
  },
  heading: {
    font: "normal normal bolder 5rem Urbanist",
    background: "#006934",
    background: "-webkit-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "-moz-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    [theme.breakpoints.down("md")]: {
      fontSize: "6.5rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
  },
  paraText: {
    marginTop: "2%",
    font: "normal normal lighter 1.5rem Urbanist",
    lineHeight: "1.3",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
 
}));

export const TextBox = ({ l1id, l2id }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>

      <Box className={classes.boxes} id={l1id}>
        <Typography className={classes.heading}>Our Vision</Typography>
        <Typography className={classes.paraText}>
          We envision to develop world-class EV Charging solutions to enable
          electrified mobility and do our part in contributing to a ‘Greener
          Planet.
        </Typography>
      </Box>

      <Box
        className={classes.boxesRight}
        id={l2id}
      >
        <Typography className={classes.heading}>Our Mission</Typography>
        <Typography className={classes.paraText}>
          To reduce the impact of global warming by promoting the use of
          sustainable energy and nurturing the spirit of the EV revolution one
          machine, one fleet, one neighborhood at a time.
        </Typography>
      </Box>
    </Box>
  );
};
