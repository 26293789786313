import React, { useEffect } from "react";
//Material ui
import { Box, makeStyles } from "@material-ui/core";
//Routes
import { useHistory } from "react-router";
//Aos
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ViewDetails } from "..";


//Style
const useStyles = makeStyles((theme) => ({
    root: {
        width: "70%",
        margin: "10% auto",
        overflow: 'hidden',
        [theme.breakpoints.down("sm")]: {
            width: "90%",
            marginBottom: '20%'
        },
    },
    image: {
        width: "100%",
        height: "100%",
    },
    flexBox: {
        width: '80%',
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            margin: "5% 0%",
            flexDirection: 'column',
        },
    },
    head: {
        font: "normal normal bolder 2.5vw Urbanist",
        color: '#00BF5F ',
        [theme.breakpoints.down("sm")]: {
            fontSize: "8vw",
        },
    },
    paraBox: {
        width: '80%',
        margin: "0 auto",
        marginTop: '2%',
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
    para: {
        width: "80%",
        lineHeight: "1.8",
        font: "normal normal 200 1.5vw urbanist",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        lineHeight: "1.5",
        fontSize: "6vw",
        },
    },
}));

export const SolutionImageCenter = ({ image, para1, title, route }) => {
    const classes = useStyles();
    const history = useHistory();

    const animation = () => {
        AOS.init({
            easing: 'ease-out-back',
            duration: 1000,
        });
    }

    useEffect(() => {
        animation()
    }, [])
    return (
        <Box className={classes.root}>

            <img className={classes.image} src={image} alt="imageCenter" data-aos="fade-up" />

            <Box className={classes.flexBox} >

                <h1 className={classes.head} data-aos="fade-right">{title}</h1>


            </Box>

            <Box className={classes.paraBox}>

                <p className={classes.para} data-aos="fade-up">{para1}</p>

                <ViewDetails
                    title="view details"
                    route={route}
                    data-aos="fade-right"
                />
            </Box>
        </Box>
    );
};
