import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import { Footer, TopBarAll } from "../../Molecules";
import { ResidentialTemplates } from "../../Templates";

export const Residential = () => {
  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <Box>
      <TopBarAll
        l1="Townships and Apartments"
        l1id="#buildings"
        l2="Independent Houses"
        l2id="#independent"
        l3="Why EVRE"
        l3id="#whyevre"
        l4="Your Best-Fit Chargers"
        l4id="#next"
      />

      <ResidentialTemplates
        l1id="buildings"
        l2id="independent"
        l3id="whyevre"
        l4id="next"
      />

      <Footer />

    </Box>
  );
};
