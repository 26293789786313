import React, { useEffect } from "react";
//Material ui
import { Box, makeStyles } from "@material-ui/core";
//Aos
import AOS from 'aos';
import 'aos/dist/aos.css';

const useStyles = makeStyles((theme) => ({
  HeadBox: {
    width: "63%",
    margin: "0 auto",
    height: "60vh",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      height: "100%",
      justifyContent: "flex-start",
      marginTop: '10%',
      marginBottom: '10%'
    },
  },
  text: {
    font: "normal normal bolder 5vw urbanist",
    background: "#006934",
    background: "-webkit-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "-moz-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12vw",
    },
  },
}));

export const ProductGridTitle = ({ h1, h2, h3 }) => {
  const classes = useStyles();

  const animation = () => {
    AOS.init({
      easing: 'ease-out-back',
      duration: 1000,
    });
  }

  useEffect(() => {
    animation()
  }, [])
  return (
    <Box className={classes.HeadBox}>

      <h3 variant="h1" className={classes.text} data-aos="fade-up">
        {h1}
        <br />
        {h2}
        <br />
        {h3}
      </h3>
    </Box>
  );
};
