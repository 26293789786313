import React from "react";
import { HomeSolutionNavigation } from "../../Atom/AllPages";
import { WhyEvre, FranchiseEnquiryForm, WhyBecomeEvre } from "../../Molecules";
import { HeadFenchise } from './HeadFenchise'
import { ownFranchise } from "../../../Data";

export const FranchiseTemplates = ({ l1id, l2id, l3id }) => {
  return (
    <>
      <HomeSolutionNavigation name="Own a Franchise" />

      <HeadFenchise />

      <div id={l1id}>
        <WhyBecomeEvre data={ownFranchise} head="Why Own an" middleHead="EVRE" head1='Franchise?' />
      </div>

      <div id={l2id}>
        <WhyEvre />
      </div>

      <div id={l3id}>
        <FranchiseEnquiryForm />
      </div>

    </>
  );
};
