import React from "react";
import { makeStyles, Container, Box } from "@material-ui/core";
import { useHistory } from "react-router";
import { Knowledge, ParaCarousel, ImagePara } from "../../Atom";
import { NewsShowcase, BlogShowcase } from "../../Molecules";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  paraBox: {
    width: "55%",
    margin: "0 auto",
    marginTop: "15%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin: "0 auto",
      marginTop: "25%",
    },
  },
  imageBox: {
    width: "100%",
    textAlign: "center",
    marginTop: "5%",
  },
  image: {
    width: "80%",
  },
}));

export const SwitchTemplate = ({ l1id, l2id, l3id }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Container className={classes.root}>
        <Box className={classes.paraBox}>
          <ParaCarousel />
        </Box>
        <Box className={classes.imageBox}>
          <img
            src={"./images/Switch/switch.png"}
            alt="switch"
            className={classes.image}
          />
        </Box>
      </Container>

      <div id={l1id}>
        <Knowledge />
      </div>
      <div id={l2id}>
        <NewsShowcase
          title="News and Events"
          titlePara="Find out about EVRE’s latest initiatives and partnerships straight from the media."
        />
      </div>
      <div id={l3id}>
        <BlogShowcase
          title="Blogs"
          titlePara="Explore EVRE’s custom resources on the opportunities and benefits of the EV Revolution."
        />
      </div>
    </>
  );
};
