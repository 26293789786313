import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import { Footer, TopBarAll } from "../../Molecules";
import { BusinessTemplates } from "../../Templates";

export const Business = () => {
  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <Box>
      <TopBarAll
        l1="Professional Spaces"
        l1id="#professional"
        l2="Automotive Companies"
        l2id="#automotive"
        // l3="Commercial Spaces"
        // l3id="#commercial"
        l4="Why EVRE"
        l4id="#whyevre"
        l5="Solutions for you"
        l5id="#best"
        // l6="What's Next"
        // l6id="#next"
      />

      <BusinessTemplates
        l1id="professional"
        l2id="automotive"
        // l3id="commercial"
        l4id="whyevre"
        l5id="best"
        // l6id="next"
      />

      <Footer />
    </Box>
  );
};
