import React from "react";
import {
  AllPageHead,
  HomeNavigation,
  ImageTopCenter,
  ImageRightCenter,
  ImageLeftCenter,
  ElectriceVehicales,
  BottomCenterProduct,
  WhatNext,
  WhyEvreCoursal,
} from "../../Atom";
import { BusinessCarousal, WhyEvreCoursalBusiness } from "../../../Data";

const para =
  "The EV Revolution has presented businesses with an unprecedented opportunity to lead the greener change. The world awaits a fossil-free future and business tech parks, co-working spaces, logistics and fleet operators, and automotive companies, etc. can redefine CSR by leading the EV Revolution.";
const paraCenter =
  "A major share of EV users prefer charging their EVs at their homes or workplaces considering the halt duration. On the other hand, businesses with electric fleets prefer charging at secure charging hubs where they can safely charge and park their fleet for non-stop business operations.";
const paraRight =
  "Offices, Business Tech Parks, and Co-Working Spaces can burgeon the EV Revolution drastically. Apart from paving a way for a sustainable future, early adopters can carve the world’s history by becoming the drivers of this change. Co-Working Spaces can add EV Charging as an add-on service and soar the demand for its spaces, bringing more sales along with an added revenue stream.";
const paraRight1 =
  "Fast-track your business growth with EVRE. The world is going to home millions of EVs in the coming years and the EV owners will opt for businesses where they can charge their EVs while availing other services.";
const paraLeft =
  "Lack of EV Charging Infra is a major roadblock for EV adoption. As the automotive industry dawns towards the EV shift, they can join hands with EVRE to offer advanced EV Chargers along with their electric vehicles to increase sales or set up charging infra to meet the rising demand for EV Charging.";
const paraBottom =
  "Here is an indomitable and robust 2, 3, & 4 Wheeler EV Charging Station spearheading durability, performance, multi-charge facility.";

export const BusinessTemplates = ({ l1id, l2id, l3id, l4id, l5id, l6id }) => {
  return (
    <>
      <HomeNavigation name="Solutions" />

      <AllPageHead title="Business" para1={para} />

      <ImageTopCenter
        para1={paraCenter}
        image={"./images/ProductCommonImages/Center2.png"}
      />

      <div id={l1id}>
        <ImageRightCenter
          title="Professional Spaces"
          para={paraRight}
          image={"./images/ProductCommonImages/busone.png"}
        />
      </div>

      <div id={l2id}>
        <ImageLeftCenter
          title="Automotive Companies"
          para={paraLeft}
          image={"./images/ProductCommonImages/bustwo.png"}
        />
      </div>

      {/* <div id={l3id}>
        <ImageRightCenter
          title="Commercial Spaces"
          para={paraRight1}
          linkText="Read More"
          linkTarget="commercial"
          image={"./images/ProductCommonImages/busthree.png"}
        />
      </div> */}

      <ElectriceVehicales />

      <div id={l4id}>
        <WhyEvreCoursal CarousalData={WhyEvreCoursalBusiness} />
      </div>

      <div id={l5id}>
        <BottomCenterProduct data={BusinessCarousal} />
      </div>

      <div id={l6id}>
        <WhatNext />
      </div>
    </>
  );
};
