import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "25%",
    paddingTop: '10%'
  },
  mainBox: {
    width: "100%",
    position: "relative",
  },
  textone: {
    font: "normal normal bolder 15rem Urbanist",
    opacity: "0.2",
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      font: "normal normal bolder 10rem Urbanist",
    },
  },
  secondBox: {
    width: "50%",
    position: "absolute",
    top: "15%",
    left: "25%",
  },
  storyText: {
    font: "normal normal bolder 8rem Urbanist",
  },
  boxOne: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  boxTwo: {
    width: "50%",
    marginLeft: "70%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: "0%",
    },
  },
  boxSize: {
    width: "60%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  titleText: {
    font: "normal normal normal 3rem Urbanist",
    color: "#00BF5F ",
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      font: "normal normal 2rem Urbanist",
    },
  },
  paratext: {
    font: "normal normal lighter 1.5rem Urbanist",
    lineHeight: "1.5",
    marginTop: 10,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
}));

export const OurStory = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.mainBox}>
        <marquee behavior="scroll" direction="left" scrollamount="20" className={classes.textone}>Our Story</marquee >
      </Box>
      <Box className={classes.boxSize}>
        <Box className={classes.boxOne}>
          <Typography className={classes.titleText}>
            The world as we know it
          </Typography>
          <Typography className={classes.paratext}>
            With rapid development comes a cost. A cost that’s born by the
            environment. Today, we witness a drastic increase in climatic and
            environmental degradation. The question is how long can we sustain
            this way?
          </Typography>
        </Box>
        <Box className={classes.boxTwo}>
          <Typography className={classes.titleText}>The Shift</Typography>
          <Typography className={classes.paratext}>
            The introduction of electric mobility shows us a ray of hope for a
            greener future. It is one of the only means of transport that will
            lead us to a sustainable and pollution-free world where all species
            live harmoniously. A world that’s free from fossil fuels and runs on
            renewable energy.
          </Typography>
        </Box>
        <Box className={classes.boxOne}>
          <Typography className={classes.titleText}>How we help</Typography>
          <Typography className={classes.paratext}>
            At EVRE, we are working aggressively towards equipping cities with
            smart and sustainable EV charging infrastructure. With this, we
            don’t only strive to promote sustainable mobility but also help
            people make the EV switch with ease and confidence.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
