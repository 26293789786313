import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { ProductInfinityPara } from "../../Atom";
import { DashBoard } from "./DashBoard";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: "0 auto",
    marginTop: "15%",
    [theme.breakpoints.down('sm')]: {
      width: "90%",
    }
  },
  chargingText: {
    font: "normal normal bold 10rem Urbanist",
    opacity: "0.2",
    [theme.breakpoints.down('sm')]: {
      fontSize: '12vw',
      lineHeight: "1"
    }
  },
  managementText: {
    font: "normal normal bold 10rem Urbanist",
    opacity: "0.2",
    textAlign: "right",
    [theme.breakpoints.down('sm')]: {
      fontSize: '12vw',
      lineHeight: "1"
    }
  },
  aboutBox: {
    width: "80%",
    margin: "0 auto",
    marginTop: "5%",
  },
  lefAlignBox: {
    width: "70%",
    margin: "0 auto",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
    }
  },
  aboutText: {
    font: "normal normal bold 10rem Urbanist",
    opacity: "0.2",
    // transform: "rotate(270deg)",
  },
  aboutParaText: {
    font: "normal normal 300 4rem Urbanist",
    width: "70%",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      margin: "0 auto",
      marginTop: "4%",
      fontSize: '8vw',
      fontWeight: "500"
    }
  },
  imageBox: {
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      width: "100%"
    }
  },
  image: {
    width: "100%",
    [theme.breakpoints.down('sm')]: {
      width: "100%"
    }
  },
  paraBox: {
    display: "flex",
    margin: "5% 0%",
    justifyContent: "space-between",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      width:"100%",
    }
  },
  parass: {
    lineHeight: "1.8",
    width: "45%",
    font: "normal normal lighter 1.87rem Urbanist",
    [theme.breakpoints.down("sm")]: {
      fontSize: "6vw",
      width: "100%",
    },
  },
}));

const para1 =
  "EVRE’s business dashboard helps our partners track charging sessions, unit consumption, live EV charging data, billing and payments, and much more.";
const para2 =
  "The dashboard is easy to use and provides an accurate and concise view of your EV Charging business.";

export const SixthBox = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <marquee className={classes.chargingText}>CHARGING</marquee>
      <marquee direction="right" className={classes.managementText}>MANAGEMENT</marquee>
      {/* <Box className={classes.aboutBox}>
          <Typography className={classes.aboutText}>About</Typography>
      </Box> */}

      <Box className={classes.lefAlignBox}>

        <Typography className={classes.aboutParaText}>
          A business dashboard to track every intricacy of your
          <span style={{ color: "#00BF5F " }}> EV Charging Business.</span>
        </Typography>
        {/* <ProductInfinityPara className={{ width:"100% !important", border:"2px solid red" }} para1={para1} para2={para2} /> */}
        <Box className={classes.paraBox}>
          <p className={classes.parass}>{para1}</p>
          <p className={classes.parass}>{para2}</p>
        </Box>

        <Box className={classes.imageBox}>
          <img
            src={"./images/AboutEvre/aboutcharging.png"}
            alt="charging"
            className={classes.image}
          />
        </Box>
      </Box>
    </Box>
  );
};
