import React, { useEffect } from "react";
import {
  TopBarMenu,
  Footer,
  ScrollTop,
  TopBarAll,
  SolutionsTabBar,
} from "../../Molecules";
import { SwitchTemplate } from "../../Templates";
import "./index.css";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { makeStyles, Box } from "@material-ui/core";

let termsData = `# Privacy Policy

### Allow EVRE to access this device’s location
-   Allow all the time
-   Allow only while using the app
-   Deny
### Terms & Conditions
Effective Date: February 3, 2020
Please read this EVRE Account Agreement carefully.
Please read this EVRE Account Agreement carefully.
### General

Amplify Cleantech Solutions Private Limited (“Amplify Mobility”) through the EVRE EV Charging Solution (hereinafter referred as "Solution") is in the business of, among other things, creating, operating and maintaining public EV charging infrastructure, collecting fees for the usage of charging stations and on behalf of owners (“Hosts”) of charging stations for electric vehicles, fees charged to users by such Hosts for access to their charging stations.

The Solution, the EVRE mobile App, the EVRE web dashboards and the Charging stations owned and/or operated by Amplify Mobility or its partners using the EVRE Network, and other products, services made available by Amplify Mobility including the payment gateway and app-based advertising are collectively referred to as the "Service”. In exchange for being enabled to use the Service, you agree to abide by these Terms and Condition of Services (hereinafter referred as "'Terms").

Amplify Mobility offers the Service to you for getting your Electric Vehicle charged at Amplify Mobility owned and/or operated charging station subject to your agreement with these Terms.

Please read these Terms carefully before registering as a Customer for accessing or using our Service. By registering as a Customer, you agree to be bound by these Terms. If you do not agree to all the Terms. then you may not access or use our Service. If these Terms are considered an offer, acceptance is expressly limited to these Terms.

Any new features or tools which are added to the current System or Service shall also be subject to the Terms. You can review the most current version of the Terms at any time on [www.evre.in](https://evre.in/). We reserve the right to update, change or replace any part of these Terms by posting updates and/or changes to our website. It is your responsibility to check our website [www.evre.in](https://evre.in/) periodically for changes. Your continued use of or access to our Service following the posting of any changes constitutes acceptance of those changed Terms.

### Registration as Customer

You agree that before using our Service you will register into the System as a Customer. For registration you will download EVRE Native App either from Google Play Store or Apple Store using a compatible smartphone or go to the website [www.evre.in](https://evre.in/) and register yourself as Customer by providing personal Information such as email address, phone number and by creating a password for your account. Additional personal information might be required after registration subject to services requested by the Customer, such as correspondence address for being able to receive a RFID Tag or Invoice, in case of Membership and credit / debit card details for being able to purchase charging services at charging stations (Collectively referred to as "Personal Data''). The Credit Card/Debit Card details shall be stored on Stripe Servers (referred to as "Payment Gateway Service Provider, located In India and all other Information shall be stored on our authorized service Amazon Web Service located In Europe and you are hereby authorizing us for this purpose.

### Connection to the Service

You can access service at charging units either with the EVRE Native App using your User ID and Password or through preauthorized RFID tag. You will be solely responsible for safe custody of RFID tags and be responsible for its authorized and possible unauthorized use. Amplify Mobility shall not be liable for use of your RFID by any unauthorized person.

### Charging Session

Charging session starts from the time you connect your Electric Vehicle / Plug-in Hybrid Electric Vehicle to the charging unit and is started through the App or RFID and ends when you or the System disconnects your vehicle from the charging unit. It is the Customer's responsibility to ensure that charging begins and completes correctly. To ensure that the charging has begun, the Customer shall refer to the information visible on the charging station or in the EVRE mobile app.

It is the Customer's responsibility to ensure that the vehicle has the right conditions to charge at the charging station. It Is the Customer's responsibility to update his/her EVRE account with Information about any new debit or credit card as soon as possible after receiving the information. Amplify Mobility will not be responsible for decline of authorization for charging if credit / debit card details are not verified by its Issuer bank.

### Payment for availing Service

For availing Service, you have to register first as a Customer. During registration you shall select one of the payment methods offered by Amplify Mobility. You shall be providing appropriate payment information to enable Amplify Mobility to receive the due amount against the Transaction and create a valid invoice for the Transaction. Payment through Credit Card/ Debit Card is processed through Stripe India (Payment Gateway Service Provider). If subscription / periodical invoicing has been opted (presently this option is available to only B2B Customers), a consolidated invoice shall be generated at the end of the period mutually agreed between Amplify Mobility and B2B Customer. Such payment shall not be processed through Stripe India.

It is the Customer's responsibility to ensure that the registered debit card or credit card Is valid, that it has a sufficient balance and is not blocked. In the event that debiting is not possible, Amplify Mobility has the right to require payment directly from the Customer.

It is the Customer's responsibility to ensure that the user information is always correctly registered In the EVRE Native App which he/she will be required to download prior to registration as customer. Amplify Mobility is not responsible for incorrect input data during registration, regardless of the registration method.

By accepting these terms. you accept that Amplify Mobility will debt the Applicable charging fee. including applicable taxes and service charges from your registered Credit/Debit Card issued on Master/Visa/AMEX platform.

The final charging fee, which is based on the time of charging or the fixed amount charged or on per unit electricity consumed or mix of the above at the predetermined price notified to the Customer before charging, will be calculated once the charging Is completed and debited immediately.

The Customer is responsible for payment of the monthly membership fee, if applicable, until membership is terminated. Amplify Mobility has the right to immediately close a Customer's account if the Customer does not pay the membership fee on time. Membership fee has to be paid by the 7th of every month in advance. In the event of payment beyond the due date as mentioned in preceding para, Amplify Mobility reserves the right to charge interest @ SRI Base Rate plus 2% on late payments, and, where relevant, a collection fee.

The Customer Is responsible for complying with the specific parking restrictions and regulations at the charging station.

### Offers from Amplify Mobility’s Partners

By agreeing to these Terms, you consent Amplify Mobility to push offers and marketing material from other organizations to EVRE mobile apps or web dashboards.

Acceptance and performance under those offers are strictly between you and those organizations. Amplify Mobility does not assume responsibility for any performance by these organizations and you agree to look solely to them, and to release EVRE from any liability, with respect to any offers you accept. These organizations conduct business for themselves and are not part of Amplify Mobility’s business. As such, you should always make sure to review their terms of service and privacy policies so that you may better understand what is being offered, what is expected of you and what may be done with any personally identifying information you provide to them.

### Cancellation of the Service

You can cancel your registration as Customer from availing Service by giving a written notice by e-mail specifying reasons at least 15 days in advance from the intended date of cancellation. you will be liable to pay for all transactions made against your registration till the date of cancellation of Service.

### Product prices

Amplify Mobility may offer Its Customers different Services with varying features and prices. The specific terms and conditions referred to In these Terms may only concern specific charging services. The applicable price of the charging service is available at the website [www.evre.in](www.google.com/url?q=http://www.evre.in/&sa=D&source=editors&ust=1657259482273771&usg=AOvVaw33w1z28ROhPIYhQMqK6ILZ) and /or charging stations and /or mobile application. These may vary between different places and also at different points of time. The service price may also be Customer specific. Amplify Mobility reserves the right to change its pricing from time to time.

### General terms and conditions

Information on Customers' purchase transactions is visible in real time on their EVRE accounts, which may be viewed through the EVRE Native App. The period-specific reports of the account may be read and printed at any time. Amplify Mobility retains the charging information for the last 12 months.

### Responsibility for the personal password

When the Customer registers for the Service, Native App will send email with a one-time registration token. Passwords shall be created by the user. The user name and password are used to login and gain access to the account. The Customers, for their part, are responsible for keeping the password secure. It Is recommended not to write down the password lest third parties can access your account. It Is also advised that the password should be used in a way that does not allow others to gain access to the information. The Customer shall immediately notify Amplify Mobility at [support@evre.in](mailto:support@evre.in), if the Customer believes that unauthorized persons have gained access to their account or pined knowledge of their password.

### Unauthorised use of the Service

The Customer Is responsible for any unauthorised use of the Service under their control. The Customer Is advised to notify Amplify Mobility immediately if they believe that their account has been used by an unauthorised person or In an unauthorised manner. If the EVRE RFID card or the mobile phone of the Customer with installed EVRE mobile application is lost or stolen, the Customer shall Immediately 'within two (2) days of becoming aware of the loss or theft of the RFID card or the mobile phone. If the Customer does not give notification of the loss or theft of the EVRE RFID card or the mobile phone within the aforementioned time, they shall be responsible for any reported purchase transactions In full.

### Complaints and investigation of an EVRE purchase transaction

A Customer wishing to submit a complaint regarding Transaction shall notify Amplify Mobility within 15 days of the date on which the purchase transaction in question became available on their account. The complaint shall dearly indicate the type of error in the Transaction. When a Customer submits a complaint, Amplify Mobility shall carry out a technical investigation. Complaints regarding incorrect charging costs are processed and determined by Amplify Mobility, if and when a complaint is accepted. Amplify Mobility shall compensate the Customer for the amount without delay. If the complaint is rejected, Amplify Mobility shall inform the Customer of the result of the Investigation of the complaint and Inform the position of Amplify Mobility. The investigation shall be completed within 90 days from the receipt of written complaint.

### Changes to the Services or Terms:

Both parties are aware that the electric vehicle charging sector is rapidly developing. Amplify Mobility, therefore, reserves the right to modify these terms and conditions in order to adapt the operation of the Service or the Terms to new or modified technologies, devices, standards, legislation, policies, or to appropriate technical, information security, administrative, business, operations', or other relevant procedures. The Customer shall be informed of such changes with notice on the website [www.evre.in](www.google.com/url?q=http://www.evre.in/&sa=D&source=editors&ust=1657259482275320&usg=AOvVaw2FafaS0zHqZwcubm14LvY1). If the Customer does not agree to the changes In the terms and conditions, the Customer has the right to terminate the agreement.

### Termination of membership/Registration

The Customer may terminate their membership by giving one month's notice. In case of monthly subscription, the one month period shall be effective from the end of the current subscription period. The notice of termination shall be sent by e-mail to [support@evre.in](mailto:support@evre.in).

Amplify Mobility has the right to give notice to terminate this agreement In the following cases:

-   The Customer uses the Service contrary to this agreement or to the obligations they have towards Amplify Mobility.
-   The Customer does not meet their payment obligations In case of Invoke based payment or subscription-based payment or membership fee.
-   Amplify Mobility has cause to suspect that the Service is being misused.
-   In all above cases, Amplify Mobility shall give opportunity to Customer to remedy the situation. If within 15 days from the issuance of such remedial notice, Customer does not remedy the situation, Amplify Mobility shall have the right to invoke this clause for termination of Membership.
-   Amplify Mobility has the right to block/prevent use of service/terminate membership instantly in case of criminal cases against customers.

It is the Customer's responsibility to terminate the membership if the Service is not intended to be used, or the vehicle is sold or temporarily out of use, etc. In case of resale of a vehicle, membership shall not automatically be transferred to the new buyer of the vehicles. He/she shall have to register afresh if they desire to avail the Services. Amplify Mobility can terminate this Agreement by providing 30 days’ notice to you without assigning any reason.

### Processing of personal data

For providing better and customised services, we may require certain personal data and these data may fall under sensitive personal data and information (“SPDI”) as defined under Information Technology Act 2000 (IT Act). We comply with applicable laws for processing it and you are hereby giving your specific consent to store it also outside India. We will use our best endeavour to inform you if there is any breach of data security.

### DISCLAIMER OF WARRANTIES; LIMITATION OF LIABILITY

We do not guarantee, represent or warrant that your use of our Service will be uninterrupted, timely, secure Or error-free.

We do not warrant that the results that may be obtained from the use of the Service will be accurate or reliable.

You agree that from time to time we may remove the Service for indefinite periods of time or cancel the Service at any time, without notice to you.

You expressly agree that your use of, or inability to use, the Service is at your sole risk The Service and all products and services delivered to you through the service are (except as expressly stated by us) provided 'as is' and 'as available for your use, without any representation, warranties or conditions of any kind. either express or implied, including all Implied warranties or conditions of merchantability, merchantable quality, fatness for a particular purpose, durability, title, and noninfringement.

In no case shall Amplify Mobility, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental punitive, special or consequential damages of any kind, including, without limitation lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content (or product) posted, transmitted, or otherwise made available via the service, even of advised of their possibility.

### INDEMNIFICATION

You agree to indemnify, defend and hold harmless Amplify Mobility and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand, including reasonable attorney’s fees, made by any third-party due to or arising out of your breach of these Terms of Service or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.

### JURISDICTION AND GOVERNING LAW

All disputes arising from this Agreement shall be governed by Indian laws and Courts at Bengaluru shall have jurisdiction over matters arising out of this Agreement.

### Communications:

Contact us [support@evre.in](mailto:support@evre.in) if you have any questions.

`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "80%",
    margin: "0 auto",
    paddingTop: "130px",
    maxWidth: "850px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "100px 7px 10px",
    },
  },
  ulTag: {
    font: "normal normal 100 18px urbanist",
    wordSpacing: "4px",
    color: "white",
    lineHeight: "1.5",
    paddingLeft: "25px",
    paddingTop: "15px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      paddingLeft: "15px",
    },
  },
  liTag: {
    font: "normal normal 300 18px urbanist",
    wordSpacing: "5px",
    color: "white",
    lineHeight: "1.5",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },

  paraWrapper: {
    width: "95%",
    padding: "30px  0",
    listStyle: "disc",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "4px",
    },
  },
  subTitle: {
    font: "normal normal 600 1.75rem urbanist",
    color: "#00BF5F ",
    padding: "40px  0  0 ",
    maxWidth: "768px",
    textUnderlineOffset: "4px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.250rem",
    },
  },
  link: {
    font: "normal normal normal 1.125rem urbanist",
    color: "#00BF5F ",
    "&:hover": {
      textDecoration: "underline",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  text: {
    font: "normal normal 300 18px urbanist",
    wordSpacing: "4px",

    color: "white",
    lineHeight: "1.5",
    paddingTop: "15px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
    },
  },
  pageTitle: {
    font: "normal normal bolder 5vw urbanist",
    background: "-webkit-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "-moz-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    WebkitBackgroundClip: "text",
    paddingBottom: "20px",
    WebkitTextFillColor: "transparent",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12vw",
      paddingBottom: "0",
    },
  },
}));
export const PrivacyScreen = () => {
  const classes = useStyles();
  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);
  function LinkRenderer(props) {
    return (
      <a
        href={props.href}
        target="_blank"
        rel="noreferrer noopener"
        className={classes.link}
      >
        {props.children}
      </a>
    );
  }
  function HeadingRenderer(props) {
    return <h2 className={classes.pageTitle}>{props.children}</h2>;
  }
  function paraRenderer(props) {
    return <p className={classes.text}>{props.children}</p>;
  }
  function subtitleRenderer(props) {
    return <p className={classes.subTitle}>{props.children}</p>;
  }
  function ulRenderer(props) {
    return <ul className={classes.ulTag}>{props.children}</ul>;
  }
  function liRenderer(props) {
    return <li className={classes.liTag}>{props.children}</li>;
  }

  const MarkdownRenderer = ({ children }) => {
    return (
      <ReactMarkdown
        className={classes.paraWrapper}
        components={{
          a: LinkRenderer,
          h1: HeadingRenderer,
          h3: subtitleRenderer,
          p: paraRenderer,
          span: paraRenderer,
          ul: ulRenderer,
          li: liRenderer,
        }}
        remarkPlugins={[remarkGfm]}
      >
        {children}
      </ReactMarkdown>
    );
  };

  return (
    <>
      <TopBarAll removeCharge="removeCharge" />
      <Box className={classes.root}>
        <MarkdownRenderer>{termsData}</MarkdownRenderer>
      </Box>
      <Footer />
    </>
  );
};
