import React, { useEffect, useRef, useState } from "react";
import {
  makeStyles,
  Box,
  Typography,
  List,
  ListItem,
  Link,
} from "@material-ui/core";
//Router
import { useHistory } from "react-router";
//Aos
import AOS from "aos";
import "aos/dist/aos.css";
import { NavLink } from "react-router-dom";
import { CursorAnimated } from "..";
import { gsap } from "gsap/all";
import { KeyboardArrowRight } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    height: "90vh",
    margin: "0 auto",
    marginTop: "2%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0%",
      width: "90%",
      height: "90vh"
    },
  },
  logo: {
    cursor: "pointer",
    width: "8rem",
    [theme.breakpoints.down("sm")]: {
      width: "5.2rem",
    },
  },
  listBox: {
    width: "70%",
    margin: "0% auto",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "100%",
    },
  },
  mainText: {
    font: "normal normal normal 1.5vw Urbanist",
    display: 'flex',
    justifyContent: "space-between",
    "& svg": {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
        fontSize: "2rem",
        color: "#fff"
      }
    },
    [theme.breakpoints.down("sm")]: {
      font: "normal normal normal 1.5rem Urbanist",
      color: "#fff",
      "& a": {
        // color: "#00BF5F",
      }
    },
    "& a": {
      transition: 'all .4s ease-in-out',
      "&:hover": {
        color: "#00BF5F",
      },
    },
  },
  active: {
    color: "#00BF5F",
  },
  itemText: {
    font: "normal normal lighter 1.2vw Urbanist",
    letterSpacing: '.5px',
    color: "white",
    [theme.breakpoints.down("sm")]: {
      font: "normal normal 100 1.2rem Urbanist",
    },
    "&:hover": {
      transition: 'color .4s ease-in-out',
      color: "#00BF5F",
    },
  },
  navFooterBox: {
    width: "100%",
    margin: "0 auto",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginTop: '10%'
    },
  },
  logos: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: 'center',
    },
  },
  copyright: {
    font: "normal normal normal 1vw Urbanist",
    marginTop: "5%",
    marginBottom: '10%',
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      font: "normal normal normal 1rem Urbanist",
      textAlign: 'center',
      marginLeft: "0%",
      marginBottom: '0%',
    },
  },
  helloBox: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: '20%',
    }
  },
  helloText: {
    font: "normal normal normal 2rem Urbanist",
    opacity: "0.7",
    textAlign: "right",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      font: "normal normal normal 1rem Urbanist",
      display: "none"
    },
  },
  details: {
    font: "normal normal 1.2rem Urbanist",
    marginTop: "5%",
    textAlign: "right",
    opacity: "0.7",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      font: "normal normal normal 4vw Urbanist",
      marginTop: "3%",

    },
  },
  nav: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      padding: "1.2rem 0",
    }
  },
  menuHidden: {
    [theme.breakpoints.down("sm")]: {
      height: "0",
      overflow: "hidden",
    }
  }
}));

export const NavBarButton = () => {
  const classes = useStyles();
  const history = useHistory();

  const [isOpen, setIsopen] = useState(false)

  let menuOne = useRef(null)
  let arrowOne = useRef(null)

  let menuTwo = useRef(null)
  let arrowTwo = useRef(null)

  const handleClick = (refs, arrowrefs, dropHeight) => {
    if (isOpen == false) {
      gsap.to(refs, { height: `${dropHeight}px` })
      gsap.to(arrowrefs, { rotation: 90 })
      setIsopen(true)
    }
    else {
      gsap.to(refs, { height: "0px" })
      gsap.to(arrowrefs, { rotation: 0 })

      setIsopen(false)
    }
  }

  const animation = () => {
    AOS.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  };

  useEffect(() => {
    animation();
  }, []);
  return (
    <Box className={classes.root}>
      <Box className={classes.nav}>
        <img
          className={classes.logo}
          src={"./images/Logo/Logo.svg"}
          alt="logo"
          onClick={() => history.push("/home")}
        />
        <img
          style={{ cursor: "pointer", width: "1.5rem" }}
          src={"./images/Logo/cross.svg"}
          alt="logo"
          onClick={() => history.goBack()}
        // data-aos="fade-up"
        />
      </Box>

      <Box className={classes.listBox}>
        <List className={classes.lists}>
          <ListItem
            data-aos="fade-up"
            className={classes.mainText}
            onClick={() => handleClick(menuOne, arrowOne, 300)}
          >
            <NavLink to="/infinity" activeClassName={classes.active} >Products</NavLink>
            <KeyboardArrowRight ref={(e) => { arrowOne = e }} />
          </ListItem>

          <Box
            className={classes.menuHidden}
            ref={(e) => { menuOne = e }}
          >
            <ListItem
              data-aos="fade-up"
              className={classes.itemText}
              onClick={() => history.push("/infinityproduct")}
            >
              Infinity
            </ListItem>
            <ListItem
              data-aos="fade-up"
              className={classes.itemText}
              onClick={() => history.push("/podproduct")}
            >
              Halo
            </ListItem>
            <ListItem
              data-aos="fade-up"
              className={classes.itemText}
              onClick={() => history.push("/ac001product")}
            >
              AC-001
            </ListItem>
            <ListItem
              data-aos="fade-up"
              className={classes.itemText}
              onClick={() => history.push("/intellipoleproduct")}
            >
              Intellipole
            </ListItem>
            <ListItem
              data-aos="fade-up"
              className={classes.itemText}
              onClick={() => history.push("/upcomming")}
            >
              Upcoming Products
            </ListItem>
            <ListItem
              data-aos="fade-up"
              className={classes.itemText}
              onClick={() => history.push("/solution")}
            >
              Solutions
            </ListItem>
            {/* <ListItem
              data-aos="fade-up"
              className={classes.itemText}
              onClick={() => history.push("/becomeadealer")}
            >
              Become a Dealer
            </ListItem> */}
          </Box>
        </List>
        <List className={classes.lists}>
          <ListItem
            data-aos="fade-up"
            ref={(e) => menuTwo = e}
            onClick={() => handleClick(menuTwo, arrowTwo, 130)}
            className={classes.mainText} >
            <NavLink to="/evreproduct" activeClassName={classes.active}>
              Charge with EVRE
            </NavLink>
            <KeyboardArrowRight ref={(e) => { arrowTwo = e }} />
          </ListItem>

          {/* <ListItem
            data-aos="fade-up"
            className={classes.itemText}
            onClick={() => history.push("/evreproduct")}
          >
            Home
          </ListItem> */}
          {/*<ListItem data-aos="fade-up"
            className={classes.itemText}
            onClick={() => history.push("/home")}
          >
            Home
           </ListItem>*/}

          <Box className={classes.menuHidden} ref={(e) => menuTwo = e}>
            <ListItem
              data-aos="fade-up"
              className={classes.itemText}
              onClick={() => history.push("/aboutevre")}
            >
              EVRE Mobile App
            </ListItem>
            <ListItem
              data-aos="fade-up"
              className={classes.itemText}
              onClick={() => history.push("/solution")}
            >
              Solutions
            </ListItem>
            {/* <ListItem
              data-aos="fade-up"
              className={classes.itemText}
              onClick={() => history.push("/franchise")}
            >
              Own a Franchise
            </ListItem> */}
          </Box>
          {/*<ListItem data-aos="fade-up" className={classes.itemText}>Locate a Charger</ListItem>*/}
        </List>
        <List className={classes.lists}>
          <ListItem
            data-aos="fade-up"
            className={classes.mainText}
            onClick={() => history.push("/aboutus")}
          >

            <NavLink to="/infinity" activeClassName={classes.active}>About Us</NavLink>

          </ListItem>
          <br />
          <ListItem
            data-aos="fade-up"
            className={classes.mainText}
            onClick={() => history.push("/switch")}
          >

            <NavLink to="/infinity" activeClassName={classes.active}>Why make the Switch</NavLink>

          </ListItem>
          <br />
          <ListItem
            data-aos="fade-up"
            className={classes.mainText}
            onClick={() => history.push("/contact")}
          >

            <NavLink to="/infinity" activeClassName={classes.active}>Contact Us</NavLink>

          </ListItem>
        </List>
      </Box>

      <Box className={classes.navFooterBox} >
        <Box className={classes.logoBox}>
          <Box className={classes.logos}>
            <Link
              data-aos="fade-up"
              href="https://www.linkedin.com/company/evre"
              color="inherit"
              target="_blank"
            >
              <i class="fa fa-linkedin faicon"></i>
            </Link>
            <Link
              data-aos="fade-up"
              href="https://www.instagram.com/evre.in/"
              color="inherit"
              target="_blank"
            >
              <i className="fa fa-instagram faicon"></i>
            </Link>
            <Link
              data-aos="fade-up"
              href="https://twitter.com/EVRE_in"
              color="inherit"
              target="_blank"
            >
              <i className="fa fa-twitter faicon"></i>
            </Link>
            <Link
              data-aos="fade-up"
              href="https://www.facebook.com/evre.io"
              color="inherit"
              target="_blank"
            >
              <i className="fa fa-facebook-f faicon"></i>
            </Link>



          </Box>
          <Typography className={classes.copyright}>
            © 2023 Amplify Cleantech Solutions Private Limited. All Rights Reserved.
          </Typography>
        </Box>

        <Box className={classes.helloBox}>
          <Typography className={classes.helloText} >
            Say Hello !
          </Typography>
          <Typography className={classes.details}>
            +91 90524 90524 | hello@evre.in
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
