import React, { useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
//Router
import { useHistory } from "react-router";
//Aos
import AOS from 'aos';
import 'aos/dist/aos.css';


//Style
const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    margin: "auto",
    marginTop: "12%",
    display: "flex",
    alignItems:"center",
    [theme.breakpoints.down("sm")]: {
      marginTop: '12%',
      width:"90%",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: '20%',
    },
  },
  HomeNavigation: {
    cursor: "pointer",
    font: 'normal normal 300 1vw Urbanist',
    [theme.breakpoints.down('sm')]: {
      fontSize: '4vw',
    }
  },
    Current: {
      font: 'normal normal 300 1vw Urbanist ',
      color: "#00BF5F ",
      [theme.breakpoints.down('sm')]: {
        fontSize: '4vw',
      }
    },
    arrow:{
      font: 'normal normal 300 1vw Urbanist',
      // verticalAlign:"middle",
      paddingTop:"3px",

      [theme.breakpoints.down('sm')]: {
        paddingTop:"10px",
        fontSize: '4vw',
      },
      [theme.breakpoints.down('xs')]: {
        paddingTop:"5px",
        fontSize: '4vw',
      }
    }
  }));

export const HomeNavigation = ({ name }) => {
  const classes = useStyles();
  const history = useHistory();

  const animation = () => {
    AOS.init({
      easing: 'ease-out-back',
      duration: 1000,
    });
  }

  useEffect(() => {
    animation()
  }, [])
  return (
    <Box className={classes.root}>
      <p
        style={{
          color: "#fff",
          font: "normal normal normal 1rem Urbanist",
        }}
        data-aos="fade-right"
      >
        <span className={classes.HomeNavigation} onClick={() => history.push("/home")} data-aos="fade-right">
          Home
        </span>
        &nbsp;
        <ArrowForward className={classes.arrow} data-aos="fade-right" />
        &nbsp;  
        <span className={classes.Current} data-aos="fade-right">{name}</span>
      </p>
    </Box>
  );
};
