import React, { useEffect, useRef, useState } from "react";
import { Typography, Box, makeStyles, Grid, Link } from "@material-ui/core";
//Router
import { useHistory } from "react-router";
import { FooterBarScroll } from "./FooterBarScroll";
//Aos
import AOS from "aos";
import "aos/dist/aos.css";
import { useStyles } from "./FooterStyles";
import { gsap } from "gsap/all";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";

export const Footer = () => {
  const classes = useStyles();
  const history = useHistory();
  const [isOpen, setIsopen] = useState(false);

  let menuOne = useRef(null);
  let arrowOne = useRef(null);

  let menuTwo = useRef(null);
  let arrowTwo = useRef(null);

  const handleClick = (refs, arrowrefs) => {
    if (isOpen === false) {
      gsap.to(refs, { height: "180px" });
      gsap.to(arrowrefs, { rotation: 90 });
      setIsopen(true);
    } else {
      gsap.to(refs, { height: "0" });
      gsap.to(arrowrefs, { rotation: 0 });

      setIsopen(false);
    }
  };

  const animation = () => {
    AOS.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  };

  useEffect(() => {
    // Refresh()
    animation();
  }, []);
  return (
    <Box className={classes.root}>
      <Box className={classes.containerFlex}>
        <input
          className={classes.newslatter}
          placeholder="Newsletter"
          data-aos="fade-up"
        />
        <p className={classes.subscribe} data-aos="fade-up">
          Subscribe
        </p>
      </Box>

      <hr style={{ border: "1px solid #747474" }} data-aos="fade-up" className={classes.formBorder} />

      <Box className={classes.gridBox}>
        <Grid xs={10}>
          <Typography className={classes.heading} data-aos="fade-up">
            Get in touch
          </Typography>
          <Typography className={classes.infoText} data-aos="fade-up">
            <a href="tel:+919052490524">+91 90524 90524</a> |{" "}
            <a href="mailto:hello@evre.in">hello@evre.in</a>
          </Typography>
        </Grid>

        <Grid container className={classes.list}>
          <Grid item xs={12} sm={12} md={3}>
            <Typography
              className={classes.Heads}
              onClick={() => handleClick(menuOne, arrowOne)}
              data-aos="fade-up"
            >
              <span
                onClick={() => history.push("/infinity")}
                style={{ cursor: "pointer" }}
              >
                Products
              </span>
              <KeyboardArrowRight
                ref={(e) => {
                  arrowOne = e;
                }}
              />
            </Typography>
            <Box ref={(e) => (menuOne = e)} className={classes.menuHidden}>
              <Typography
                className={classes.linkText}
                onClick={() => history.push("/Infinity")}
              >
                All Products
              </Typography>
              <Typography
                className={classes.linkText}
                onClick={() => history.push("/solution")}
              >
                Solutions
              </Typography>
              {/* <Typography
                className={classes.linkText}
                onClick={() => history.push('/becomeadealer')}
              >
                Become a Dealer
              </Typography> */}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <Typography
              className={classes.Heads}
              ref={(e) => (menuTwo = e)}
              onClick={() => handleClick(menuTwo, arrowTwo)}
              data-aos="fade-up"
            >
              <span
                onClick={() => history.push("/evreproduct")}
                style={{ cursor: "pointer" }}
              >
                Charge with EVRE
              </span>
              <KeyboardArrowRight
                ref={(e) => {
                  arrowTwo = e;
                }}
              />
            </Typography>

            <Box className={classes.menuHidden} ref={(e) => (menuTwo = e)}>
              <Typography
                className={classes.linkText}
                onClick={() => history.push("/aboutevre")}
              >
                EVRE Mobile App
              </Typography>
              <Typography
                className={classes.linkText}
                onClick={() => history.push("/solution")}
              >
                Solutions
              </Typography>
              {/* <Typography
                className={classes.linkText}
                onClick={() => history.push("/franchise")}
              >
                Own a Franchise
              </Typography> */}

              {/* <Typography
                    className={classes.linkText}
                    onClick={() => history.push("/")}
                    data-aos="fade-up">
                    Home
                  </Typography> */}
              {/* <Typography className={classes.linkText} data-aos="fade-up">
                    Locate a Charger
                  </Typography> */}
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={3}>
            <Typography
              className={classes.Heads}
              onClick={() => history.push("/aboutus")}
              data-aos="fade-up"
            >
              About Us
            </Typography>
            <Typography
              className={classes.Heads}
              onClick={() => history.push("/switch")}
              data-aos="fade-up"
            >
              Why make the switch?
            </Typography>
            <Typography
              className={classes.Heads}
              onClick={() => history.push("/contact")}
              data-aos="fade-up"
            >
              Contact Us
            </Typography>
          </Grid>
        </Grid>
        <Box>
          <Box className={classes.iconBox}>
            <Link
              href="https://www.linkedin.com/company/evre"
              color="inherit"
              target="_blank"
              data-aos="fade-up"
            >
              <i class="fa fa-linkedin faicon"></i>
            </Link>

            <Link
              href="https://www.instagram.com/evre.in/"
              color="inherit"
              target="_blank"
              data-aos="fade-up"
            >
              <i className="fa fa-instagram faicon"></i>
            </Link>

            <Link
              href="https://twitter.com/EVRE_in"
              color="inherit"
              target="_blank"
              data-aos="fade-up"
            >
              <i className="fa fa-twitter faicon"></i>
            </Link>

            <Link
              href="https://www.facebook.com/evre.io"
              color="inherit"
              target="_blank"
              data-aos="fade-up"
            >
              <i className="fa fa-facebook-f faicon"></i>
            </Link>
          </Box>
          <Box className={classes.textBox} data-aos="fade-up">
            <Typography className={classes.text}>Terms</Typography>

            <Typography
              className={classes.linkText}
              onClick={() => history.push("/privacy-policy")}
            >
              Privacy Policy
            </Typography>
            <Typography className={classes.text}>Cookie Policy</Typography>
          </Box>
        </Box>
      </Box>

      <FooterBarScroll />

      <Box className={classes.bottomOfFooter}>
        <Typography className={classes.copyright} data-aos="fade-up">
          © 2023 Amplify Cleantech Solutions Private Limited. All Rights
          Reserved.
        </Typography>
        <Box className={classes.bgImage}>
          <img
            src={"./images/Footer/Footer.svg"}
            alt="footer"
            data-aos="fade-up"
          />
        </Box>
      </Box>
    </Box>
  );
};
