import React from "react";
import { makeStyles, Container, Box, Typography } from "@material-ui/core";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { useHistory } from "react-router";
import { ViewDetails } from "../../Atom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "10%",
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      padding: "0px",
      "& vertical": {
        width: "100%",
      },
      "& .vertical-timeline": {
        width: "100%",
      },
      "& .vertical-timeline::before": {
        width: "2px",
        left: "0",
        height: "85%",
      },
    },
  },
  textBox: {
    font: "normal normal bolder 2.4vw Urbanist",
    marginTop: 10,
    background: "#006934",
    background: "-webkit-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "-moz-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    [theme.breakpoints.down("md")]: {
      fontSize: "2.8vw",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
  },
  textPara: {
    font: "normal normal lighter 1.8vw Urbanist",
    lineHeight: "1.4",
    [theme.breakpoints.down("sm")]: {
      fontSize: "5vw",
    },
    "& p": {},
  },
}));

export const VerticalComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Box className={classes.root}>
      <VerticalTimeline>
        <VerticalTimelineElement
          contentStyle={{ background: "transparent" }}
          position={"right"}
        >
          <Box>
            <Box>
              <img
                src={"./images/evre/firstVertical.png"}
                alt="first-vertical"
                style={{ width: "100%" }}
              />
            </Box>
            <h1 className={classes.textBox}>Residential</h1>
            <h6 className={classes.textPara}>
              Leverage the unprecedented chance to lead towards a sustainable
              future.
            </h6>
            <Box style={{ float: "right", cursor: "pointer" }}>
              <ViewDetails title="view details" route="residential" />
            </Box>
          </Box>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "transparent" }}
          position={"left"}
        >
          {/* <Box className={classes.containerBox}>
            <img
              src={"./images/ProductCommonImages/center.png"}
              alt="first-vertical"
              style={{ width: "100%", }}
              className={classes.image}
            />
            <h1 className={classes.textBox} >Commercial</h1>
            <h6 className={classes.textPara} >
              Capitalize on the EV Revolution while expanding your business
              revenue.
            </h6>
            <Box style={{ float: "right", cursor: "pointer" }}>
              <ViewDetails
                title="view details"
                route="commercial"
              />
            </Box>
          </Box> */}
          <Box className={classes.containerBox}>
            <img
              src={"./images/ProductCommonImages/center.png"}
              alt="first-vertical"
              style={{ width: "100%" }}
              className={classes.image}
            />
            <h1 className={classes.textBox}>Logistics and Fleet Operators</h1>
            <h6 className={classes.textPara}>
              We are building India’s largest and most advanced EV Charging
              network for logistics and fleet operators.
            </h6>
            <Box style={{ float: "right", cursor: "pointer" }}>
              <ViewDetails title="view details" route="logistics" />
            </Box>
          </Box>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "transparent" }}
          position={"right"}
        >
          <Box className={classes.containerBox}>
            <img
              src={"./images/Solutions/Center2.png"}
              alt="first-vertical"
              style={{ width: "100%" }}
              className={classes.image}
            />
            <h1 className={classes.textBox}>Business</h1>
            <h6 className={classes.textPara}>
              Build a sustainable future hand-in-hand with EVRE.
            </h6>
            <Box style={{ float: "right", cursor: "pointer" }}>
              <ViewDetails title="view details" route="business" />
            </Box>
          </Box>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          contentStyle={{ background: "transparent" }}
          position={"left"}
        >
          <Box className={classes.containerBox}>
            <img
              src={"./images/Solutions/Right2.png"}
              alt="first-vertical"
              style={{ width: "100%" }}
              className={classes.image}
            />
            <h1 className={classes.textBox}>Government</h1>

            <h6 className={classes.textPara}>
              Make EV Charging ubiquitous by helping us build India’s largest EV
              Charging network.
            </h6>
            <Box style={{ float: "right", cursor: "pointer" }}>
              <ViewDetails title="view details" route="government" />
            </Box>
          </Box>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </Box>
  );
};
