import React from "react";
//Material ui
import { Box, makeStyles } from "@material-ui/core";

//Style
const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "5%",
        marginBottom: "10%",
        [theme.breakpoints.down("sm")]: {
            margin: "15% 0%",
        },
    },
    mainBox: {
        width: "100%",
        margin: "0 auto",
        marginLeft: "0%",
    },
    electric: {
        font: "normal normal bolder 15vw Urbanist",
        opacity: ".1",
        [theme.breakpoints.down("sm")]: {
            fontSize: "3rem",
        },
    },
    electricPara: {
        font: "normal normal 300 5.5vw Urbanist",
        width:"80%",
        margin:"0 auto",
        [theme.breakpoints.down("sm")]: {
            fontSize: "8vw",
            textAlign: 'center'
        },
    },
    Vehicles: {
        font: "normal normal bolder 15vw Urbanist",
        opacity: ".1",
        [theme.breakpoints.down("sm")]: {
            fontSize: "3rem",
            marginLeft: "-5%",
        },
    },
}));

export const Knowledge = ({ title, image, para }) => {
    const classes = useStyles();
    return (
        <Box className={classes.root}>

            {/* <Box className={classes.mainBox}>
                <h1 className={classes.electric}>Knowledge</h1>
            </Box> */}
            <Box className={classes.mainBox}>
                <marquee behavior="scroll" direction="right" scrollamount="20" className={classes.electric}>Knowledge</marquee>
            </Box>

            <h3 className={classes.electricPara}>
                Let’s drive the change together. Let’s start with creating awareness.
            </h3>
            {/* <Box className={classes.mainBox}>
                <h1 className={classes.Vehicles}>Resources</h1>
            </Box> */}
            <Box className={classes.mainBox}>
        <marquee behavior="scroll" direction="left" scrollamount="20" className={classes.Vehicles}>Resources</marquee>
      </Box>

        </Box>
    );
};
