import React, { useState, useEffect } from "react";
import { useWindowScroll } from "react-use";
import { makeStyles, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  scroll: {
    transform: "rotate(270deg)",
    font: "normal normal lighter 1rem Urbanist",
    cursor: "pointer",
    [theme.breakpoints.down('sm')]: {
      fontSize: "1rem",
    }
  },
  arrowAlign: {
    marginLeft: 50,
    marginTop: 35,
    transform: "rotate(270deg)",
    cursor: "pointer",
  },
  barAlign: {
    position: "fixed",
    bottom: "30%",
    right: "0px",
    zIndex: "2",
    [theme.breakpoints.down('sm')]: {
      display:'none'
    }
  },
}));

export const FooterBarScroll = () => {
  const classes = useStyles();

  const { y: pageYOffset } = useWindowScroll();
  const [visible, setVisiblity] = useState(false);

  useEffect(() => {
    if (pageYOffset > 800) {
      setVisiblity(true);
    } else {
      setVisiblity(false);
    }
  }, [pageYOffset]);
  if (!visible) {
    return false;
  }

  const handleScroll = () => {
    window.scroll({ top: 0, behavior: "smooth" });
  };

  return (
    <Box className={classes.barAlign} onClick={handleScroll}>
      <img
        src={"./images/Arrow/Arrow.svg"}
        className={classes.arrowAlign}
        alt="Arrow"
        width="60"
      />
      <h1 className={classes.scroll}>Move to top</h1>
    </Box>
  );
};
