import React, { useEffect, useState } from "react";
//Material ui
import { Grid, makeStyles, Box } from "@material-ui/core";
//Material Icon
import {
  FontDownloadOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@material-ui/icons";
//Aos
import AOS from "aos";
import "aos/dist/aos.css";

const useStyles = makeStyles((theme) => ({
  GridContainer: {
    width: "65%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  gridBox: {
    padding: "2%",
    [theme.breakpoints.down("sm")]: {
      padding: "0%",
      marginBottom: "10%",
    },
  },
  HeadTitle: {
    font: "normal normal normal 2vw urbanist",
    color: "#00BF5F ",
    [theme.breakpoints.down("sm")]: {
      fontSize: "8vw",
    },
  },
  subtitlePara: {
    font: "normal normal 100 1.12vw urbanist",
    color: "white",
    lineHeight: "1.4",
    marginTop: "5%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "6vw",
    },
  },
}));

export const ProductGridOne = ({ ...mapData }) => {
  const classes = useStyles();

  const [visible, setVisible] = useState(4);

  const animation = () => {
    AOS.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  };

  useEffect(() => {
    animation();
  }, []);
  return (
    <>
      <Grid container className={classes.GridContainer}>
        {mapData.mapData.slice(0, visible).map((item, index) => {
          const { title, subtitle } = item;
          return (
            <Grid
              className={classes.gridBox}
              item
              xs={12}
              sm={12}
              md={6}
              key={index}
            >
              <h1 className={classes.HeadTitle} data-aos="fade-up">
                {title}
              </h1>
              <p className={classes.subtitlePara} data-aos="fade-up">
                {subtitle}
              </p>
            </Grid>
          );
        })}
      </Grid>

      {visible == 4 ? (
        <Box style={{ position: "relative" }}>
          <h1
            style={{
              font: "normal normal normal 1.5rem Urbanist",
              textAlign: "center",
              cursor: "pointer",
              marginTop: "2rem",
            }}
            onClick={() => setVisible(visible + mapData.mapData.length)}
            data-aos="fade-up"
          >
            Read More
            <KeyboardArrowDown
              fontSize="large"
              style={{ position: "absolute", top: "-1px" }}
            />
          </h1>
        </Box>
      ) : (
        <Box style={{ position: "relative" }}>
          <h1
            style={{
              font: "normal normal normal 1.5rem Urbanist",
              textAlign: "center",
              cursor: "pointer",
              position: "relative",
            }}
            onClick={() => setVisible(4)}
            data-aos="fade-down"
          >
            Read Less
            <span>
              <KeyboardArrowUp
                fontSize="large"
                style={{ position: "absolute", top: "-1px" }}
              />
            </span>
          </h1>
        </Box>
      )}
    </>
  );
};
