import React from "react";
//Material ui
import { Box, makeStyles } from "@material-ui/core";
//Slider
import Slider from "react-slick";

//Style
const useStyles = makeStyles((theme) => ({
  root: {
    width: "60%",
    margin: "0 auto",
    position: "relative",
    paddingTop: '13%',
    marginBottom: '10%',
    cursor: 'pointer',
    [theme.breakpoints.down("sm")]: {
      marginTop: '0%',
      width: "95%",
    },
  },
  centerBox: {
    width: "100%",
    height: "100%",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  head: {
    textAlign: "center",
    font: "normal normal bolder 10vw Urbanist",
    background: "#006934",
    background: "-webkit-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "-moz-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    [theme.breakpoints.down("sm")]: {
      fontSize: "15vw",
    },
  },
  imageCenter: {
    width: "20%",
    // position: "absolute",
    // top: "50%",
    // left: "50%",
    // transform: "translate(-50%)",
    [theme.breakpoints.down("sm")]: {
      width: "30%",
    },
  },
  imageCenter1: {
    width: "12%",
    // position: "absolute",
    // top: "25%",
    // left: "50%",
    // transform: "translate(-50%)",
    [theme.breakpoints.down("sm")]: {
      width: "30%",
    },
  },
  paraLeft: {
    width: "80%",
    font: "normal normal normal 1.5rem Urbanist",
    lineHeight: "1.5",
    margin: "0% auto",
    marginTop: '1%',
    // marginBottom: '5%',
    textAlign: 'center',
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      // margin: "5% auto",
      fontSize: "6vw",
      float: "none",
    },
  },
}));

export const BottomCenterProduct = ({ data }) => {
  const classes = useStyles();
  const Settings = {
    arrows: false,
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  return (
    <Box className={classes.root}>
      <Slider {...Settings}>

        {
          data.map((item, index) => {
            const { title, image, para, image1 } = item
            return (
              <Box>
                <Box className={classes.centerBox}>
                  <h1 className={classes.head}>{title}</h1>
                  {image ?
                    <img className={classes.imageCenter} src={image} alt="Images" /> :
                    <img className={classes.imageCenter1} src={image1} alt="Images" />
                  }
                </Box>
                <Box className={classes.flexBox}>
                  <h1 className={classes.paraLeft}>{para}</h1>
                </Box>
              </Box>
            )
          })
        }

      </Slider>
    </Box>
  );
};
