import React, { useEffect } from 'react'
//Material ui
import { Box, makeStyles } from "@material-ui/core";
//Aos
import AOS from 'aos';
import 'aos/dist/aos.css';


const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    height: "100%",
    margin: "0 auto",
    marginTop: "15%",
    marginBottom: "10%",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  text: {
    font: "normal normal bolder 12vw Urbanist",
    background: "#006934",
    background: "-webkit-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "-moz-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3rem",
    },
  },
}));

export const ProductSpecification = ({ title }) => {
  const classes = useStyles();

  const animation = () => {
    AOS.init({
      easing: 'ease-out-back',
      duration: 1000,
    });
  }

  useEffect(() => {
    animation()
  }, [])
  return (
    <Box className={classes.root}>
      <h1 variant="h1" className={classes.text} data-aos="fade-up">
        {title}
      </h1>
    </Box>
  );
};
