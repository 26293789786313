import React, { useEffect } from "react";
import {
  Container,
  Box,
  makeStyles,
  Typography,
  Link,
} from "@material-ui/core";
import {
  BottomBarScroll,
  EvreBottomBar,
  VerticalComponent,
} from "../../Molecules";
import { Intro } from "./Intro";
import { TwoPara } from "./TwoPara";
import { TwoImg } from "./TwoImg";
import { AboutEVRE } from "./AboutEVRE";
import { ViewDetails } from "../../Atom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    color: "#ffffff",
    overflow: "hidden",
    marginTop: "10%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      color: "#ffffff",
    },
    "& br": {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  },
  container: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  heading: {
    font: "normal normal bolder 5rem Urbanist",
    textAlign: "center",
    background: "#006934",
    background: "-webkit-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "-moz-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    [theme.breakpoints.down("md")]: {
      fontSize: "10rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
  },
  vertical: {
    width: "90%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginTop: 10,
    },
  },
  headingAlign: {
    marginTop: "20%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  disruptBox: {
    width: "100%",
    margin: "8% auto",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "2% auto",
    },
  },
  disruptText: {
    fontFamily: "Urbanist",
    fontSize: "18.75rem",
    color: "#1A1A1A",
    opacity: "0.7",
    fontWeight: "600",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontFamily: "Urbanist",
      fontSize: "6.5rem",
      color: "#1A1A1A",
      opacity: "0.9",
      fontWeight: "600",
      textAlign: "center",
    },
  },
  disruptParaText: {
    fontFamily: "Urbanist",
    fontSize: "3.75rem",
    position: "absolute",
    fontWeight: "500",
    top: "27%",
    left: "13%",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      fontFamily: "Urbanist",
      fontSize: "1.2rem",
      top: "16%",
      left: "20%",
    },
  },
  semiHeading: {
    marginTop: "12%",
    marginLeft: "30%",
    width: "70%",
    [theme.breakpoints.down("sm")]: {
      margin: "15% auto",
      width: "90%",
    },
  },
  semiHeadingText: {
    font: "normal normal 2.3rem Urbanist",
    fontWeight: "500",
    [theme.breakpoints.down("sm")]: {
      font: "normal normal 7vw Urbanist",
      marginTop: "4%",
      marginBottom: "8%",
    },
  },
  semipara: {
    font: "normal normal 200 1.8rem Urbanist",
    width: "85%",
    marginTop: "8%",
    lineHeight: "1.5",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
      margin: "0 auto",
      width: "100%",
    },
  },
  exploreBox: {
    marginTop: "5%",
    marginLeft: "80%",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      marginTop: 10,
    },
  },
  exploreArrow: {
    fontFamily: "Gilroy",
  },

  containerBox: {
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  roots: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    position: "relative",
    color: "#ffffff",
    marginTop: "10%",
  },
  mobile: {
    width: "75%",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  mobilepara: {
    font: "normal normal lighter 1.8rem Urbanist",
    lineHeight: "1.8",
    marginLeft: "40%",
    margin: "2% 0%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      marginLeft: "10%",
      font: "normal normal lighter 0.9rem Urbanist",
      marginTop: "-4%",
    },
  },
  evreText: {
    font: "normal normal bolder 26rem Urbanist",
    textAlign: "center",
    background: "#006934",
    paddingTop: "20%",
    background: "-webkit-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "-moz-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    [theme.breakpoints.down("md")]: {
      fontSize: "26rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "35vw",
    },
  },

  BoxImage: {
    display: "flex",
    position: "absolute",
    width: "80%",
    left: "38%",
    bottom: "50%",
    top: "5%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      position: "absolute",
      width: "30%",
      left: "37%",
      bottom: "70%",
    },
  },

  mobileParaBox: {
    width: "100%",
  },

  appQrBox: {
    marginTop: "5%",
    display: "flex",
    flexDirection: "row",
    width: "35%",
    marginLeft: "50%",
    // float: "right",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "row",
      width: "50%",
      marginLeft: "0%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "70%",
      marginLeft: "0%",
    },
  },
  qrBoxAlign: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      marginTop: "5%",
    },
    "& img": {
      cursor: "pointer",
    },
  },
  QRBox: {
    width: "80%",
    [theme.breakpoints.down("sm")]: {
      margin: "0% auto",
    },
  },
  iconBox: {
    display: "flex",
    marginTop: "3%",
    justifyContent: "space-between",
    "& a": {
      width: "48%",
    },
  },
}));

export const EvreProductTemplate = ({ l1id, l2id, l3id, l4id }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Intro />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <BottomBarScroll />

      <TwoPara />

      <div id={l1id}>
        <AboutEVRE />
      </div>

      <Container className={classes.container}>
        <Box className={classes.headingAlign}>
          <div id={l2id}>
            <Typography className={classes.heading}>
              Capitalize on the most disruptive revolution of the 21
              <sup>st</sup>
              Century!
            </Typography>
          </div>
        </Box>

        <Box className={classes.vertical}>
          <VerticalComponent />
        </Box>

        <div id={l3id}>
          <Box className={classes.disruptBox}>
            <marquee
              behavior="scroll"
              scrollamount="20"
              className={classes.disruptText}
            >
              Disrupt
            </marquee>
            <Typography className={classes.disruptParaText}>
              With 21st Century’s most promising business models.
            </Typography>
            <Box className={classes.semiHeading}>
              <Typography className={classes.semiHeadingText}>
                Help us build India’s largest EV Charging Network
              </Typography>
              <Typography className={classes.semipara}>
                The future is electric and we are looking for businesses that
                can help us power it. The EV Revolution has flipped the status
                quo for traditional means of transport. The future looks bold
                where vehicles don’t refill but recharge.
              </Typography>
              <Typography className={classes.semipara}>
                We are looking for franchise partners that can help us build a
                100% EV Compliant India. Join us as we dominate the market with
                disruptive EV Charging Solutions.
              </Typography>
              <Box className={classes.exploreBox}>
                <ViewDetails title="view details" />
              </Box>
            </Box>
          </Box>
        </div>

        <div id={l4id}>
          <Box className={classes.roots}>
            <Typography className={classes.evreText}>EVRE</Typography>
            <Box className={classes.BoxImage}>
              <Box>
                <img
                  className={classes.mobile}
                  src={"../images/evre/App.png"}
                  alt="mobile-app"
                />
              </Box>
            </Box>

            <Box className={classes.mobileParaBox}>
              <Typography className={classes.mobilepara}>
                Plug into the EVRE Mobile App to access our network of EV
                Charging stations across India.
              </Typography>

              <Box className={classes.QRBox}>
                <Box className={classes.appQrBox}>
                  <Box className={classes.qrBoxAlign}>
                    <img
                      src={"./images/evre/qr-code-for-both.png"}
                      alt="appleqr"
                      style={{ width: "100%" }}
                    />
                    <Box className={classes.iconBox}>
                      <a
                        href="https://apps.apple.com/us/app/evre-by-amplify-mobility/id1547215872?platform=iphone"
                        target="_blank"
                      >
                        <img
                          src={"./images/evre/apple.svg"}
                          alt="apple"
                          style={{ width: "100%", marginTop: 10 }}
                        />
                      </a>
                      <a
                        href="https://play.google.com/store/apps/details?id=com.slscorp.amplify.evre"
                        target="_blank"
                      >
                        <img
                          src={"./images/evre/googleplay.svg"}
                          alt="playstore"
                          style={{ width: "100%", marginTop: 10 }}
                        />
                      </a>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </div>
      </Container>
    </Box>
  );
};
