import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "60%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  head: {
    font: "normal normal bolder 8rem Urbanist",
    marginBottom: "10%",
    background: "#006934",
    background: "-webkit-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "-moz-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10vw",
    },
  },
  boxOne: {
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop:"2rem",
    },
  },
  boxtwo: {
    width: "50%",
    marginLeft: "70%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: "0%",
      marginTop: 10,
    },
  },
  titleText: {
    font: "normal normal normal 3vw Urbanist",
    [theme.breakpoints.down("sm")]: {
      font: "normal normal  normal 6vw Urbanist",
    },
  },
  paraText: {
    font: "normal normal  lighter 1.2rem Urbanist",
    lineHeight: "1.5",
    width: "70%",
    marginTop: 10,
    [theme.breakpoints.down("sm")]: {
      font: "normal normal  lighter 4vw Urbanist",
      width: "80%",
    },
  },
}));

export const Why = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root} data-aos="fade-up">
      <h1 className={classes.head} data-aos="fade-up">Why EVRE?</h1>
      <Box className={classes.boxOne}>
        <Typography className={classes.titleText} data-aos="fade-up">
          Technology and Design First Solutions
        </Typography>
        <Typography className={classes.paraText} data-aos="fade-up">
          Own a dealership that offers competitive advantage as part of the
          package. Our solutions stand apart from the herd of existing EV
          charging solutions both in terms of technology and design.
        </Typography>
      </Box>
      <Box className={classes.boxtwo} >
        <Typography className={classes.titleText} data-aos="fade-up">Partnerships</Typography>
        <Typography className={classes.paraText} data-aos="fade-up">
          We are aggressively working towards building an EV-First India and
          have partnered with leading brands to capture a good share in the
          market.
        </Typography>
      </Box>
      <Box className={classes.boxOne} >
        <Typography className={classes.titleText} data-aos="fade-up">Network</Typography>
        <Typography className={classes.paraText} data-aos="fade-up">
          We have commissioned our products across 9 major cities in India and
          are expanding by the day. Partnering with us gives you an already
          established name in the market.
        </Typography>
      </Box>
      <Box className={classes.boxtwo} >
        <Typography className={classes.titleText} data-aos="fade-up">Interoperability</Typography>
        <Typography className={classes.paraText} data-aos="fade-up">
          Interoperability is the key for building a sustainable and meaningful
          EV Charging Infra. Our infra can be integrated with any businesses’
          existing framework making it a desirable solution.
        </Typography>
      </Box>
    </Box>
  );
};
