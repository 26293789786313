import React from "react";
import { TopBar, Footer, ScrollTop, SolutionsTabBar } from "../../Molecules";
import { AboutEvreTemplate } from "../../Templates";

export const AboutEvre = () => {
  return (
    <div>

      <SolutionsTabBar
        l1=""
        l1id="#doorway"
        l2="Features"
        l2id="#features"
        l3="Technology"
        l3id="#technology"
        l4="CMS"
        l4id="#cms"
        l5="Business"
        l5id="#business"
      />

      <AboutEvreTemplate
        l1id="doorway"
        l2id="features"
        l3id="technology"
        l4id="cms"
        l5id="business"
      />

      <Footer />

    </div>
  );
};
