import React, { useRef } from "react";
import { Box, makeStyles } from "@material-ui/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const blogsData = [
  // {
  //   title:
  //     "CII calls for making Open Access almost free for EV Charing from renewable sources",
  //   imgSrc:
  //     "https://images.newindianexpress.com/uploads/user/imagelibrary/2019/11/8/w900X450/Jaitley.jpg?w=900&dpr=1.0",
  //   createdAt: "21 Mar 2022",
  //   targetLink:
  //     "https://www.newindianexpress.com/business/2022/mar/21/cii-calls-for-making-open-access-almost-free-for-ev-charing-from-renewable-sources-2432615.html",
  // },

  {
    title:
      "Manish Sisodia inaugurates East Delhi's first residential EV charging station in Mayur Vihar",
    imgSrc:
      "https://english.cdn.zeenews.com/sites/default/files/2022/05/16/1043154-manish-sisodia-ev-charger.jpg",
    createdAt: "16 Mar 2022",
    targetLink:
      "https://zeenews.india.com/electric-vehicles/manish-sisodia-inaugurates-east-delhis-first-residential-ev-charging-station-in-mayur-vihar-2464079.html",
  },
  {
    title:
      "Infratech firm EVRE raises funds from Acko Tech, CreedCap Asia Advisors",
    imgSrc:
      "https://gumlet.assettype.com/freepressjournal/2019-12/dbc62c6d-38ca-4512-9149-d06ac5624d85/Money.jpg?format=webp&w=768&dpr=1.0",
    createdAt: "22 Mar 2022",
    targetLink:
      "https://www.freepressjournal.in/business/infratech-firm-evre-raises-funds-from-acko-tech-creedcap-asia-advisors",
  },
  {
    title:
      "EV infra startup EVRE, lounge meetup app O'hi, VAMA, others report early-stage investment deals",
    imgSrc:
      "https://images.yourstory.com/cs/2/f02aced0d86311e98e0865c1f0fe59a2/electric-vehicle-charging-1640670746424.png?fm=auto&ar=2:1&mode=crop&crop=faces&w=1270",
    createdAt: "24 Mar 2022",
    targetLink:
      "https://yourstory.com/2022/03/funding-roundup-evre-struct-finance-defi-social-networking/amp",
  },
  {
    title:
      "EV Charging Solutions Provider EVRE Raises Undisclosed Fund in Pre-series Round",
    imgSrc:
      "https://assets.entrepreneur.com/content/3x2/2000/1647951940-electric-charge-g58f01ed81-19201.jpg?auto=webp&quality=95&crop=16:9&width=675",
    createdAt: "22 Mar 2022",
    targetLink: "https://www.entrepreneur.com/article/422813",
  },
  // {
  //   title: "Signature Global, EVRE to setup EV charging stations in Delhi",
  //   imgSrc:
  //     "https://static.toiimg.com/thumb/msid-90662483,imgsize-34774,width-400,resizemode-4/90662483.jpg",
  //   createdAt: "5 Apr 2022",
  //   targetLink:
  //     "https://timesofindia.indiatimes.com/auto/news/signature-global-evre-to-setup-8-ev-charging-stations-in-delhi-ncr/articleshow/90662504.cms",
  // },
  {
    title:
      "EVRE signs MoU with Signature Global to deploy EV chargers in Delhi-NCR",
    imgSrc:
      "https://www.financialexpress.com/wp-content/uploads/2021/12/EVRE-1.jpg",
    createdAt: "5 Apr 2022",
    targetLink:
      "https://www.financialexpress.com/express-mobility/evre-signs-mou-with-signature-global-to-deploy-ev-chargers-in-delhi-ncr/2482510/",
  },
  {
    title:
      "ईवीआरई, सिग्नेचर ग्लोबल ने दिल्ली-एनसीआर में ईवी चार्जिंग स्टेशन लगाने के लिए हाथ मिलाया",
    imgSrc:
      "https://res.cloudinary.com/rakesh-gupta/image/upload/v1653127935/change_xekg3q.jpg",
    createdAt: "5 Apr 2022",
    targetLink:
      "https://navbharattimes.indiatimes.com/business/business-news/evre-signature-global-joins-hands-to-set-up-ev-charging-stations-in-delhi-ncr/articleshow/90662350.cms",
  },
  {
    title:
      "तेजी से बढ़ रहा ईवी चार्जिंग इन्फ्रास्ट्रक्चर, इस शहर में ईवी चार्जिंग स्टेशन स्थापित करने के लिए हुई बड़ी डील",
    imgSrc:
      "https://www.jagranimages.com/images/newimg/05042022/05_04_2022-ev_charging_stations_22600779.jpg",
    createdAt: "5 Apr 2022",
    targetLink:
      "https://m.jagran.com/automobile/latest-news-evre-signature-global-join-hands-to-set-up-ev-charging-stations-in-delhi-ncr-22600779.html",
  },
  {
    imgSrc:
      "https://akm-img-a-in.tosshub.com/indiatoday/images/story/202109/Evre_charging_station.jpg?LyeR8vs6zv9lQMdj_abDWv70cY5d7X7r&size=770:433",
    title: "EVRE, Park+ to set up 10,000 EV charging stations in India by 2023",
    createdAt: "21 Sep 2021",
    targetLink:
      "https://www.indiatoday.in/auto/latest-auto-news/story/evre-park-to-set-up-10-000-ev-charging-stations-in-india-by-2023-1855234-2021-09-21",
  },
  {
    imgSrc:
      "https://www.drivespark.com/img/2021/11/xevre-1635779350.jpg.pagespeed.ic.UnXds1UHb3.jpg",
    title:
      "EVRE & FreshWorld Partner Up To Expand Charging Network For Dedicated EV Fleet",
    createdAt: "1 Nov 2021",
    targetLink:
      "https://www.drivespark.com/four-wheelers/2021/evre-fresh-world-partner-to-expand-charging-network-for-ev-fleet-035003.html#p4",
  },
  // {
  //   imgSrc:
  //     "https://www.financialexpress.com/wp-content/uploads/2021/11/Electric-Vehicles-Pollute-As-Much-As-ICE-Cars-Is-a-Myth-1-620x400-1.jpg",
  //   title:
  //     "EVRE to install 1,000 EV chargers in 12 cities, partners with Lets Transport",
  //   createdAt: "25 Nov 2021",
  //   targetLink:
  //     "https://www.financialexpress.com/auto/electric-vehicles/evre-to-install-1000-ev-chargers-in-12-cities-partners-with-lets-transport/2375856/?utm_source=whatsapp_web&utm_medium=social&utm_campaign=socialsharebuttons",
  // },
  {
    imgSrc: "https://etimg.etb2bimg.com/photo/87908847.cms",
    title:
      "LetsTransport partners with EVRE for charging infrastructure in 12 cities",
    createdAt: "25 Nov 2021",
    targetLink:
      "https://auto.economictimes.indiatimes.com/news/industry/letstransport-partners-with-evre-for-charging-infrastructure-in-12-cities/87908613",
  },
  {
    imgSrc:
      "https://todaynewsnetwork.in/wp-content/uploads/2021/12/1hse5eqc_evre-charging-station_625x300_10_September_21-768x474.jpg",
    title:
      "EVRE Joins Hands With Mad About Wheels To Add 500 EV Charging Stations",
    createdAt: "2 Dec 2021",
    targetLink:
      "https://todaynewsnetwork.in/evre-joins-hands-with-mad-about-wheels-to-add-500-ev-charging-stations/",
  },

  {
    imgSrc: "https://etimg.etb2bimg.com/photo/88954074.cms",
    title:
      "EVRE upgrades its EV hubs with fast charging ecosystem, other amenities",
    createdAt: "17 Jan 2022",
    targetLink:
      "https://auto.economictimes.indiatimes.com/news/industry/evre-upgrades-its-ev-hubs-with-fast-charging-ecosystem-other-amenities/88954021",
  },
  {
    imgSrc:
      "https://www.thehindu.com/news/cities/bangalore/b0shlu/article36373097.ece/alternates/FREE_320/09bgElectricVehiclesChargingVidhanaSoudha6JPG",
    title: "Bengaluru to get 200 new EV charging points in six months",
    createdAt: "8 Sep 2021",
    targetLink:
      "https://www.thehindu.com/news/cities/bangalore/city-to-get-200-new-ev-charging-points-in-six-months/article36367077.ece/amp/",
  },

  {
    imgSrc:
      "https://i2.wp.com/evtechnews.in/wp-content/uploads/2021/09/EVRE-image-1.png?w=519&ssl=1",
    title:
      "EVRE Partners With MoEVing To Add 1000+ EV Charging Stations Across India",
    createdAt: "8 Sep 2021",
    targetLink:
      "https://evtechnews.in/evre-partners-with-moeving-to-add-1000-ev-charging-stations-across-india/",
  },
];

const useStyles = makeStyles((theme) => ({
  boxMargin: {
    margin: "10% 0%",
  },
  head: {
    width: "50%",
    margin: "10% auto",
    font: "normal normal bold 7vw Urbanist",
  },
  arrow: {
    width: "100%",
    // marginBottom: '2%',
    display: "inline-block",
  },
  arrowFloat: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    float: "right",
    [theme.breakpoints.down("sm")]: {
      float: "none",
      width: "100%",
    },
  },
  arrowIcon: {
    margin: "0 5%",
    background: "none",
    border: "none",
    outline: "none",
    opacity: ".5",
    cursor: "pointer",
    "&:hover": {
      opacity: "1",
    },
  },

  boxone: {},
  boxtwo: {
    border: "2px solid green",
    display: "flex",
    flexDirection: "row",
  },
  image: {
    width: "80%",
  },
  flexboxone: {
    width: "20%",
    marginTop: "10%",
  },
  flexboxtwo: {
    width: "70%",
  },
  number: {
    fontSize: "5rem",
    fontFamily: "Urbanist",
    fontWeight: "700",
    opacity: "0.5",
  },
  slider: {
    width: "80%",
    margin: "0 auto",
    // height: '75vh',
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  card: {
    overflow: "hidden",
    padding: "10%",
    paddingLeft: "0%",
    width: "10%",
    // height: '80vh',
    // margin: '5%',
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "10%",
    },
  },
  cardSub: {
    // padding: '5%',
    // height: '500px',
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  },
  TitleBox: {
    width: "70%",
    margin: "0% 0% 5% 10%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  titleFlex: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  titleHead: {
    fontSize: "4vw",
    [theme.breakpoints.down("sm")]: {
      fontSize: "8vw",
    },
  },
  titlePara: {
    font: " normal normal lighter 1.8vw Urbanist",
    [theme.breakpoints.down("sm")]: {
      fontSize: "6vw",
    },
  },
  image: {
    width: "100%",
    height: "280px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxHeight: "100%",
    },
    [theme.breakpoints.down("xl")]: {
      height: "180px",
    },
    [theme.breakpoints.down("lg")]: {
      height: "200px",
    },
  },
  dateText: {
    font: "normal normal lighter 1.2rem Urbanist",
    marginTop: "5%",
    // marginLeft:'2%'
  },
  paratext: {
    font: "normal normal normal 1.2rem Urbanist",
    marginTop: "8%",
    "&:hover": {
      color: "#00BF5F",
    },
    // marginLeft: '2%'
  },
}));

export const NewsShowcase = ({ title, titlePara }) => {
  // console.log(title);
  const classes = useStyles();

  const sliderRef = useRef();

  const next = () => {
    sliderRef.current.slickNext();
  };
  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const settings = {
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box className={classes.boxMargin}>
      <Box className={classes.TitleBox}>
        <Box className={classes.titleFlex}>
          <h3 className={classes.titleHead}>{title}</h3>
        </Box>
        <Box className={classes.titleFlex}>
          <h6 className={classes.titlePara}>{titlePara}</h6>
        </Box>
      </Box>

      <Box className={classes.arrow}>
        <Box className={classes.arrowFloat}>
          <button
            className={classes.arrowIcon}
            style={{ transform: "rotate(180deg)" }}
            onClick={previous}
          >
            <img src={"./images/Arrow/Arrow.png"} alt="arrow" />
          </button>
          <button className={classes.arrowIcon} onClick={next}>
            <img src={"./images/Arrow/Arrow.png"} alt="arrow" />
          </button>
        </Box>
      </Box>

      <Slider ref={sliderRef} {...settings} className={classes.slider}>
        {blogsData.map((i, index) => {
          return (
            <Box className={classes.card} key={index}>
              <Box className={classes.cardSub}>
                <img src={i.imgSrc} alt="news-img" className={classes.image} />
                <p className={classes.dateText}>{i.createdAt || ""}</p>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={i.targetLink}
                  className={classes.paratext}
                >
                  {i.title || ""}
                </a>
              </Box>
            </Box>
          );
        })}
      </Slider>
    </Box>
  );
};
