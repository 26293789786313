import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { SecondBox } from "./SecondBox";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    margin: "0 auto",
    marginTop: "5%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  textone: {
    font: "normal normal 1.5rem Urbanist",
    [theme.breakpoints.down("sm")]: {
      font: "normal normal 1rem Urbanist",
      marginTop: "10%",
    },
  },
  textTwo: {
    font: "normal normal 5vw Urbanist",
    marginTop: "5%",
    background: "#006934",
    background: "-webkit-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "-moz-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    [theme.breakpoints.down("md")]: {
      fontSize: "5rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.down("xs")]: {
      font: "normal normal 500 3rem Urbanist",
    },
  },
  textThree: {
    font: "normal normal lighter 1.5rem Urbanist",
    width: "70%",
    marginTop: "5%",
    lineHeight: "1.5",
    [theme.breakpoints.down("sm")]: {
      font: "normal normal  lighter 1.87rem Urbanist",
      width: "90%",
    },
  },
  mainBox: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  textBoxLeft: {
    width: "40%",
    marginLeft: "15%",
    marginRight: "5%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin: "0% auto",
    },
  },
  appQrBox: {
    marginTop: "5%",
    display: "flex",
    flexDirection: "row",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "50%",
    },
    [theme.breakpoints.down("xs")]: {
      width: "70%",
    },
  },
  qrBoxAlign: {
    display: "flex",
    flexDirection: "column",
    overflow: 'hidden',
    [theme.breakpoints.down("sm")]: {
      marginTop: '5%'
    },
    "& img":{
      cursor:"pointer"
    }
  },
  handBox: {
    width: "50%",
    float: "right",
    [theme.breakpoints.down("sm")]: {
      width: '80%',
      marginTop: '10%',
      float: 'right',
      marginLeft: '20%',
      
    },
  },
  iconBox: {
    marginTop: '3%',
    display: 'flex',
    justifyContent:"space-between",
    "& a": {
      width: "48%",
    },
    "& img":{
      cursor:"pointer"
    }
  }
}));

export const FirstBox = () => {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.root}>
        <Box className={classes.mainBox}>
          <Box className={classes.textBoxLeft}>
            <Typography className={classes.textone}>
              WITH EVRE MOBILE APP
            </Typography>
            <Typography className={classes.textTwo}>
              Plug Into the Future of Smart EV Charging
            </Typography>
            <Typography className={classes.textThree}>
              Helping EV owners navigate to their nearest EV Chargers across
              India.
            </Typography>
            <Box className={classes.appQrBox}>
              <Box className={classes.qrBoxAlign}>
                <img
                  src={"./images/evre/qr-code-for-both.png"}
                  alt="appleqr"
                  style={{ width: "100%" }}
                />
                <Box className={classes.iconBox}>
                  <a href="https://apps.apple.com/us/app/evre-by-amplify-mobility/id1547215872?platform=iphone" target="_blank">
                    <img
                      src={"./images/evre/apple.svg"}
                      alt="apple"
                      style={{ width: "100%", marginTop: 10 }}
                    />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.slscorp.amplify.evre" target="_blank">
                    <img
                      src={"./images/evre/googleplay.svg"}
                      alt="playstore"
                      style={{ width: "100%", marginTop: 10 }}
                    />
                  </a>
                </Box>
              </Box>
              <Box className={classes.qrBoxAlign}>
                {/* <img
                  src={"./images/evre/googleqr.png"}
                  alt="googleqr"
                  style={{ width: "80%" }}
                /> */}
              </Box>
            </Box>
          </Box>
          <Box className={classes.handBox}>
            <img
              src={"./images/AboutEvre/mobile-app.png"}
              alt="mobile"
              width="100%"
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};
