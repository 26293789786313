import React, { useRef, useState } from "react";
import Slider from "react-slick";
import { Box, makeStyles } from "@material-ui/core";
import AnimatedCursor from "react-animated-cursor"
import { useEffect } from "react";
import clsx from "clsx";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { MdOutlineDoubleArrow } from 'react-icons/md'
import { FaAngleDoubleLeft, FaAngleDoubleRight } from 'react-icons/fa'

const useStyles = makeStyles((theme) => ({
    custom_cursor: {
        zIndex: '999',
        borderRadius: "50%",
        width: "50px",
        height: "50px",
        border: "4px solid #00BF5F",
        pointerEvents: 'none',
        position: 'fixed',
        top: "0",
        left: "0",
        transform: "translate3d(0,0,0)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "transparent",
        color: "#00BF5F",
        fontWeight: "bold",
        font: 'normal normal 500 1vw Urbanist'

    },
    arrows: {
        color: "#00BF5f",
        fontSize: "1.5rem",
        margin: "0 1.5rem ",
    }
}));


export const CursorCustom = ({ className }) => {
    const classes = useStyles();

    let cusRef = useRef(null)

    const [state, setState] = useState("")

    useEffect(() => {

        document.addEventListener("mousemove", (e) => {
            const { clientX, clientY } = e

            const mouseX = clientX - cusRef.current.clientWidth / 2;
            const mouseY = clientY - cusRef.current.clientHeight / 2;

            cusRef.current.style.transform = `translate3d(${mouseX}px,${mouseY}px,0)`;

        })
    }, [cusRef])


    return (
        <div className={clsx(`custom_pointer_app ${className}`, classes.custom_cursor)} ref={cusRef} >
            <div className={classes.arrows}>
                {/* <FaAngleDoubleLeft /> */}
            </div>
            180&#176;
            <div className={classes.arrows}>
                {/* <FaAngleDoubleRight /> */}
            </div>
        </div>
    );
};
