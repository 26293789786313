import React from "react";
import { Box, makeStyles, Typography } from "@material-ui/core";
import {
  AllPageHead,
  HomeNavigation,
  TextBox,
  OurStory,
  ReachCard,
  TeamProfile,
  JoinUs,
} from "../../Atom";

import { team } from "../../../Data/TeamData";

const para =
  "We are India’s leading EV Charger OEMs and Charging Infra developers. The EV Revolution brings with it opportunities to build a sustainable future. We want to be the key influencers leading this shift and pave a way for a greener future one vehicle, one neighborhood, and one city at a time.";

const card1 = "We are currently present across 9 cities of India and growing.";

const useStyles = makeStyles((theme) => ({
  imageBox: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  image: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  boxAlign: {
    marginLeft: "30%",
  },
  ourReach: {
    width: "80%",
    margin: "0 auto",
    marginTop: "10%",
    [theme.breakpoints.down("sm")]: {
      width: "100",
    },
  },
  reachBox: {
    width: "80%",
    margin: "0 auto",
  },
  reachText: {
    font: "normal normal normal 5rem Urbanist",
    color: "#00BF5F ",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      font: "normal normal  3rem Urbanist",
    },
  },
  reachPara: {
    width: "40%",
    font: "normal normal lighter 1.5rem Urbanist",
    marginTop: 10,
    marginLeft: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginLeft: "25%",
      font: "normal normal lighter 1rem Urbanist",
    },
  },
  colourText: {
    font: "normal normal bolder 3vw Urbanist",
    textAlign: "center",
    background: "#006934",
    lineHeight: "1.5",
    background: "-webkit-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "-moz-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    [theme.breakpoints.down("md")]: {
      fontSize: "3rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
    },
  },
  colorBox: {
    width: "50%",
    marginTop: "40%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      marginTop: "20%",
    },
  },
  line: {
    width: "70%",
    margin: "0 auto",
    color: "#ffffff",
    marginTop: "5%",
  },
  teamBox: {
    width: "60%",
    margin: "0 auto",
    marginTop: "10%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20%",
    },
  },
  teamText: {
    font: "normal normal  5rem Urbanist",
    textAlign: "center",
    paddingTop: "15%",
  },
  teampara: {
    font: "normal normal lighter 1.2rem Urbanist",
    textAlign: "center",
    width: "50%",
    marginTop: 20,
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      font: "normal normal lighter 1rem Urbanist",
      width: "100%",
      marginTop: 5,
    },
  },
  teams: {
    width: "70%",
    margin: "0 auto",
    marginTop: "5%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      marginTop: "10%",
    },
  },
}));

export const AboutUsTemplate = ({ l1id, l2id, l3id, l4id, l5id }) => {
  const classes = useStyles();
  return (
    <>
      <HomeNavigation name="About Us" />

      <AllPageHead title="Who We Are" para1={para} />

      <TextBox l1id={l1id} l2id={l2id} />

      <br />
      <br />
      <br />
      <br />

      <div id={l3id}>
        <OurStory />
      </div>

      <div id={l4id}>
        <ReachCard />
      </div>

      <Box className={classes.colorBox}>
        <Typography className={classes.colourText}>
          "The automobile industry and the world is witnessing the most
          disruptive sustainable shift today. If we channelize it now, we can
          create a world that looks as advanced as it feels livable."
        </Typography>
        <hr className={classes.line} />
      </Box>

      <Box className={classes.teamBox}>
        <Typography className={classes.teamText} id={l5id}>
          Team
        </Typography>
        <Typography className={classes.teampara}>
          Here is the team that’s enabling EVRE in creating a sustainable world.
        </Typography>
      </Box>

      <Box className={classes.teams}>
        <TeamProfile data={team} />

        {/* <TeamProfile
          title="Name"
          pos="Designation"
          image="./images/AboutUs/test.jpeg"
        />
        <TeamProfile
          title="Name"
          pos="Designation"
          image="./images/AboutUs/test.jpeg"
        />
        <TeamProfile
          title="Name"
          pos="Designation"
          image="./images/AboutUs/test.jpeg"
        /> */}
      </Box>

      <Box className={classes.teams}>
        {/* <TeamProfile
          title="Name"
          pos="Designation"
          image="./images/AboutUs/test.jpeg"
        />
        <TeamProfile
          title="Name"
          pos="Designation"
          image="./images/AboutUs/test.jpeg"
        />
        <TeamProfile
          title="Name"
          pos="Designation"
          image="./images/AboutUs/test.jpeg"
        /> */}
      </Box>

      <JoinUs />
    </>
  );
};
