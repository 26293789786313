import React, { useEffect } from "react";
//Material ui
import { Box, makeStyles } from "@material-ui/core";
//Aos
import AOS from 'aos';
import 'aos/dist/aos.css';


//Style
const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    margin: "10% auto",
    paddingTop: '10%',
    [theme.breakpoints.down("sm")]: {
      width: '100%'
    },
  },
  image: {
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "20%",
    },
  },
  flexBox: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      float: "none",
    },
  },
  head: {
    font: "normal normal 600 2.5vw Urbanist",
    color: "#00BF5F ",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
      textAlign: "center",
    },
  },
  para: {
    lineHeight: "1.8",
    font: "normal normal 200 1.5vw Urbanist",
    margin: "5% 0%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      margin: "10% auto",
      fontSize: "6vw",
    },
  },
}));

export const ImageLeftCenter = ({ title, image, para }) => {
  const classes = useStyles();

  const animation = () => {
    AOS.init({
      easing: 'ease-out-back',
      duration: 1000,
    });
  }

  useEffect(() => {
    animation()
  }, [])
  return (
    <Box className={classes.root}>
      <Box className={classes.flexBox}>
        <h1 className={classes.head} data-aos="fade-up">{title}</h1>
        <p className={classes.para} data-aos="fade-up">{para}</p>
      </Box>
      <img className={classes.image} src={image} alt="imageCenter" data-aos="fade-up" />
    </Box>
  );
};
