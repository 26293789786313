import React, { useEffect } from "react";
//Material ui
import { Box, makeStyles } from "@material-ui/core";
import haloBrochure from "../../Assets/brochures/halo.pdf";
//Material Icon
import { PodGridOneData } from "../../../Data";
import {
  LeftImagesPara,
  ProductGridOne,
  ProductGridTitle,
  ProductInfinityPara,
  ProductIntro,
  RightImagesPara,
  MakeInIndia,
} from "../../Atom";
import { Footer, TopBarAll } from "../../Molecules";
import { BottomBarScroll } from "../../Molecules/BottomBar/BottomBarScroll";

const para1 =
  "Presenting a revenue-boosting and design- and technology-driven 2, 3, & 4-Wheeler EV Charging station that can help you augment your real estate/business value through pocket-friendly charging.";
const paraRight =
  "As per studies, there will be 79 Million EVs on the road by 2030 in India. With maximum EV Charging happening in homes at night, this makes EV Charging a must-have infrastructure for future ready townships, high-rise buildings, row houses, etc. Upscale the value and traction towards your real-estate properties with EVRE.";
const paraRight1 =
  "Majority of the Indian population spends at least 8 to 10 hours at their offices which makes charging at workplaces as desirable as charging at home. Head on to an emission-free future and help us scale the EV uptake by providing all-wheeler EV Charging with Pod.";
const paraLeft =
  "Scale the revenue generation of your commercial spaces like Departmental stores, Malls, Hotels, etc. by making it EV-Ready. Ace the charging game by becoming an all-wheeler EV Charging provider and attract the major chunk of EV users to your business.";

const useStyles = makeStyles((theme) => ({
  paddingView: {
    paddingTop: "10%",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "18%",
    },
  },
  paddingViewAll: {
    paddingTop: "2%",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "20%",
    },
  },
}));

export const PodProductPage = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <TopBarAll
        l2="Features"
        l2id="#Purpose-built"
        l3="Residential Spaces"
        l3id="#residential"
        l4="Commercial Spaces"
        l4id="#commercial"
        l5="Business"
        l5id="#business"
        // l6="&#11015; Download Specifications"
        downloadSpecification="&#11015; Download Specifications"
        downloadFile={haloBrochure}

        // l5id="#download"
      />

      <ProductIntro
        title="HALO"
        Pod
        // Image={"./images/Pod/hola.png"}
      />

      <BottomBarScroll title="CHARGE WITH EVRE" route="evreproduct" />

      <ProductInfinityPara para1={para1} />

      <Box id="Purpose-built" className={classes.paddingView}>
        <ProductGridTitle
          h1="Purpose-built"
          h2="for diversified"
          h3="EV Charging"
        />
      </Box>

      <ProductGridOne mapData={PodGridOneData} />

      <Box id="residential" className={classes.paddingViewAll}>
        <RightImagesPara
          image={"./images/Infinity/Right1.png"}
          title="Residential Spaces"
          para={paraRight}
          route="residential"
        />
      </Box>

      <Box id="commercial" className={classes.paddingViewAll}>
        <LeftImagesPara
          image={"./images/Infinity/Center1.png"}
          title="Commercial Spaces"
          para={paraLeft}
          route="commercial"
        />
      </Box>

      <Box id="business" className={classes.paddingViewAll}>
        <RightImagesPara
          image={"./images/Infinity/Center2.png"}
          title="Business"
          para={paraRight1}
          route="business"
        />
      </Box>

      <MakeInIndia />
      <Footer />
    </div>
  );
};
