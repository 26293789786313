export const ResidentialCarousal = [
  {
    title: "INFINITY",
    image: "./images/Infinity/left.png",
    para: "  A premium 4-wheeler EV Charging Station with superior design, class-apart performance, and centennial durability.",
  },
  {
    title: "Halo",
    image: "./images/Pod/hola.png",
    para: " Unveiling a 2, 3, & 4 Wheeler EV Charging Station that’s compact,intelligent, and meant for places where your customers spend most of their time.",
  },
  {
    title: "INTELLIPOLE",
    image1: "./images/Intellipole/Intellipole.png",
    para: "Presenting the futuristic and intelligent EV Charging Solar Street Light Solution with multi-charge facility, AQI, all-weather durability, and much more!",
  },
];

export const CommercialCarousal = [
  {
    title: "INFINITY",
    image: "./images/Infinity/left.png",
    para: "  A premium 4-wheeler EV Charging Station with superior design, class-apart performance, and centennial durability.",
  },
  {
    title: "Halo",
    image: "./images/Pod/hola.png",
    para: " Unveiling a 2, 3, & 4 Wheeler EV Charging Station that’s compact,intelligent, and meant for places where your customers spend most of their time.",
  },
  {
    title: "INTELLIPOLE",
    image1: "./images/Intellipole/Intellipole.png",
    para: "Presenting the futuristic and intelligent EV Charging Solar Street Light Solution with multi-charge facility, AQI, all-weather durability, and much more!",
  },
];

export const BusinessCarousal = [
  {
    title: "INFINITY",
    image: "./images/Infinity/left.png",
    para: "  A premium 4-wheeler EV Charging Station with superior design, class-apart performance, and centennial durability.",
  },
  {
    title: "Halo",
    image: "./images/Pod/hola.png",
    para: " Unveiling a 2, 3, & 4 Wheeler EV Charging Station that’s compact,intelligent, and meant for places where your customers spend most of their time.",
  },
  {
    title: "INTELLIPOLE",
    image1: "./images/Intellipole/Intellipole.png",
    para: "Presenting the futuristic and intelligent EV Charging Solar Street Light Solution with multi-charge facility, AQI, all-weather durability, and much more!",
  },
];

export const GovermentCarousal = [
  {
    title: "INFINITY",
    image: "./images/Infinity/left.png",
    para: "  A premium 4-wheeler EV Charging Station with superior design, class-apart performance, and centennial durability.",
  },
  {
    title: "INTELLIPOLE",
    image1: "./images/Intellipole/Intellipole.png",
    para: "Presenting the futuristic and intelligent EV Charging Solar Street Light Solution with multi-charge facility, AQI, all-weather durability, and much more!",
  },
  {
    title: "AC-001",
    image1: "./images/AC/AC.png",
    para: "An indomitable and robust 2, 3, & 4 Wheeler EV Charging Station spearheading durability, performance, And multi-charge facility.",
  },
];