import React, { useEffect } from "react";
//Material ui
import { Box, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
  },
  image: {
    width: "100%",
    height: "100%",
    overflow: "hiddne",
  },
}));

export const ProductImage = ({ Image }) => {
  const classes = useStyles();
  return (
    <Box>
      <img
        src={Image}
        className={classes.image}
        alt="coverImages"
      />
    </Box>
  );
};
