import React from "react";
//Material ui
import { Box, makeStyles } from "@material-ui/core";

//Style
const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    margin: "0 auto",
    marginTop: "5%",
    marginBottom: "10%",
  },
  mainBox: {
    width: "100%",
    display: "inline-block",
  },
  electric: {
    float: "right",
    font: "normal normal bolder 10rem Urbanist",
    opacity: ".2",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3rem",
    },
  },
  electricPara: {
    width: "70%",
    margin: "0 auto",
    font: "normal normal 300 4.5vw Urbanist",
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.5rem",
      marginTop: "10%",
      marginBottom: "10%",
    },
  },
  Vehicles: {
    font: "normal normal bolder 10rem Urbanist",
    opacity: ".2",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3rem",
    },
  },
}));

export const ElectriceVehicales = ({ title, image, para }) => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.mainBox}>
        <marquee direction="left" scrollamount="20" className={classes.electric}>Electric</marquee>
      </Box>
      <Box className={classes.mainBox}>
        <h3 className={classes.electricPara}>
          The EV Revolution is here and EVRE is ready to power it!
        </h3>
      </Box>
      <Box className={classes.mainBox}>
        <marquee direction="right" scrollamount="20" className={classes.Vehicles}>Vehicles</marquee>
      </Box>
    </Box>
  );
};
