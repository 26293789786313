import React, { useEffect } from "react";
import { makeStyles, Container, Box, Typography } from "@material-ui/core";
//Molicules
import { BottomBarForUpcoming } from "../../Molecules";
import { ViewDetails } from "../../Atom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display:"flex",
    justifyContent:"center",
    marginTop: "14%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100vh",
      justifyContent: "center",
      flexDirection: "column",
    },
  },
  rootInbox: {
    margin: "0 auto",
    width:"60%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "10%",
      marginLeft: "auto",
    },
  },
  upcommingText: {
    font: "normal normal bolder 9vw Urbanist",
    background: "#006934",
    lineHeight: "0.9",
    background: "-webkit-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "-moz-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",    
    [theme.breakpoints.down("sm")]: {
      fontSize: "8vw",
      textAlign: "center",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "14vw",
    },
  },
  subBox: {
    cursor: "pointer",
    position: 'relative',

  },
  para: {
    font: "normal normal lighter 1.2rem Urbanist",
    lineHeight: "1.5",
    width: "60%",
    [theme.breakpoints.down("md")]: {

    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      lineHeight: "1.5",
    },
  },
}));

export const UpcommingTemplatesFix = ({
  explore
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Box className={classes.rootInbox}>
        <Typography className={classes.upcommingText} >
          UPCOMING PRODUCTS
        </Typography>
        <Box className={classes.subBox}>
          <Typography className={classes.para}>
            Explore our upcoming range of futuristic EV Charging Solutions made
            for leading the sustainable change across the world.
          </Typography>
          <Box className={classes.subBox}>
            {
              explore ?
                <ViewDetails
                  title="Explore"
                  route_curr="upcomming"
                />
                : ''
            }
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
