import React, { useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
//Router
import { useHistory } from "react-router";
//Aos
import AOS from 'aos';
import 'aos/dist/aos.css';


//Style
const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    margin: "0 auto",
    marginTop: "12%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20%",
      width:"90%"
    },
  },
  HomeNavigation: {
    cursor: "pointer",
    font: 'normal normal 300 1vw Urbanist',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem'
    }
  },
  Current: {
    font: 'normal normal 500 1vw Urbanist ',
    color: "#00BF5F ",
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem'
    }
  },
  arrow:{
    position: 'absolute',
    verticalAlign:"middle", 
    marginTop:"2px",
    fontWeight:"bold",
    fontSize: '1.2rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
      marginTop:"5px",

    }
  }
}));

export const HomeSolutionNavigation = ({ name }) => {
  const classes = useStyles();
  const history = useHistory();

  const animation = () => {
    AOS.init({
      easing: 'ease-out-back',
      duration: 1000,
    });
  }

  useEffect(() => {
    animation()
  }, [])
  return (
    <Box className={classes.root}>
      <p
        style={{
          color: "#fff",
          font: "normal normal normal 1rem Urbanist",
        }}
        data-aos="fade-right"
      >
        <span className={classes.HomeNavigation} onClick={() => history.push("/home")} data-aos="fade-right">
          Home
        </span>
        &nbsp;
        <ArrowForward className={classes.arrow} fontSize='small' data-aos="fade-right" />
        &nbsp; &nbsp; &nbsp; &nbsp;
        <span className={classes.Current} data-aos="fade-right">{name}</span>
      </p>
    </Box>
  );
};
