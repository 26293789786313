import React, { useEffect } from "react";
//Material ui
import { Box, makeStyles } from "@material-ui/core";
//Router
import { useHistory } from "react-router";
//Aos
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ViewDetails } from "..";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "75%",
    height: "100%",
    margin: "10% auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    }
  },
  imageBox: {
    width: "80%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  image: {
    width: "100%",
  },
  paraTitle: {
    color: "#00BF5F ",
    margin: "0% 0% 1% 0%",
    cursor: "pointer",
  },
  para: {
    color: "#FFF",
    lineHeight: "1.8",
    font: "normal normal 200 1.5vw urbanist",
    marginTop: 10,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      fontSize: "6vw",
    },
  },
  arrowAlign: {
    marginLeft: '5rem',
    marginTop: '-2%',
    width: "70px",
    color: "red",
    [theme.breakpoints.down("sm")]: {
      width: "30%",
    },
  },
  flexBox: {
    width: "50%",
    float: "right",
    display: "inline-block",
    marginBottom: "10%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: '0% 5%'
    },
  },
  floatBox: {
    display: "inline-block",
  },
}));

export const RightImagesPara = ({ image, title, para, route }) => {
  const classes = useStyles();
  const history = useHistory();

  const animation = () => {
    AOS.init({
      easing: 'ease-out-back',
      duration: 1000,
    });
  }

  const handleRoute = () => {
    window.open(`/${route}`, '_blank')
  }

  useEffect(() => {
    animation()
  }, [])
  return (
    <Box className={classes.root} >

      <Box className={classes.imageBox}>
        <img className={classes.image} src={image} alt="Image" data-aos="fade-up" />
      </Box>

      <Box className={classes.floatBox}>
        <Box className={classes.flexBox}>

          <Box className={classes.boxFlex}>
            <h4
              className={classes.paraTitle}
              onClick={handleRoute}
              data-aos="fade-up">
              {title}
            </h4>
          </Box>

          <Box className={classes.boxFlex}>
            <h6
              className={classes.para}
              data-aos="fade-up">
              {para}
            </h6>
          </Box>

          <ViewDetails
            title="view details"
            route={route}
          />

        </Box>
      </Box>

    </Box>
  );
};
