import React, { useEffect } from "react";
import { IntroTemplates } from "../../Templates";

export const Intro = () => {
  useEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' });
  }, [])
  return (
    <div>
      <IntroTemplates />
    </div>
  );
};
