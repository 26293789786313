import React from "react";
import { Box, makeStyles } from "@material-ui/core";
import AnimatedCursor from "react-animated-cursor"


const useStyles = makeStyles((theme) => ({

}));

export const CursorAnimated = () => {
    const classes = useStyles();

    return (
        <Box style={{ zIndex:99 }}>
            <AnimatedCursor 
                innerSize={120}
                outerSize={120}
                color='0,191,95'
                outerAlpha={0.5}
                innerScale={.2}
                outerScale={.8}
                trailingSpeed={8}></AnimatedCursor>
        </Box>
    );
};
