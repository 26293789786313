import React, { useRef, useState } from "react";
import { Box, makeStyles } from "@material-ui/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useModal, Modal } from "react-morphing-modal";
import "react-morphing-modal/dist/ReactMorphingModal.css";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import "./index.css";

const blogsData = [
  {
    title: "Move Over, ICE Vehicles - It’s Time for EVs to Take The Stage!",
    imgSrc:
      "https://res.cloudinary.com/rakesh-gupta/image/upload/v1653127935/change_xekg3q.jpg",
  },
  {
    title: "The Future is Electric - Now’s the time to capitalize on it!",
    imgSrc:
      "https://res.cloudinary.com/rakesh-gupta/image/upload/v1652953180/blog_img_r4j4hu.jpg",
  },
];

const detailsData = [
  `#### Move Over, ICE Vehicles - It’s Time for EVs to Take The Stage!

  You might not have driven one yet, let alone seriously contemplating purchasing one. But we must tell you - we are in the middle of the biggest automotive revolution after 1913 when Henry Ford’s first production line started rolling.
  
  Our automotive industry is the fifth-largest globally and is all set to be the third-largest by the end of this decade. With such a huge market to cater to, reliance on fuel-intensive mobility will no longer be a sustainable approach. To address this, the people responsible for making policies are developing a mobility option that is [Shared, Connected, and Electric](https://economictimes.indiatimes.com/india-aims-to-become-100-e-vehicle-nation-by-2030-piyush-goyal/articleshow/51551706.cms?from=mdr) - which is geared towards improving the adoption of EVs in Indian markets.
  
  One obvious reason behind such overenthusiasm towards moving to EVs is the number of environmental benefits they provide, and you already know about that. But that’s not it.
  
  It is estimated that almost [75-80%](https://theicct.org/sites/default/files/publications/EV-life-cycle-GHG_ICCT-Briefing_09022018_vF.pdf) of the expenses incurred on fuel are eliminated by using EVs. Compared to the other ICEVs, EVs have 70% fewer movable parts, leading to much lesser maintenance expenses. Further, the Government of India is proactively rolling out incentives and tax relaxations to encourage the transition to EV - which means all the more monetary benefits. EVs also help the nation with the BoP (Balance of Payment) deficit. In 2019-20 itself, India had to import 120 Bn Dollars worth of crude oil. This can be drastically reduced by switching to EVs.
  
  Okay, so that explains why the GoI is inclined towards inviting more people to EVs, but one question remains - is the transition from ICEVs to EVs (or from no vehicle to EVs - in case you’re on your first purchase!) worth it?
  
  TL;DR: Yes. Most certainly. It is way more than just worth it, and it’s only going to get better in the coming days. For a slightly longer answer, keep reading!
  ##### An electric future awaits
  
  Automotive companies from all around the world have keen eyes on the Indian markets. With Tesla, the world’s largest EV manufacturer, coming to the Indian automotive spectrum, it is more than safe to say that India has got a new lease for becoming a potential future hub for EVs.
  
  So, if you’re looking to purchase a vehicle in 2021, you must explore the EV options for you and pick the best one. We don’t say it without any reasons - there are enough reasons to support the claim that the future is indeed electric; some include:
  
   - **Slashed operational costs** :    EVs operate at overall lower costs than ICEVs. EVs trump ICEVs in all these scenarios, from maintenance to running costs to fuel prices, and that too by sufficient margin. For instance, consider Hyundai Kona. The vehicle delivers approximately 300 to 350 km in a single charge, which amounts to the running cost of less than a rupee per km. How amazing is that!
   -**Enjoy GoI’s incentives and schemes**:   GoI’s incentives make the EV purchase cheaper - depending on the state you’re residing in. Faster Adoption and Manufacturing of Hybrid and Electric vehicles scheme offers the following incentives based on the battery capacity of the EV:	
 
   - For two-wheelers: Rs. 15k per kWh of battery capacity, up to 40 percent of the vehicle cost.
   - For four-wheelers: Rs 10k per kWh of battery capacity, up to Rs 1.5 lakh.
  
   For two-wheelers: Rs. 15k per kWh of battery capacity, up to 40 percent of the vehicle cost.
     -- For four-wheelers: Rs 10k per kWh of battery capacity, up to Rs 1.5 lakh
  -**Stay charged and ready-to-go**:While it’s true that currently, the charging capabilities for e-mobility is a problematic area, there is a lot that has been done in the last couple of years, and a lot is to come. Delhi recently legalized EV stations for charging electric rickshaws with the idea that more and more private players and civic agencies would step forward with higher incentives. Further, NTPC has commissioned its [first](http://bit.ly/2PEHyvo) -   EV charging station in Visakhapatnam. It is an in-house station that has the bandwidth to charge three EVs simultaneously. Also, almost all the EVs come with personal chargers and enough battery capacity to last them for over 400kms with just an overnight charge. So even with the current lack of e-mobility infrastructure, this mileage is workable. The good news, though, is that it will soon get better with the rise of EV charging stations!
  -**Cut down on excess materials, improve safety measures:** -   Apart from efficiency and convenience, safety is another factor that works in favor of EVs. EVs operate by reducing a lot of vehicle mass and providing protection against short-circuiting or any hazards using high-quality materials which can withstand physical and chemical degradation. As a result, unlike ICEVs, EVs are less likely to deteriorate quickly over time, giving you a greater value for money. Further, since ICEVs tend to degrade over time progressively, their resale value also diminishes quickly. This is likely to change with EVs as the components - both physical and chemical - being used are long-lasting and sustainable.
  
  Many stylish, efficient, and safe EVs will [grace the Indian roads in 2021 and beyond](https://www.indiatoday.in/magazine/environment/story/20210614-hot-new-electric-vehicles-coming-soon-in-india-1810614-2021-06-05). If you are looking to purchase a vehicle this year - your choice should be pretty simple. The future is electric, and it’s only a matter of time before you, too, become a part of this revolution!
  
`,
  `####  The Future is Electric - Now’s the time to capitalize on it!
  ![](https://res.cloudinary.com/rakesh-gupta/image/upload/v1652953180/blog_img_r4j4hu.jpg)

  Did you know that [over 10 million electric cars](https://www.iea.org/reports/global-ev-outlook-2021/trends-and-developments-in-electric-vehicle-markets) took the roads across the world in 2020?
  
  Back in 2017, at the Bloomberg Global Business Forum (New York), industry leader Anand Mahindra stated that clean technology will emerge as the “[single biggest business opportunity for the next couple of decades](https://www.livemint.com/Companies/LIF7622PjBWEMx7Xt3tWqO/Anand-Mahindra-says-electric-vehicles-profit-tipping-point-n.html)”. He further stated:
  
  “We’ve just been believers for a while, haven’t been making any money, but now the tipping point seems to be reached.”
  
  And he was right!
  
  Despite the dire economic consequences of the Covid-19 pandemic, EV car registrations skyrocketed in the major markets. According to IEA’s [Global EV Outlook 2021](https://www.iea.org/reports/global-ev-outlook-2021), the global electric car stock peaked at 10 million in 2020, indicating a 43% increase since the past year (2019).
  
    
  
  India: The untapped market with huge potential
  
    
  
  India is an emerging market ripe with new possibilities for innovation. With Tesla in the Indian EV market and magnates like Tata and Mahindra launching their EV versions, India is geared up for the electric revolution. In 2020, the [Indian EV market value](https://www.mordorintelligence.com/industry-reports/india-electric-vehicle-market) stood at $5 billion. By 2026, the EV market is projected to grow at a CAGR of 44% to reach a value of $47 billion.
  
  Although at a nascent stage, India’s EV market is growing rapidly, thanks to the initiatives adopted by both private players and the government of India. For instance, Niti Aayog plans to create actionable strategies to [target 30% electric vehicles](https://www.investindia.gov.in/sector/automobile/electric-mobility) in India by 2030, powered by green technologies such as solar. Furthermore, we’ve witnessed significant growth in the Indian EV sector after the [FAME India Scheme](https://www.livemint.com/auto-news/india-extends-fame-scheme-by-two-years-to-incentivise-purchase-of-electric-vehicles-11624631393528.html) was implemented to promote electric vehicles.
  
  With such favorable schemes and initiatives, India is walking towards a sustainable future to address the rapidly escalating environmental concerns and economic challenges.
  
  EV Charging: Why do we say it’s the most profitable business model of the 21st century?
  
  The EV revolution is here and it’s here to stay! While the world is seeing a surge in EV uptake, the same can’t be said about establishing EV charging infrastructure. And this is where the business opportunity comes into the picture.
  
  Transportation is the lifeblood of every economy. Until now, the global transportation business/service had been dependent on petroleum giants of the OPEC network; a multi-billion dollar industry. The switch towards EVs is going to shift the dependency from the fuel companies to EV solution providers, entirely flipping the business landscape.
  
  The EV Revolution is all set to dynamically change the transportation and business ecosystem; making it an open opportunity to any and everyone to start this business without any heavy investment or complications.
  
  What’s more interesting is that an EV Charging business is not bound by geographical limitations. It is an open opportunity not only for individuals having properties at commercially viable locations but can also act like a side business and attraction for real estate businesses, retail shops, malls, offices, tech parks, etc.
  
  Of course, all the businesses who leverage this opportunity now will not only attract the majority of EV users to their businesses but can also capture a sizable share of this emerging EV market in India.  
    
  The bottom line? Anyone can capitalize on this fantastic business opportunity by setting up an EV charging infrastructure without incurring hefty investments.
  
  [EVRE](https://www.evre.in/)  is making this opportunity more feasible for individuals and business owners across the country. It offers the complete EV charging ecosystem - a number of bespoke EV charging stations designed using EVRE’s patented technology, a consumer app, and a business dashboard.
  
    
  
  Right from bringing you on the radar of all EV users to allowing them to reserve the charging slot and making online payments - everything happens online via the app. And the business dashboard helps you keep real-time track of your EV Charging stations making it a hassle-free unmanned operation. The best part is the interoperability of the system which allows integration of EVRE to your existing business apps to provide ease of business.
  
    
  
  So while you do what you do best, you can also earn a good side income without any manual intervention or efforts and own an EV Charging hub.
  
    
  
  An EV usually takes 5-6 hours for a full charge using a slow charger and 2 hours to fully charge using a fast charger. So while your consumer’s EV charges, they will have all the time in the world to avail your services.
  
    
  
  Real Estate builders can leverage this opportunity to gain a competitive edge, upsell their properties by making them future-ready.
  
    
  
  Workplaces on the other hand can provide this as energy as a service (EAAS) to their employees and contribute to the bigger mission of bringing sustainability to this world and scale up their CSR.
  
    
  
  Companies and governments around the world are taking the oath to switch to electric vehicles which makes this a promising opportunity for individuals interested in setting up public charging infrastructure.
  
    
  
  The crux is, EV Charging Business is filled with numerous opportunities, and businesses who leverage this now can make history by becoming the early adopters and leaders in the EV Charging Space.`,
];

const useStyles = makeStyles((theme) => ({
  boxMargin: {
    margin: "10% 0%",
  },
  head: {
    width: "50%",
    margin: "10% auto",
    font: "normal normal bold 7vw Urbanist",
  },
  arrow: {
    width: "100%",
    // marginBottom: '2%',
    display: "inline-block",
  },
  arrowFloat: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    float: "right",
    [theme.breakpoints.down("sm")]: {
      float: "none",
      width: "100%",
    },
  },
  arrowIcon: {
    margin: "0 5%",
    background: "none",
    border: "none",
    outline: "none",
    opacity: ".5",
    cursor: "pointer",
    "&:hover": {
      opacity: "1",
    },
  },

  boxone: {},
  boxtwo: {
    border: "2px solid green",
    display: "flex",
    flexDirection: "row",
  },
  image: {
    objectFit: "contain",
  },
  flexboxone: {
    width: "20%",
    marginTop: "10%",
  },
  flexboxtwo: {
    width: "70%",
  },
  number: {
    fontSize: "5rem",
    fontFamily: "Urbanist",
    fontWeight: "700",
    opacity: "0.5",
  },
  slider: {
    width: "80%",
    margin: "0 auto",
    // height: '75vh',
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  card: {
    overflow: "hidden",
    padding: "10%",
    paddingLeft: "0%",
    width: "10%",

    // height: '80vh',
    // margin: '5%',
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "10%",
    },
  },
  cardSub: {
    // padding: '5%',
    // height: '500px',
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  },
  TitleBox: {
    width: "70%",
    margin: "0% 0% 5% 10%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  titleFlex: {
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  titleHead: {
    fontSize: "4vw",
    [theme.breakpoints.down("sm")]: {
      fontSize: "8vw",
    },
  },
  titlePara: {
    font: " normal normal lighter 1.8vw Urbanist",
    [theme.breakpoints.down("sm")]: {
      fontSize: "6vw",
    },
  },
  image: {
    width: "100%",
    height: "280px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxHeight: "100%",
    },
    [theme.breakpoints.down("xl")]: {
      height: "180px",
    },
    [theme.breakpoints.down("lg")]: {
      height: "200px",
    },
  },
  dateText: {
    font: "normal normal lighter 1.2rem Urbanist",
    marginTop: "5%",
    // marginLeft:'2%'
  },
  paratext: {
    font: "normal normal normal 1.2rem Urbanist",
    marginTop: "8%",
    "&:hover": {
      color: "#00BF5F",
      cursor: "pointer",
    },
    // marginLeft: '2%'
  },
}));
const placeholderData = "Loading...";
function LinkRenderer(props) {
  console.log({ props });
  return (
    <a href={props.href} target="_blank" rel="noreferrer">
      {props.children}
    </a>
  );
}
const MarkdownRenderer = ({ children }) => {
  return (
    <ReactMarkdown
      className="markdown-wrapper"
      components={{ a: LinkRenderer }}
      remarkPlugins={[remarkGfm]}
    >
      {children}
    </ReactMarkdown>
  );
};

export const BlogShowcase = ({ title, titlePara }) => {
  const [modalData, setModalData] = useState(placeholderData);
  const { modalProps, open, close } = useModal({
    background: "#030303",
  });

  const triggerRef1 = useRef("");
  const handleTrigger1 = () => open(triggerRef1);
  // console.log(title);
  const classes = useStyles();

  const sliderRef = useRef();

  const next = () => {
    sliderRef.current.slickNext();
  };
  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const handleClick = (index) => {
    setModalData(detailsData[index]);
  };

  const settings = {
    arrows: false,
    speed: 1000,
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          dots: true,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const ModalView = () => {
    return <MarkdownRenderer>{modalData}</MarkdownRenderer>;
  };

  return (
    <Box className={classes.boxMargin}>
      <Box className={classes.TitleBox}>
        <Box className={classes.titleFlex}>
          <h3 className={classes.titleHead}>{title}</h3>
        </Box>
        <Box className={classes.titleFlex}>
          <h6 className={classes.titlePara}>{titlePara}</h6>
        </Box>
      </Box>

      {/* <Box className={classes.arrow}>
        <Box className={classes.arrowFloat}>
          <button
            className={classes.arrowIcon}
            style={{ transform: "rotate(180deg)" }}
            onClick={previous}
          >
            <img src={"./images/Arrow/Arrow.png"} alt="arrow" />
          </button>
          <button className={classes.arrowIcon} onClick={next}>
            <img src={"./images/Arrow/Arrow.png"} alt="arrow" />
          </button>
        </Box>
      </Box> */}

      <Slider ref={sliderRef} {...settings} className={classes.slider}>
        {blogsData.map((i, index) => {
          return (
            <Box
              onClick={() => handleClick(index)}
              className={classes.card}
              key={index}
            >
              <Box className={classes.cardSub}>
                <img src={i.imgSrc} alt="news-img" className={classes.image} />
                <p
                  ref={triggerRef1}
                  onClick={handleTrigger1}
                  className={classes.paratext}
                >
                  {i.title || ""}
                </p>
              </Box>
            </Box>
          );
        })}
      </Slider>
      <Modal {...modalProps} className="modal-test">
        <div className="modal-wrapper">
          <ModalView />
        </div>
      </Modal>
    </Box>
  );
};
