import React, { useEffect } from 'react'
//Material ui
import { Box, makeStyles, Typography } from "@material-ui/core";
//Aos
import AOS from 'aos';
import 'aos/dist/aos.css';
import { ViewDetails } from '..';


//Style
const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: "20%",
        height: '100%',
        paddingTop: '10%',
    },
    head: {
        font: "normal normal bolder 10vw Urbanist",
        textAlign: 'center',
        background: "#006934",
        background: "-webkit-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
        background: "-moz-linear-gradient(to top, #006934 0%,#00BF5F 100%)",
        background: "linear-gradient(to top, #006934 0%,#00BF5F 100%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        [theme.breakpoints.down("sm")]: {
            fontSize: "15vw",
            textAlign: "center",
        },
    },
    flexBox: {
        display: 'flex',
        justifyContent: 'right',
        alignItems:'flex-end',
        marginTop: '5%'
    },
    paraBox:{
        width: "40%",
        paddingRight: '5%',

    },
    para: {
        font: "normal normal normal 2vw Urbanist",
        lineHeight: "1.5",
        // marginTop: '20%',
        // float: 'right',
        [theme.breakpoints.down("sm")]: {
            width: "80%",
            margin: "10% auto",
            fontSize: "1rem",
            float: "none",
        },
    },
    BoxFlex: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'end',
        flexDirection: 'column',
        paddingRight: '2%'
    },
    rightArrow: {
        marginTop: '2%',
        width: '90%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        cursor: "pointer"
    },
    explore: {
        marginTop: 10,
        cursor: 'pointer',
        font: "normal normal normal 1.5rem Urbanist",
    },
    image: {
        width: "95%",
        marginLeft: '5%',
        [theme.breakpoints.down("sm")]: {
            width: "95%",
        },
    },
    arrowAlign: {
        marginLeft: "10%",
        marginBottom: 40,
    },
    imagePara: {
        width: '40%',
        height: '100%',
        "& img":{
            width:'100%',
            maxHeight:'100%'
        }
    },
    ViewDetailsBox: {
        display: "flex",
        justifyContent: 'right',
    }
}));


export const WhatNext = () => {
    const classes = useStyles();

    const animation = () => {
        AOS.init({
            easing: 'ease-out-back',
            duration: 1000,
        });
    }

    useEffect(() => {
        animation()
    }, [])
    return (
        <Box className={classes.root}>
            <h1 className={classes.head} data-aos="fade-up">What's next ?</h1>
            <Box className={classes.flexBox}>
                <Box className={classes.paraBox}>
                    <h1 className={classes.para} data-aos="fade-up">In case of any queries, feel free to write to us and our team will get back to you shortly.</h1>
                    <ViewDetails
                        className={classes.ViewDetailsBox}
                        title="Write to EVRE"
                        route="contact"
                    />
                </Box>
                <Box className={classes.imagePara}>
                    <img  data-aos="fade-up" src={'./images/What/What.png'} alt="image" />
                </Box>
            </Box>

            <Box className={classes.rightArrow}>
                {/* <Typography
                    className={classes.explore}
                    data-aos="fade-right"
                >
                    Write to EVRE
                </Typography> */}
                {/* <img
                    src={"./images/Arrow/Arrow.svg"}
                    alt="Arrow"
                    width="60"
                    className={classes.arrowAlign}
                    data-aos="fade-right"
                /> */}
            </Box>
        </Box>
    )
}
