import React, { useEffect, useState } from "react";
//Material ui
import {
  Box,
  makeStyles,
  Typography,
  Input,
  InputLabel,
  Button,
} from "@material-ui/core";
//data
import { States_city } from "../../../Data";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "10%",
    [theme.breakpoints.down("sm")]: {},
  },
  head: {
    font: "normal normal bolder 10rem Urbanist",
    textAlign: "center",
    background: "#006934",
    background: "-webkit-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "-moz-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    [theme.breakpoints.down("lg")]: {
      fontSize: "10rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "5rem",
    },
  },
  flexBox: {
    width: "100%",
    textAlign: "center",
  },
  paraBoxText: {
    width: "20%",
    textAlign: "left",
    marginLeft: "55%",
    font: "normal normal lighter 1rem Urbanist",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      width: "80%",
      margin: "0 auto",
      fontSize: "4vw",
    },
  },
  formBox: {
    width: "60%",
    margin: "5% auto",
    padding: "2%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  dateBox: {
    width: "50%",
    padding: "0% 5%",
  },
  label: {
    color: "#ffffff",
    font: "normal normal 1rem Urbanist",
    marginLeft: 10,
  },
  inputDate: {
    width: "100%",
    margin: "2% auto",
    paddingLeft: "5%",
    border: "none",
    borderBottom: "2px solid #1A1A1A",
    background: "transparent",
    color: "#FFFFFF",
    outline: "none",
  },
  nameBox: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginTop: "5%",
  },
  selectBox: {
    marginTop: "5%",
    width: "100%",
    padding: "0% 5%",
  },
  selectText: {
    width: "100%",
    margin: "2% auto",
    paddingLeft: "5%",
    border: "none",
    borderBottom: "2px solid #1A1A1A",
    background: "#000",
    color: "#FFFFFF",
    outline: "none",
  },
  submitBtn: {
    font: "normal normal 1rem Urbanist",
    display: "inline",
    float: "right",
    color: "#ffffff",
    marginTop: "5%",
    [theme.breakpoints.down("sm")]: {
      margin: "10% 0%",
    },
  },
}));

export const DealerEnquiryForm = () => {
  const classes = useStyles();
  const [data, setData] = useState("");
  const [open, setOpen] = useState(false);

  const [indiaState, setIndiaState] = useState("");

  const [city, setCity] = useState([]);

  const handleChange = (event) => {
    setData(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleState = (e) => {
    setIndiaState(e.target.value);
  };

  const getCity = () => {
    const NewCity = States_city.filter((item) => {
      return item.state == indiaState;
    });
    setCity(NewCity);
  };

  // console.log(city.length, "asd")

  useEffect(() => {
    getCity();
  }, [indiaState]);

  return (
    <Box className={classes.root}>
      <Box className={classes.flexBox}>
        <Typography className={classes.head} data-aos="fade-left">
          ENQUIRY
        </Typography>
        <Typography className={classes.paraBoxText} data-aos="fade-left">
          Feel free to connect with us to know more about our dealership model.
          We will be happy to help you!
        </Typography>
      </Box>

      <Box className={classes.formBox}>
        <Box className={classes.dateBox} data-aos="fade-up">
          <InputLabel className={classes.label}>Date</InputLabel>
          <Input placeholder="dd/mmm/YYYY" className={classes.inputDate} />
        </Box>

        <Box className={classes.nameBox}>
          <Box className={classes.dateBox} data-aos="fade-up">
            <InputLabel className={classes.label}>First Name</InputLabel>
            <Input className={classes.inputDate} />
          </Box>
          <Box className={classes.dateBox} data-aos="fade-up">
            <InputLabel className={classes.label}>Last Name</InputLabel>
            <Input className={classes.inputDate} />
          </Box>
        </Box>

        <Box className={classes.nameBox}>
          <Box className={classes.dateBox} data-aos="fade-up">
            <InputLabel className={classes.label}>State</InputLabel>
            <br />
            <select
              className={classes.selectText}
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              onChange={(e) => handleState(e)}
            >
              <option>Select</option>
              {States_city.map((item, index) => {
                const { state, districts } = item;
                return <option value={state}>{state}</option>;
              })}
            </select>
          </Box>

          <Box className={classes.dateBox} data-aos="fade-up">
            <InputLabel className={classes.label}>City</InputLabel>
            <br />
            <select
              className={classes.selectText}
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
            >
              <option>Select</option>
              {city.length === 1 &&
                city[0].districts.map((items) => {
                  return <option>{items}</option>;
                })}
            </select>
          </Box>
        </Box>

        <Box className={classes.nameBox}>
          <Box className={classes.dateBox} data-aos="fade-up">
            <InputLabel className={classes.label}>Phone</InputLabel>
            <Input className={classes.inputDate} />
          </Box>

          <Box className={classes.dateBox}>
            <InputLabel className={classes.label} data-aos="fade-up">
              Email
            </InputLabel>
            <Input className={classes.inputDate} />
          </Box>
        </Box>

        <Box className={classes.nameBox}>
          <Box className={classes.dateBox}>
            <InputLabel className={classes.label} data-aos="fade-up">
              Company Name
            </InputLabel>
            <Input className={classes.inputDate} />
          </Box>

          <Box className={classes.dateBox}>
            <InputLabel className={classes.label} data-aos="fade-up">
              Investment Range
            </InputLabel>
            <br />
            <select
              className={classes.selectText}
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
            >
              <option value={10}>Ten</option>
              <option value={20}>Twenty</option>
              <option value={30}>Thirty</option>
            </select>
          </Box>
        </Box>

        <Box className={classes.selectBox}>
          <InputLabel className={classes.label} data-aos="fade-up">
            Message
          </InputLabel>
          <Input className={classes.selectText} />
        </Box>

        <Box>
          <Button className={classes.submitBtn} data-aos="fade-left">
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
