import React, { useEffect } from "react";
import { makeStyles, Container, Box, Typography } from "@material-ui/core";
//Molicules
import { BottomBarForUpcoming } from "../../Molecules";
import { UpcommingTemplatesFix } from "../UpcommingTemplatesFix";
import upcomingProductImg from "../../Assets/evreProduct.png";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    marginTop: "10%",
    marginBottom: "20%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      marginTop: "20%",
      marginBottom: "20%",
    },
  },
  upcommingText: {
    font: "normal normal bolder 10vw Urbanist",
    background: "#006934",
    lineHeight: "0.9",
    background: "-webkit-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "-moz-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    [theme.breakpoints.down("md")]: {
      fontSize: "10rem",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "3rem",
    },
  },
  paraBox: {
    width: "35%",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      marginTop: "10%",
      marginLeft: "auto",
    },
  },
  paraText: {
    font: "normal normal lighter 1.5rem Urbanist",
    lineHeight: "1.5",
    [theme.breakpoints.down("sm")]: {
      fontSize: "6vw",
    },
  },
  whiteBoxOne: {
    backgroundColor: "#D4D4D4",
    marginTop: "20%",
    paddingTop: "40%",
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#D4D4D4",
      marginTop: "20%",
      paddingTop: "50%",
      width: "100%",
    },
  },
  listBoxRight: {
    width: "50%",
    marginTop: "5%",
    marginLeft: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      marginTop: "20%",
      marginLeft: "auto",
    },
  },
  listHeading: {
    font: "normal normal  2rem Urbanist",
    marginBottom: "5%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "6vw",
    },
  },
  listpara: {
    font: "normal normal  normal lighter 1rem Urbanist",
    lineHeight: "1.8",
    [theme.breakpoints.down("sm")]: {
      fontSize: "5vw",
      lineHeight: "1.8",
    },
  },
  whiteBoxtwo: {
    backgroundImage: `url(${upcomingProductImg})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    marginTop: "20%",
    paddingTop: "40%",
    width: "60%",
    marginLeft: "40%",
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#D4D4D4",
      marginTop: "20%",
      paddingTop: "50%",
      width: "100%",
      marginLeft: 0,
    },
  },
  listBoxLeft: {
    width: "50%",
    marginTop: "5%",
    marginLeft: "20%",

    [theme.breakpoints.down("sm")]: {
      width: "95%",
      marginTop: "20%",
      marginLeft: "auto",
    },
  },
}));

export const UpcommingTemplates = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root} data-aos="fade-up">
      <UpcommingTemplatesFix />
      <Box>
        {/* <Box className={classes.whiteBoxOne}></Box> */}
        {/* <Box className={classes.listBoxRight}>
          <Typography className={classes.listHeading}>
              Solar Charging Station
          </Typography>
          <Typography className={classes.listpara}>
            - Dedicated EV charging area
          </Typography>
          <Typography className={classes.listpara}>- Solar power</Typography>
          <Typography className={classes.listpara}>
            - Energy storage media (battery & hydrogen)
          </Typography>
          <Typography className={classes.listpara}>
            - Revenue generation through advertising
          </Typography>
          <Typography className={classes.listpara}>
            - Cloud connectivity
          </Typography>
          <Typography className={classes.listpara}>
            - Modular structure
          </Typography>
          <Typography className={classes.listpara}>
            - Rain water harvesting
          </Typography>
        </Box> */}
      </Box>
      <Box>
        <Box className={classes.whiteBoxtwo}></Box>
        <Box className={classes.listBoxLeft}>
          <Typography className={classes.listHeading}>
            DC Charging Station
          </Typography>
          <Typography className={classes.listpara}>
            - 15 kW to 150 kW Charging Provision
          </Typography>
          <Typography className={classes.listpara}>
            - Integrated computer vision
          </Typography>
          <Typography className={classes.listpara}>
            - Digital advertising
          </Typography>
          <Typography className={classes.listpara}>
            - Fast charging for cars, buses & trucks
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
