import React, { useEffect } from "react";
//Material Ui
import { makeStyles, Box } from "@material-ui/core";
//Atoms
import { AllPageHead, HomeNavigation, SolutionImageCenter } from "../../Atom";
//Router
import { useHistory } from "react-router";
//Aos
import AOS from "aos";
import "aos/dist/aos.css";

const para =
  "The future is electric and entities that leverage the revolution now, can attract and capture the majority share of EV users and become market dominators.";
const para2 =
  "Boost your property value and generate new revenue streams by capitalizing on a fossil-free future with EVRE.";

const paraCenter =
  "Electric Vehicles are the future and success will be honed by real estate players that capitalize on the EV Revolution by becoming EV Compliant. Explore purpose-first and customer-centric EV Charging Solutions.";

// const paraCenter1 =
//   "Earn yourself a permanent secondary income by leveraging the EV Revolution and attract the majority share of EV users to your business. Explore business-first EV Charging Solutions with EVRE.";
const paraCenter1 =
  "We are building India’s largest and most advanced EV Charging network for logistics and fleet operators, and last-mile delivery service providers. Our charging hubs are secured and insured spaces with a variety of EV Chargers that are compatible with all-wheeler EVs. We are also partnering with leading fleet operators to help them set up charging hubs exclusively for their companies with business-first features for non-stop operations.";

const paraCenter2 =
  "Be a leader in carving the electric future for the world. As we sail to a fossil-free future, equip your offices and business tech parks with EV Charging Infra that upscales the EV uptake in your workforce. Join us and become an early adopter of the biggest automobile revolution the world has witnessed.";

const paraCenter3 =
  "Public EV Charging Infra can solve the biggest challenge of EV uptake - Charge and Range Anxiety. Governments can play an influential role in redirecting India’s EV curve. Partner with EVRE to help India reach its National Electric Mobility Mission Plan and help build the future of India’s Smart Cities.";

//Style
const useStyles = makeStyles((theme) => ({
  head: {
    width: "80%",
    margin: "0 auto",
    font: "normal normal bolder 1.5rem Urbanist",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
      textAlign: "center",
    },
  },
}));

export const SolutionTemplates = ({ l1id, l2id, l3id, l4id }) => {
  const classes = useStyles();
  const history = useHistory();

  const animation = () => {
    AOS.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  };

  useEffect(() => {
    animation();
  }, []);
  return (
    <>
      <HomeNavigation name="Solutions" />

      <AllPageHead title="Solutions" para1={para} para2={para2} />

      <hr
        style={{ width: "45%", border: "1px solid #00BF5F " }}
        data-aos="fade-up"
      />

      <br />
      <br />
      <br />

      <hr
        style={{ width: "45%", float: "right", border: "1px solid #00BF5F " }}
        data-aos="fade-up"
      />

      <br />
      <br />
      <br />

      <h1
        className={classes.head}
        onClick={() => history.push("/evreproduct")}
        data-aos="fade-up"
      >
        CHARGE WITH EVRE
      </h1>

      <Box id={l1id}>
        <SolutionImageCenter
          slide="zoom-in"
          slide1="slide-right"
          title="Residential Spaces"
          para1={paraCenter}
          image={"./images/Solutions/SolutionRes.png"}
          route="residential"
        />
      </Box>

      <Box id={l2id}>
        <SolutionImageCenter
          slide="slide-right"
          slide1="slide-left"
          // title="Commercial Spaces"
          title="Logistics and Fleet Operators"
          // Subtitle="Spaces"
          para1={paraCenter1}
          image={"./images/ProductCommonImages/center.png"}
          route="commercial"
        />
      </Box>

      <Box id={l3id}>
        <SolutionImageCenter
          slide="slide-left"
          slide1="slide-right"
          title="Business"
          para1={paraCenter2}
          image={"./images/Solutions/Center2.png"}
          route="business"
        />
      </Box>

      <Box id={l4id}>
        <SolutionImageCenter
          slide="zoom-in"
          slide1="slide-left"
          title="Government"
          para1={paraCenter3}
          image={"./images/Solutions/Right2.png"}
          route="goverment"
        />
      </Box>
    </>
  );
};
