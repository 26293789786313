import React, { useEffect, useState } from "react";
//Material ui
import { Box, makeStyles } from "@material-ui/core";
//Material Icon
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import {
  PoleData,
  IntellipoleSecondData,
  IntellipoleSecondData1,
  IntellipoleSecondData2,
  IntellipoleSecondData3,
  IntellipoleSecondData4,
  IntellipoleSecondData5,
  IntellipoleSecondData6,
  IntellipoleSecondData7,
  IntellipoleSecondData8,
  IntellipoleSecondData9,
} from "../../../Data";
import {
  LeftImagesPara,
  ProductGridOne,
  ProductGridSecond,
  ProductGridTitle,
  ProductImage,
  ProductInfinityPara,
  ProductIntroIntellipole,
  ProductSpecification,
  RightImagesPara,
  MakeInIndia,
  ProductIntro,
} from "../../Atom";
import {
  BottomBar,
  TopNavbar,
  Footer,
  TopBarAll,
  ScrollTop,
} from "../../Molecules";
import { BottomBarScroll } from "../../Molecules/BottomBar/BottomBarScroll";
import { AdditionalFeature } from "../../Molecules";

const para1 =
  "Lead by innovation with Intellipole, a next-generation solar-powered street light and EV charging station, and boost your property or business value with omni-purpose EV Charging.";
const para2 =
  "Here is Intellipole, a futuristic EV Charger that’s redefining intelligence in the EV Charging landscape.";
const paraRight =
  "Electric Vehicles are the future and as the EV uptake elevates, make sure to take your real estate properties to the next level and dominate the market. Now is the time to proliferate the value of your townships, high-rise buildings, rowhouses, and colonies, etc. with an omni-purpose EV Charging Station that upscales your sales and makes you future-ready.";
const paraRight1 =
  "Governments across the world can be the key influencers of the EV Revolution by making EV Charging Infra ubiquitous. This can help reduce the vehicular pollution load along with reducing imports of crude oil. Intellipole is an intelligent solar street light EV Charging solution that can act as an integral part of GoI’s Smart City Mission. One Intellipole can power 3 EVs simultaneously while keeping a tab on the air quality index and generate revenue by providing EV Charging as a Service and advertisements.";
const paraLeft =
  "The EV Revolution is pacing and now is the time to lead it by adopting business-first EV Charging Solutions. Intellipole is a multi-purpose EV Charging Station that balances your investment effort through its multiple use cases. This makes it a viable solution for multi-national companies, business tech parks, co-working spaces, etc. that can build a fossil-free future by turning their workforce electric.";


const useStyles = makeStyles((theme) => ({
  infinityMargin: {
    marginTop: '10%'
  },
  paddingView: {
    paddingTop: '10%',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '18%'
    }
  },
  paddingViewAll: {
    paddingTop: '2%',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '20%'
    }
  }

}))


export const IntellipoleProductPage = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <TopBarAll
        l2="Features"
        l2id="#multi-purpose"
        l3="Residential Spaces"
        l3id="#residential"
        l4="Business"
        l4id="#business"
        l5="Government"
        l5id="#government"
        // l6="&#11015; Download Specifications"
        downloadSpecification="&#11015; Download Specifications"

      />

      <ProductIntro
        title="INTELLIPOLE"
        Intellipole
      />

      {/* <ProductIntroIntellipole
        title="INTELLIPOLE"
        Intellipole
        Image={"./images/Intellipole/Intellipole.png"}        
      /> */}

      <BottomBarScroll title="CHARGE WITH EVRE" route="evreproduct" />

      <ProductInfinityPara para1={para1} />

      <Box id="multi-purpose" className={classes.paddingView}>
        <ProductGridTitle
          h1="Multi-purpose"
          h2="and Intelligent"
          h3="EV Charging"
        />
      </Box>

      <ProductGridOne mapData={PoleData} />

      <Box id="residential" className={classes.paddingViewAll}>
        <RightImagesPara
          image={"./images/Infinity/Right1.png"}
          title="Residential Spaces"
          para={paraRight}
          route="residential"
        />
      </Box>

      <Box id="business" className={classes.paddingViewAll}>
        <LeftImagesPara
          image={"./images/Intellipole/Center2.png"}
          title="Business"
          para={paraLeft}
          route="business"
        />
      </Box>

      <Box id="government" className={classes.paddingViewAll}>
        <RightImagesPara
          image={"./images/Intellipole/Right2.png"}
          title="Government"
          para={paraRight1}
          route="government"
        />
      </Box>

      <MakeInIndia />

      <Footer />
    </div>
  );
};
