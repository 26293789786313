import React, { useRef } from "react";
import { Box, makeStyles } from "@material-ui/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const useStyles = makeStyles((theme) => ({
  boxMargin: {
    margin: "10% auto",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  head: {
    width: "50%",
    margin: "10% auto",
    font: "normal normal bold 7vw Urbanist",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
      fontSize: "8vw"
    },
  },
  arrow: {
    width: "100%",
    display: "inline-block",
  },
  arrowFloat: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    float: "right",
    [theme.breakpoints.down("sm")]: {
      float: "none",
      width: "100%",
    },
  },
  arrowIcon: {
    margin: "0 5%",
    background: "none",
    border: "none",
    outline: "none",
    opacity: ".5",
    "&:hover": {
      opacity: "1",
    },
    "& img": {
      cursor: "pointer"
    }
  },
  boxone: {
  },
  boxtwo: {
    border: "2px solid green",
    display: "flex",
    flexDirection: "row",
  },
  image: {
    width: "80%",
  },
  flexboxone: {
    width: "20%",
    marginTop: "10%",
  },
  flexboxtwo: {
    width: "70%",
  },
  number: {
    fontSize: "5rem",
    fontFamily: "Urbanist",
    fontWeight: "700",
    opacity: "0.5",
  },
  slider: {
    width: "95%",
    margin: "0 auto",
    // height: '75vh',
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      width: "80%"
    },
  },
  card: {
    overflow: "hidden",
    width: "20rem",
    padding: "8%",
    margin: "0% 2%",
    [theme.breakpoints.down("xs")]: {
      width: '90%',
      padding: "1%",
      margin: "0%"
    },
  },
  cardSub: {
    padding: "5%",
    background: "#1A1A1A",
    height: "500px",
    // transition: "all .5s ease-in-out",
    // "&:hover": {
    //   transform: "translateY(-50px)",
    // background: "linear-gradient(#00BF5F , #006934)",
    // boxShadow: "0px 6px 10px #fff",
    // },
    [theme.breakpoints.down("sm")]: {
      height: "100%",
    },
  },
  subHead: {
    font: "normal normal bold 2.2rem Urbanist",
    marginTop: "5%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "4vw",
    },
  },
  subPara: {
    font: "normal normal 300 1.3rem Urbanist",
    marginTop: "5%",
    lineHeight: "1.8",
    [theme.breakpoints.down("sm")]: {
      fontSize: "4vw",
      lineHeight: "1.5",
    },
  },
}));

export const WhyBecomeEvre = ({ head, middleHead, head1, data }) => {
  const classes = useStyles();

  const sliderRef = useRef();

  const next = () => {
    sliderRef.current.slickNext();
  };
  const previous = () => {
    sliderRef.current.slickPrev();
  };

  const settings = {
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };




  return (
    <Box className={classes.boxMargin}>
      <h1 className={classes.head} data-aos="fade-up">
        {head} <span style={{ color: "#00BF5F " }}>{middleHead}</span> {head1}
      </h1>

      <Box className={classes.arrow}>
        <Box className={classes.arrowFloat}>
          <button
            className={classes.arrowIcon}
            style={{ transform: "rotate(180deg)" }}
            onClick={previous}
            data-aos="fade-left"
          >
            <img src={"./images/Arrow/Arrow.png"} alt="arrow" />
          </button>
          
          <button
            className={classes.arrowIcon}
            onClick={next}
            data-aos="fade-right"

          >
            <img src={"./images/Arrow/Arrow.png"} alt="arrow" />
          </button>
        </Box>
      </Box>

      <Slider ref={sliderRef} {...settings} className={classes.slider}>
        {
          data.map((item, index) => {
            return (
              <Box className={classes.card} key={index} >
                <Box className={classes.cardSub}>
                  <h1 className={classes.subHead} data-aos="fade-up">{item.head}</h1>
                  <h1 className={classes.subPara} data-aos="fade-up">
                    {item.para}
                  </h1>
                </Box>
              </Box>
            )
          })
        }
      </Slider>
    </Box>
  );
};
