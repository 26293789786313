export const InfinityGridOneData = [
  // {
  //   title: "22 kW AC Charging",
  //   subtitle: "Explore a design-first, technology-driven AC EV Charger.",
  // },
  // {
    //   title: "Patented Computer Vision Technology",
  //   subtitle:
  //     "With EVRE, enable your users to reserve their charging slots in advance through our patented number plate recognition technology.",
  // },
  {
    title: "7 kW to 22 kW AC Charging",
    subtitle:
      "Customize your charger capacity as per your charging requirements.",
  },
  {
    title: "Charges within 6 to 8 Hours",
    subtitle: "Redefining the ‘Rapid’ in AC EV Chargers.",
  },
  {
    title: "Real-time Telemetry Data",
    subtitle:
      "Access real-time charging data like unit consumption, charging sessions, payments etc. on your EVRE dashboard or integrate with your existing management systems.",
  },
  {
    title: "Integrated with Mobile App",
    subtitle:
      "Access our ready-to-go customer app or integrate with your business app to deliver customer experience that cuts above the rest.",
  },
  {
    title: "Compatible with all 4-Wheeler EVs",
    subtitle:
    "Here is premium EV Charging for all types of electric 4-Wheelers.",
  },
  {
    title: "Integrated Billing and Payments",
    subtitle:
      "With completely unmanned operations, manage your EV Charging income with integrated online billing and payments.",
  },
  {
    title: "Energy Metering",
    subtitle:
      "With Energy Metering, transparently track electricity unit consumption and earned revenue.",
  },
  {
    title: "Electrically Protected",
    subtitle:
      "State-of-the-art electrical protections, leaving little to chance.",
  },
  {
    title: "RFID/QR Code Based User Authentication",
    subtitle:
      "Gain utmost control of who uses your EV Chargers through custom RFID tags and QR Codes.",
  },

  {
    title: "Emergency Button",
    subtitle: "All the alarms and alerts you need in case of an emergency.",
  },
  {
    title: "OCPP 1.6 Compatibility",
    subtitle: "OCPP 1.6 compliant charging station that can push data to your CMS with little integration effort.",
  },
  {
    title: "Yours to Configure",
    subtitle: "Remotely configure your most important parameters such as pricing, cut-off thresholds, etc.",
  },
];

export const InfinitySecondData = [
  {
    title: "Input Voltage Rating",
    subtitle: "230 VAC (± 20%) Single Phase OR 415 VAC (± 20%) Three Phase",
  },
  {
    title: "Input Current Rating",
    subtitle: "32 A per Phase",
  },
  {
    title: "Connection & Wiring",
    subtitle: "L1, N, G OR L1, L2, L3, N, G",
  },
  {
    title: "Standby Power",
    subtitle: "< 20 W",
  },
];

export const InfinitySecondData1 = [
  {
    title: "Output Rating",
    subtitle: "230 VAC (± 20%), 32 A per Phase - One/Two/ Three Phase",
  },
  {
    title: "Output Power",
    subtitle: "1 / 2 outputs of 7.4 kW OR 1 output of 22 kW",
  },
  {
    title: "Metering",
    subtitle: "Real-time single-phase/ three-phase energy metering",
  },
  {
    title: "Cold Load Pick-Up",
    subtitle: "Delay before charge resumes after power failure",
  },
];

export const InfinitySecondData2 = [
  {
    title: "Upstream Breaker",
    subtitle: "2-pole/4-pole, 40 A breaker on dedicated circuit, non-GFCI type",
  },
  {
    title: "Plug-Out Protection",
    subtitle: "Output terminated upon charging connector plug-out",
  },
  {
    title: "Ground Fault",
    subtitle: "30 mA",
  },
  {
    title: "Surge Protection",
    subtitle: "Class-II, 4-pole up to 1.5 kV",
  },
  {
    title: "Electrical Protections",
    subtitle:
      "Over current, Over voltage, Under voltage, Ground fault, Surge protection",
  },
];

export const InfinitySecondData3 = [
  {
    title: "Buttons",
    subtitle: "Emergency Stop",
  },
  {
    title: "User Authentication",
    subtitle: "QR Code, Mobile App, RFID, Remote Start",
  },
  {
    title: "Status Indication",
    subtitle: "Multi-colour LED-Power, Charging, Diagnostic",
  },
];

export const InfinitySecondData4 = [
  {
    title: "EVSE to Vehicle",
    subtitle: "Pilot Line Communication (Type-2/J1772).",
  },
  {
    title: "EVSE to Server",
    subtitle: "OCPP 1.6.",
  },
  {
    title: "Cloud Connectivity",
    subtitle: "Wi-Fi, Ethernet, GSM (optional).",
  },
];

export const InfinitySecondData5 = [
  {
    title: "Charging Interface",
    subtitle: "IEC 62196 (Type 2) - 5 wire/7 wire",
  },
  {
    title: "Charging Cable Length",
    subtitle: "5 m",
  },
  {
    title: "Cloud Connectivity",
    subtitle: "Wi-Fi, Ethernet, GSM (optional)",
  },
];

export const InfinitySecondData6 = [
  {
    title: "Operating Temperature",
    subtitle: "0° C to + 55° C",
  },
  {
    title: "Operating Humidity",
    subtitle: "5 to 95% relative humidity, non-condensing",
  },
];

export const InfinitySecondData7 = [
  {
    title: "Dimensions",
    subtitle: "556 mm x 456 mm x 200 mm",
  },
  {
    title: "Material",
    subtitle: "Aluminium",
  },
  {
    title: "Cooling",
    subtitle: "Natural air cooled",
  },
  {
    title: "Net Weight",
    subtitle: "11 kg",
  },
  {
    title: "Mounting Type",
    subtitle: "Wall Mount, Pedestal (optional)",
  },
];

export const InfinitySecondData8 = [
  {
    title: "Compliance",
    subtitle: "AIS-138",
  },
];
