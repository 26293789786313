import React from 'react'
import { makeStyles } from '@material-ui/core';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '10% 0%',
    [theme.breakpoints.down("sm")]: {
      flexDirection: 'column',
      alignItems:"center",
      margin: '5% 0%',

    },
  },
  dashboard_left: {
    width: '40%',
    // height: '500px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down("sm")]: {
      width: '70%',
      height: 'fit-content',
      marginTop:"10%"
    },
  },
  dashboard_left_box:{
    [theme.breakpoints.down("sm")]: {
      width:"100%",
    }
  },
  dashboard_left_head: {
    width: '50%',
    margin: '0% auto',
    padding:"3% 0%",
    font: "normal normal 200 2vw Urbanist",
    textAlign: 'center',
    [theme.breakpoints.down("sm")]: {
      fontSize: "6vw",
      width: '60%',
    },
  },
  dashboard_left_hr: {
    width: '50%',
    margin: '0% auto',
    border: "1px solid #00BF5F",
    // marginTop: '5%',
    [theme.breakpoints.down("sm")]: {
      // marginTop: '5%',
    width: '100%',

    },
  },
  dashboard_right: {
    width: '50%',
    display: 'flex',
    justifyContent: 'end',
    [theme.breakpoints.down("sm")]: {
      width: '100%',
      marginTop:"10%"
    },
  },
  dashboard_image: {
    width: '80%',
  },
  // asd: {
  //   border: '2px solid green',
  //   height: 'auto',
  //   padding: '50px'
  // },
}));

export const DashBoard = () => {
  const classes = useStyles();

  const Settings = {
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false
  };

  return (
    <div className={classes.root}>

      <div className={classes.dashboard_left}>
        <Slider {...Settings} className={classes.asd}>

          <div className={classes.dashboard_left_box}>
            <h1 className={classes.dashboard_left_head}>Driver Attendance and Monitoring</h1>
            <hr className={classes.dashboard_left_hr} />
          </div>

          <div className={classes.dashboard_left_box}>
            <h1 className={classes.dashboard_left_head}>Smart Charging & Secure Parking</h1>
            <hr className={classes.dashboard_left_hr} />
          </div>

          <div className={classes.dashboard_left_box}>
            <h1 className={classes.dashboard_left_head}>Vehicle Service & Maintenance</h1>
            <hr className={classes.dashboard_left_hr} />
          </div>

          <div className={classes.dashboard_left_box}>
            <h1 className={classes.dashboard_left_head}>Solar Power Integration</h1>
            <hr className={classes.dashboard_left_hr} />
          </div>

          <div className={classes.dashboard_left_box}>
            <h1 className={classes.dashboard_left_head}>Vehicle Telematics</h1>
            <hr className={classes.dashboard_left_hr} />
          </div>

          <div className={classes.dashboard_left_box}>
            <h1 className={classes.dashboard_left_head}>Charging Reports</h1>
            <hr className={classes.dashboard_left_hr} />
          </div>

          <div className={classes.dashboard_left_box}>
            <h1 className={classes.dashboard_left_head}> Video Analytics</h1>
            <hr className={classes.dashboard_left_hr} />
          </div>

        </Slider>

      </div>

      <div className={classes.dashboard_right}>
        <img className={classes.dashboard_image} src={'./images/AboutEvre/Dashboard_mockup.png'} alt="dashboard" />
      </div>

    </div>
  )
}
