import { AllPageHead } from "../../Atom";
import { Footer, TopBarAll } from "../../Molecules";
import { Box } from "@material-ui/core";

export const LogisticsScreen = () => {
  return (
    <Box>
      <TopBarAll
        l1="Logistics"
        l1id="#logistics"
        l2="Commercial Spaces"
        l2id="#commercial"
      />

      <div id="logistics">
        <AllPageHead
          title="Logistics and Fleet Operators"
          para1="We are building India’s largest and most advanced EV Charging network for logistics and fleet operators, and last-mile delivery service providers. Our charging hubs are secured and insured spaces with a variety of EV Chargers that are compatible with all-wheeler EVs. We are also partnering with leading fleet operators to help them set up charging hubs exclusively for their companies with business-first features for non-stop operations."
        />
      </div>

      <div id="commercial">
        <AllPageHead
          title="Commercial Spaces"
          para1="
        Fast-track your business growth with EVRE. The world is going to home millions of EVs in the coming years and the EV owners will opt for businesses where they can charge their EVs while availing other services."
        />
      </div>
      <Footer />
    </Box>
  );
};
