import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    marginTop: "15%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20%",
    },
  },
  containerFlex: {
    width: "55%",
    margin: "0 auto",
    display: "flex",
    gap:10, 
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width:"96%"
    },
  },
  formBorder: {
    width: "58%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width:"100%"
    },
  },
  newslatter: {
    backgroundColor: "transparent",
    border: "none",
    outline: "none",
    color: "#747474",
    paddingRight: 10 , 
    font: "normal normal lighter 4.5vw Urbanist",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
  
    },
  },
  subscribe: {
    color: "#fff",
    font: "normal normal lighter 1.5rem Urbanist",
    cursor: "pointer",
  },
  gridBox: {
    width: "80%",
    float: "right",
    padding: "0px",
    [theme.breakpoints.down("sm")]: {
      margin: "0 auto",
      width: "90%",
      float: "none",
    },
  },
  heading: {
    font: "normal normal 6vw Urbanist",
    marginTop: "5%",
    marginLeft: "2%",
    fontWeight: "500",
    background: "#006934",
    background: "-webkit-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "-moz-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    [theme.breakpoints.down("sm")]: {
      fontSize: "2rem",
      width: "99%",
      marginLeft: "0%",
    },
  },
  infoText: {
    font: "normal normal 3vw Urbanist",
    marginTop: 10,
    marginLeft: "2%",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      width: "99%",
    marginLeft: "0%",

    },
  },
  list: {
    marginTop: "8%",
    marginLeft: "0%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      margin: "0 auto",
      marginTop: "10%",
    },
  },
  Heads: {
    font: "normal normal 1.5vw Urbanist",
    cursor: "pointer",
    opacity: "0.5 ",
    marginBottom: 20,
    width: "100%",
    transition: "all .4s ease-in-out",
    color: "#fff",
    "&:hover": {
      color: "#00BF5F",
    },
    "& svg": {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      font: "normal normal 2rem Urbanist",
      // color: "#00BF5F",
      display: "flex",
      justifyContent: "space-between",
      "& svg": {
        fontSize: "2rem",
        display: "block",
      },
    },
    [theme.breakpoints.down("xs")]: {
      font: "normal normal 1.5rem Urbanist",
      // color: "#00BF5F",
    },
  },

  menuHidden: {
    [theme.breakpoints.down("sm")]: {
      height: "200px",
      height: "0",
      overflow: "hidden",
    },
  },
  linkText: {
    font: "normal normal normal 1.12rem Urbanist",
    cursor: "pointer",
    color: "#fff",
    marginTop: 20,
    opacity: "0.5",
    transition: "all .4s ease-in-out",
    [theme.breakpoints.down("sm")]: {
      font: "normal normal normal 3vw Urbanist",
    },
    [theme.breakpoints.down("xs")]: {
      font: "normal normal normal 1.1rem Urbanist",
    },
    "&:hover": {
      color: "#00BF5F ",
      opacity: "1",
    },
  },
  bottomOfFooter: {
    display: "flex",
    width: "80%",
    float: "right",
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "100%",
      marginTop: "4%",
    },
  },
  copyright: {
    font: "normal normal normal 1vw Urbanist",
    color: "#747474",
    color: "#fff",
    marginTop: "10%",
    marginBottom: "16%",
    [theme.breakpoints.down("sm")]: {
      font: "normal normal normal 1.2rem Urbanist",
      color: "grey",
      width: "80%",
      margin: "5% auto",
      marginBottom: "0%",
      textAlign: "center",
    },
  },
  bgImage: {
    width: "50%",
    justifyContent: "space-between",
    display: "flex",
    marginTop: "5%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    "& img": {
      width: "125%",
      maxHeight: "100%",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  },
  icon: {
    padding: 20,
    marginTop: "5%",
  },
  iconBox: {
    width: "50%",
    // marginLeft: "0",
    marginTop: "5%",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      marginLeft: "-2%",
    },
  },
  text: {
    font: "normal normal normal 1.12rem Urbanist",
    color: "#747474",
    padding: "0 10px  ",
    marginTop: 20,
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "10px",
    },
  },
  textBox: {
    display: "flex",
    alignItems: "center",
    width: "50%",
    transition: "all .4s ease-in-out",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
      marginTop: 20,
      width: "100%",
      marginLeft: "0%",
    },
  },
  image: {
    width: "45%",
    height: "40%",
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));
