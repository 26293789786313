import React, { useState } from "react";
import {
    Box,
    makeStyles,
    Typography,
    Input,
    InputLabel,
    Button,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: "10%",
        [theme.breakpoints.down("sm")]: {},
    },

    head: {
        font: "normal normal bolder 10rem Urbanist",
        textAlign: "center",
        background: "#006934",
        background: "-webkit-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
        background: "-moz-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
        background: "linear-gradient(to top, #006934 0%,#00FF7F 100%)",
        WebkitBackgroundClip: "text",
        WebkitTextFillColor: "transparent",
        [theme.breakpoints.down("lg")]: {
            fontSize: "10rem",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "5rem",
        },
    },
    flexBox: {
        width: "100%",
        textAlign: "center",
    },
    paraBoxText: {
        width: "20%",
        textAlign: "left",
        marginLeft: "55%",
        font: "normal normal lighter 1rem Urbanist",
        [theme.breakpoints.down('sm')]: {
            textAlign: "center",
            width: "100%",
            marginLeft: "0%",
        }
    },
    formBox: {
        width: "60%",
        margin: "5% auto",
        padding: "2%",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        }
    },
    dateBox: {
        width: "50%",
        padding: '0% 5%'
    },
    label: {
        color: "#ffffff",
        font: "normal normal 1rem Urbanist",
        marginLeft: 10,
    },
    inputDate: {
        width: "100%",
        margin: "2% auto",
        paddingLeft: "5%",
        border: "none",
        borderBottom: "2px solid #1A1A1A",
        background: "transparent",
        color: "#FFFFFF",
        outline: "none",
    },
    nameBox: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        marginTop: "5%",
    },
    selectBox: {
        marginTop: "5%",
        width: "100%",
        padding: '0% 5%'
    },
    selectText: {
        width: "100%",
        margin: "2% auto",
        paddingLeft: "5%",
        border: "none",
        borderBottom: "2px solid #1A1A1A",
        background: "#000",
        color: "#FFFFFF",
        outline: "none",
    },
    submitBtn: {
        font: "normal normal 1rem Urbanist",
        display: "inline",
        float: "right",
        color: "#ffffff",
        marginTop: "5%",
        [theme.breakpoints.down("sm")]: {
            margin: "10% 0%",
        },
    },
}));

export const FranchiseEnquiryForm = () => {
    const classes = useStyles();
    const [data, setData] = useState("");
    const [open, setOpen] = useState(false);

    const handleChange = (event) => {
        setData(event.target.value);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <Box className={classes.root}>

            <Box className={classes.flexBox}>
                <Typography className={classes.head}>ENQUIRY</Typography>
                <Typography className={classes.paraBoxText}>
                    Feel free to connect with us to know more about our dealership model.
                    We will be happy to help you!
                </Typography>
            </Box>

            <Box className={classes.formBox}>

                <Box className={classes.selectBox}>
                    <InputLabel className={classes.label}>Date</InputLabel>
                    <Input placeholder="dd/mmm/YYYY" className={classes.inputDate} />
                </Box>

                <Box className={classes.nameBox}>
                    <Box className={classes.dateBox}>
                        <InputLabel className={classes.label}>First Name</InputLabel>
                        <Input className={classes.inputDate} />
                    </Box>
                    <Box className={classes.dateBox}>
                        <InputLabel className={classes.label}>Last Name</InputLabel>
                        <Input className={classes.inputDate} />
                    </Box>
                </Box>

                <Box className={classes.selectBox}>
                    <InputLabel className={classes.label}>
                        In which city do you want to setup the charging infra? *
                    </InputLabel>
                    <select
                        className={classes.selectText}
                        open={open}
                        onClose={handleClose}
                        onOpen={handleOpen}
                    >
                        <option value={10}>Ten</option>
                        <option value={20}>Twenty</option>
                        <option value={30}>Thirty</option>
                    </select>
                </Box>

                <Box className={classes.nameBox}>
                    <Box className={classes.dateBox}>
                        <InputLabel className={classes.label}>Phone</InputLabel>
                        <Input className={classes.inputDate} />
                    </Box>
                    <Box className={classes.dateBox}>
                        <InputLabel className={classes.label}>Email</InputLabel>
                        <Input className={classes.inputDate} />
                    </Box>
                </Box>

                <Box className={classes.nameBox}>

                    <Box className={classes.dateBox}>
                        <InputLabel className={classes.label}>Company Name</InputLabel>
                        <Input className={classes.inputDate} />
                    </Box>

                    <Box className={classes.dateBox}>
                        <InputLabel className={classes.label}>Investment Range</InputLabel>
                        <select
                            className={classes.selectText}
                            open={open}
                            onClose={handleClose}
                            onOpen={handleOpen}
                        >
                            <option value={10}>Ten</option>
                            <option value={20}>Twenty</option>
                            <option value={30}>Thirty</option>
                        </select>
                    </Box>
                </Box>

                <Box className={classes.selectBox}>
                    <InputLabel className={classes.label}>
                        Do you own or rent a location that can be used for setting up EV
                        charging stations?*
                    </InputLabel>
                    <select
                        className={classes.selectText}
                        open={open}
                        onClose={handleClose}
                        onOpen={handleOpen}
                    >
                        <option value={10}>Ten</option>
                        <option value={20}>Twenty</option>
                        <option value={30}>Thirty</option>
                    </select>
                </Box>

                <Box className={classes.selectBox}>
                    <InputLabel className={classes.label}>Message</InputLabel>
                    <Input className={classes.selectText} />
                </Box>

                <Box>
                    <Button className={classes.submitBtn}>Submit</Button>
                </Box>

            </Box>

        </Box>
    );
};
