import React, { useEffect } from "react";
import { Box, makeStyles } from "@material-ui/core";
import { HomeNavigation, ProductGridOne } from "../../Atom";
import { Footer, ScrollTop, TopBarAll, TopNavbar } from "../../Molecules";
import { BecomeADealerTemplate } from "../../Templates";

const useStyles = makeStyles((theme) => ({
  paddingView: {
    paddingTop: '10%',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '18%'
    }
  },
  paddingViewAll: {
    paddingTop: '2%',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '20%'
    }
  }
}))

export const BecomeADealer = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <Box>
      <TopBarAll
        // l1="About EVRE"
        // l1id="#aboutevre"
        l2="Why EVRE"
        l2id="#whyevre"
        l3="ENQUIRY"
        l3id="#enquiry"
      />

      <HomeNavigation name="Become a dealer" />

      <BecomeADealerTemplate
        l1id="aboutevre"
        l2id="whyevre"
        l3id="enquiry"
      />
      <Footer />
    </Box>
  );
};
