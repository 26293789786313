import React from "react";
import { makeStyles, Box, Typography } from "@material-ui/core";
import Card from "./Card";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "5%",
    paddingTop: '10%',
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin:"0 auto"
    },
  },

  mainBox: {
    width: "100%",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  textone: {
    font: "normal normal bolder 15rem Urbanist",
    opacity: "0.2",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      font: "normal normal bolder 10rem Urbanist",
    },
  },
  contentBox: {
    width: "40%",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      width: "80%"
    },
  },
  paraText: {
    font: "normal normal lighter 1.5rem Urbanist",
    lineHeight: "1.5",
  },
}));

export const ReachCard = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box className={classes.mainBox}>
        <marquee behavior="scroll" direction="right" scrollamount="20" className={classes.textone}>Our Reach</marquee>
      </Box>
      <Box className={classes.contentBox}>
        <Typography className={classes.paraText}>
          We are stern to build a community of businesses that lead the EV
          Revolution with us. With the rapid implementation of EV Charging
          Infrastructure across India, we are confident to lead this shift with
          impact.
        </Typography>
      </Box>



      <Card />

      
    </Box>
  );
};
