import React from 'react'
import { makeStyles, Box } from "@material-ui/core";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "80%",
    margin: "0 auto",
    color: "#fff",
    paddingTop: "2%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      padding: "1.2rem 0",
    },
  },
  heading: {
    cursor: "pointer",
    width: "8rem",
    [theme.breakpoints.down("sm")]: {
      width: "5.2rem",
    },
  },
  Menu: {
    width: "50%",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      justifyContent: "end",
    },
  },
  ul: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    listStyle: "none",
    font: "normal normal normal 1rem urbanist",
    [theme.breakpoints.down("sm")]: {
      display: 'none'
    },
  },
  MenuIcon: {
    cursor: "pointer",
    marginLeft: '5%'
  },
  noActive: {
    font: "normal normal normal 1.1rem urbanist",
    textDecoration: 'none',
    color: '#fff'
  },
  active: {
    color: '#00BF5F '
  },
  '@media(max-width: 1440px)': {
    Menu: {
      width: "60%",
    },
  },
  '@media(max-width: 1366px)': {
    Menu: {
      width: "60%",
    },
  },
}));

export const TopBar = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Box className={classes.root}>
      <img
        className={classes.heading}
        src={"./images/Logo/Logo.svg"}
        onClick={() => history.push("/home")}
        alt="Logo"
      />
      <Box className={classes.Menu}>
        <ul className={classes.ul}>
          <li><NavLink activeClassName={classes.active} className={classes.noActive} to="/evreproduct">Charge With EVRE</NavLink></li>
          <li><NavLink activeClassName={classes.active} className={classes.noActive} to="/aboutevre">About EVRE</NavLink></li>
          <li><NavLink activeClassName={classes.active} className={classes.noActive} to="/solution">Solutions</NavLink></li>
          <li><NavLink activeClassName={classes.active} className={classes.noActive} to="/home">Locate a Charger</NavLink></li>
          {/* <li><NavLink activeClassName={classes.active} className={classes.noActive} to="/franchise">Own a Franchise</NavLink></li> */}
        </ul>
        <img
          className={classes.MenuIcon}
          src={"./images/TopNavbar/menu.png"}
          alt="logo"
          onClick={() => history.push("/menu")}
        />
      </Box>
    </Box>
  )
}
