import React from "react";
import { Box, Container, makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    width:"80%",
    margin:"5% auto",
    [theme.breakpoints.down("sm")]: {
      marginTop: "18%",
    },
  },
  imageBox: {
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      marginTop: "5%",
    },
  },
  image: {
    width: "45%",
    marginTop: "2%",
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
  },
  textBox: {
    marginTop: "-6%",
    [theme.breakpoints.down("sm")]: {

    },
  },
  evre: {
    font: "normal normal bolder 8vw Urbanist",
    textAlign: "center",
    background: "#006934",
    background: "-webkit-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "-moz-linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    background: "linear-gradient(to top, #006934 0%,#00FF7F 100%)",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    zIndex: "1 !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3rem",
    },
  },
}));

export const Intro = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <Box className={classes.imageBox}>
        <img
          src={"./images/evre/evre.png"}
          className={classes.image}
          alt="Evre Product"
        />
      </Box>
      <Box className={classes.textBox}>
        <Typography className={classes.evre}>Charge with EVRE</Typography>
      </Box>
    </Box>
  );
};
