import React, { useEffect } from "react";
//Material ui
import { Box, makeStyles } from "@material-ui/core";
//DATA
import { InfinityGridOneData } from "../../../Data";
//Atom
import infiniyBrouchure from "../../Assets/brochures/infinity.pdf";
import {
  LeftImagesPara,
  ProductGridOne,
  ProductGridTitle,
  ProductInfinityPara,
  ProductIntro,
  RightImagesPara,
  MakeInIndia,
} from "../../Atom";
//Molicule
import { Footer, TopBarAll } from "../../Molecules";
//BottomBarScroll
import { BottomBarScroll } from "../../Molecules/BottomBar/BottomBarScroll";
//Aos
import AOS from "aos";
import "aos/dist/aos.css";

const para1 =
  "Fast track your business or real-estate growth with Infinity. An aesthetically designed and technology-first EV Charging Station for places where customer impression is of prime importance. ";
const paraRight =
  "Become the market leader in your industry. Build futuristic and luxurious townships, high-rise buildings, or colonies with Infinity and expedite your property value and uptake multi-folds with class-apart Electric Vehicle Charging.";
const paraRight1 =
  "Become a trendsetter in the professional world as the 21st Century dawns towards innovative technologies and sustainability. Contribute towards increasing the curve of EV uptake by helping switch your workforce to electric and solve the biggest challenge surrounding the EV landscape - lack of EV Charging Infra.";
const paraLeft =
  "Pave the way for an ever-increasing second income for your retail stores, restaurants, malls, or hotels and become the hub for all EV Owners. Equip your properties with premium EV Charging Stations to capitalize on the EV Revolution as we speak.";

const useStyles = makeStyles((theme) => ({
  infinityMargin: {
    marginTop: "10%",
  },
  paddingView: {
    paddingTop: "10%",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "18%",
    },
  },
  paddingViewAll: {
    paddingTop: "2%",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "20%",
    },
  },
}));

const handleScroll = () => {
  const scrollX = window.scrollX; //Don't get confused by what's scrolling - It's not the window
  console.log(scrollX);
};

export const InfinityProductPage = () => {
  const classes = useStyles();

  const animation = () => {
    AOS.init({
      easing: "ease-out-back",
      duration: 1000,
    });
  };
  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });

    document.addEventListener("scroll", handleScroll);

    animation();
  }, []);

  return (
    <div>
      <TopBarAll
        l1="Features"
        l1id="#Premiumchager"
        l2="Residential Space"
        l2id="#residential"
        l3="Commercial Space"
        l3id="#commercial"
        l4="Business"
        l4id="#business"
        downloadSpecification="&#11015; Download Specifications"
        downloadFile={infiniyBrouchure}
      />

      <ProductIntro
        title="INFINITY"
        Infinity
        // Image={"./images/Infinity/left.png"}
      />

      <BottomBarScroll
        title="CHARGE WITH EVRE"
        route="evreproduct"
        image="./images/All/mobile.png"
      />

      <ProductInfinityPara para1={para1} />

      <div id="Premiumchager" className={classes.paddingView}>
        <ProductGridTitle
          h1="Premium"
          h2="Charging for"
          h3="Electric 4 Wheelers"
        />
      </div>

      <ProductGridOne mapData={InfinityGridOneData} />

      <Box id="residential" className={classes.paddingViewAll}>
        <RightImagesPara
          image={"./images/Infinity/Right1.png"}
          title="Residential Spaces"
          para={paraRight}
          route="residential"
        />
      </Box>

      <Box id="commercial" className={classes.paddingViewAll}>
        <LeftImagesPara
          image={"./images/Infinity/Center1.png"}
          title="Commercial Spaces"
          para={paraLeft}
          route="commercial"
        />
      </Box>

      <Box id="business" className={classes.paddingViewAll}>
        <RightImagesPara
          image={"./images/Infinity/Center2.png"}
          title="Business"
          para={paraRight1}
          route="business"
        />
      </Box>

      <MakeInIndia />

      <Footer />
    </div>
  );
};
