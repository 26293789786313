import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import { Footer, ScrollTop, TopBarAll, TopNavbar } from "../../Molecules";
import { CommercialTemplates } from "../../Templates";

export const Commercial = () => {
  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <Box>

      <TopBarAll
        l1="Retail Stores"
        l1id="#retail"
        l2="Restaurants"
        l2id="#restaurants"
        l3="Malls"
        l3id="#malls"
        l4="Hotels"
        l4id="#hotel"
        l5="Why EVRE"
        l5id="#whyevre"
        l6="Solutions for you"
        l6id="#bestfit"
      />

      <CommercialTemplates
        l1id="retail"
        l2id="restaurants"
        l3id="malls"
        l4id="hotel"
        l5id="whyevre"
        l6id="bestfit"

      />

      <Footer />

    </Box>
  );
};
