import React, { useEffect } from "react";
import { TopNavbar, Footer, ScrollTop } from "../../Molecules";
import { UpcommingTemplates } from "../../Templates";

export const Upcomming = () => {
  useEffect(() => { 
      window.scroll({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <TopNavbar />
      {/* <ScrollTop/> */}
      <UpcommingTemplates />
      <Footer />
    </div>
  );
};
